import React from 'react'

import {
    ThemeProvider,
    Theme,
    StyledEngineProvider,
} from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { BrowserRouter } from 'react-router-dom'
import {
    ApolloClient,
    ApolloProvider,
    NormalizedCacheObject,
} from '@apollo/client'
import { SnackbarOrigin, SnackbarProvider } from 'notistack'
import CssBaseline from '@mui/material/CssBaseline'

const snackbarPosition: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right',
}

type AppProvidersProps = {
    apolloClient: ApolloClient<NormalizedCacheObject>
    children: React.ReactNode
    theme: Theme
}

const AppProviders: React.FC<AppProvidersProps> = (
    props: AppProvidersProps
) => {
    const { children, theme, apolloClient } = props
    return (
        <ThemeProvider theme={theme}>
            <ApolloProvider client={apolloClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SnackbarProvider
                        maxSnack={3}
                        autoHideDuration={3000}
                        anchorOrigin={snackbarPosition}
                    >
                        <StyledEngineProvider injectFirst>
                            <CssBaseline />
                            <BrowserRouter>{children}</BrowserRouter>
                        </StyledEngineProvider>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ApolloProvider>
        </ThemeProvider>
    )
}

export default AppProviders

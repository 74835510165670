import useStyles from './index.styles'

import React from 'react'

import { IconButton, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { paths } from 'config/routes'
import ArrowLeft from 'assets/images/icons/ArrowLeft'
import ViewOrder from 'components/common/ViewOrder'

const Order = (): JSX.Element => {
    const classes = useStyles()
    const params = useParams()
    const orderId = params.orderId || ''
    const navigate = useNavigate()

    return (
        <Stack className={classes.container}>
            <Stack className={classes.crumbs}>
                <IconButton onClick={(): void => navigate(paths.admin.home)}>
                    <ArrowLeft />
                </IconButton>
                back to order list
            </Stack>
            <ViewOrder orderId={orderId} />
        </Stack>
    )
}

export default Order

import useStyles from './index.styles'

import React from 'react'

import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

import { IUser } from 'models/user.model'
import ContactName from 'assets/images/icons/ContactName'
import ContactPhone from 'assets/images/icons/ContactPhone'
import ContactEmail from 'assets/images/icons/ContactEmail'
import { formatUserName } from 'utils/user.utils'
import { UserRole } from 'config/user'
import { paths } from 'config/routes'
import { EOrderStatus } from 'models/order.model'

interface IAdminAside {
    profile?: IUser
    onEditClick?: () => void
}

const AdminAside = ({ profile, onEditClick }: IAdminAside): JSX.Element => {
    const classes = useStyles()

    const activeOrdersLink = (): string => {
        return `${paths.admin.home}?administrator_id=${
            profile?.id || ''
        }&status=${EOrderStatus.DRAFT},${EOrderStatus.IN_PROGRESS}`
    }
    const totalOrdersLink = (): string => {
        return `${paths.admin.home}?administrator_id=${profile?.id || ''}`
    }

    return (
        <Stack className={classes.container}>
            <Stack className={classes.box}>
                <Stack className={classes.row}>
                    <Stack className={classes.icon}>
                        <ContactName />
                    </Stack>
                    <Stack className={classes.titleHead}>
                        {formatUserName(profile)}
                    </Stack>
                </Stack>
                <Stack className={classes.row}>
                    <Stack className={classes.icon} />
                    <Stack className={classes.title}>
                        <Link to={activeOrdersLink()}>
                            {profile?.activeOrders || 0} active orders,
                        </Link>
                        <Link to={totalOrdersLink()}>
                            {profile?.totalOrders || 0} total
                        </Link>
                    </Stack>
                </Stack>
            </Stack>
            <Stack className={classes.box}>
                <Stack className={classes.row}>
                    <Stack className={classes.icon}>
                        <ContactPhone />
                    </Stack>
                    <Stack className={classes.title}>
                        {profile?.phone || 'Not set'}
                    </Stack>
                </Stack>
                <Stack className={classes.row}>
                    <Stack className={classes.icon}>
                        <ContactEmail />
                    </Stack>
                    <Stack className={classes.title}>
                        {profile?.email || ''}
                    </Stack>
                </Stack>
            </Stack>
            <Stack className={classes.box}>
                <Stack className={classes.row}>
                    <Button
                        size="small"
                        variant="outlined"
                        className={classes.button}
                        onClick={onEditClick}
                    >
                        Manage Profile
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    )
}
export default AdminAside

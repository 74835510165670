import { gql } from '@apollo/client'

import { COMPANY_INFO } from 'api/fragments/company.fragment'

export const USER_INFO = gql`
    fragment UserFields on UserType {
        id
        email
        firstName
        lastName
        isActive
        role {
            key
            val
        }
        lastLogin
        phone
    }
`
export const USER_DATA = gql`
    ${USER_INFO}
    ${COMPANY_INFO}
    fragment UserInfo on UserType {
        ...UserFields
        company {
            ...CompanyFields
        }
    }
`

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'stretch',
        backgroundColor: 'transparent',
        overflowX: 'hidden',
        overflowY: 'auto',
        gap: '16px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        padding: '48px 56px',
        gap: '32px',
    },
    header: {
        ...theme.typography.medium18,
    },
}))

export default useStyles

import useStyles from './index.styles'

import React, { useMemo } from 'react'

import { Stack } from '@mui/material'

import { useServiceList } from 'hooks/useService'
import TextField from 'components/inputs/TextField'

interface IServices {
    group: number
    serviceErrors: Record<string, string>
    changedServices: Record<string, string>
    setServiceRate: (id: string, newRate: string) => void
}
const Services = ({
    group,
    changedServices,
    setServiceRate,
    serviceErrors,
}: IServices): JSX.Element => {
    const classes = useStyles()
    const { categories } = useServiceList({ group })

    const serviceValues: Record<string, string> = useMemo(() => {
        const response: Record<string, string> = {}
        categories.forEach(category => {
            category.services.forEach(service => {
                response[service.id] = service.rate.toString()
            })
        })
        return response
    }, [categories])
    const getServiceRate = (id: string): string => {
        if (Object.prototype.hasOwnProperty.call(changedServices, id)) {
            return changedServices[id]
        }
        if (Object.prototype.hasOwnProperty.call(serviceValues, id)) {
            return serviceValues[id]
        }
        return '0'
    }

    return (
        <Stack className={classes.categories}>
            {categories.map((category, key) => (
                <Stack key={category.id} className={classes.category}>
                    <Stack className={classes.categoryTitle}>{`${key + 1}. ${
                        category.title
                    }`}</Stack>
                    {category.services.map(service => (
                        <Stack className={classes.service} key={service.id}>
                            <Stack className={classes.serviceTitle}>
                                {service.title}
                            </Stack>
                            <Stack className={classes.serviceRate}>
                                <TextField
                                    type="text"
                                    value={getServiceRate(service.id)}
                                    onChange={(e): void =>
                                        setServiceRate(
                                            service.id,
                                            e.target.value
                                        )
                                    }
                                />
                                {serviceErrors[service.id] && (
                                    <Stack className={classes.error}>
                                        {serviceErrors[service.id]}
                                    </Stack>
                                )}
                            </Stack>
                            <Stack className={classes.serviceDescription}>
                                {service.description}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            ))}
        </Stack>
    )
}

export default Services

import React from 'react'

import Routes from './Routes'
import ReactiveDialog from 'components/common/ReactiveDialog'

const App: React.FC = () => {
    return (
        <>
            <ReactiveDialog />
            <Routes />
        </>
    )
}

export default App

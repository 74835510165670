import useStyles from '../index.styles'

import React from 'react'

import { Paper, Stack } from '@mui/material'
import { useFormikContext } from 'formik'

import OrderVessel from 'components/form/OrderForm/OrderVessel'
import OrderTowage from 'components/form/OrderForm/OrderTowage'
import OrderServices from 'components/form/OrderForm/OrderServices'
import { TextareaField } from 'components/inputs'
import { IOrderFormValues } from 'components/form/OrderForm/index.schema'
import { ICategory } from 'models/category.model'

interface IShipServices {
    isLoading: boolean
    categories?: Array<ICategory>
}

const ShipServices = ({
    isLoading,
    categories,
}: IShipServices): JSX.Element => {
    const classes = useStyles()
    const formik = useFormikContext<IOrderFormValues>()
    return (
        <Stack className={classes.container}>
            <Paper className={`${classes.formPaper} ship-services`}>
                <OrderVessel />
                <OrderTowage />
                <OrderServices
                    key="group-2"
                    isLoading={isLoading}
                    categories={categories}
                />
                <Stack className={classes.divider} />
                <TextareaField
                    id="comments-input"
                    name="portComments"
                    label="Comments (optional)"
                    placeholder="Enter your suggestions here"
                    rows={1}
                    value={formik.values.portComments}
                    onChange={formik.handleChange}
                />
            </Paper>
        </Stack>
    )
}
export default ShipServices

import { useCallback, useEffect, useMemo } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'

import { IQueryParams, useExtQuery } from 'hooks/useExtQuery'
import { ITableQueryInfo } from 'types/table.types'
import { IBooleanResponse, IEdgeNode } from 'models/api.model'
import { IService } from 'models/service.model'
import ServiceAPI from 'api/service.api'
import { ICategory } from 'models/category.model'
import { IUser } from 'models/user.model'
import { UserAPI } from 'api'

interface IServiceMutationResponse extends IBooleanResponse {
    services?: Array<IService>
}

export interface IServiceUpdate {
    serviceId: string
    rate: number
}

interface IUseServiceApi {
    updateServices: (
        values: Array<IServiceUpdate>
    ) => Promise<IServiceMutationResponse>
    isLoading: boolean
}

export const useServiceApi = (): IUseServiceApi => {
    const [update, { loading: updateServiceLoading }] = useMutation(
        ServiceAPI.updateServices(),
        {
            notifyOnNetworkStatusChange: true,
        }
    )

    const updateServices = (
        services: Array<IServiceUpdate>
    ): Promise<IServiceMutationResponse> => {
        return update({
            variables: { input: { serviceList: services } },
        }).then(response => {
            return response.data.updateServices
        })
    }

    return {
        updateServices,
        isLoading: updateServiceLoading,
    }
}

interface IUseServiceList {
    categories: Array<ICategory>
    isLoading: boolean
    loadMore: () => void
    hasNextPage: boolean
    setSearch: (search: string) => void
}

export const useServiceList = (params?: IQueryParams): IUseServiceList => {
    const { rows, setParam, dataIsLoading, loadMore, hasNextPage } =
        useExtQuery(
            {
                dataApi: ServiceAPI.categoryList,
                dataPath: 'categoryList',
                itemsPerPage: 200,
            } as ITableQueryInfo,
            params
        )
    useEffect(() => {})

    const setSearch = useCallback(
        (str: string): void => {
            setParam('search', str)
        },
        [setParam]
    )

    const categories: Array<ICategory> = useMemo(() => {
        return rows as Array<ICategory>
    }, [rows])

    return {
        categories,
        setSearch,
        loadMore,
        hasNextPage,
        isLoading: dataIsLoading,
    }
}
interface IUseLazyServiceList {
    categories: Array<ICategory>
    isLoading: boolean
    getCategories: (params?: IQueryParams) => void
}

export const useLazyServiceList = (): IUseLazyServiceList => {
    const [getData, { data, loading }] = useLazyQuery(ServiceAPI.categoryList())

    const categories: Array<ICategory> = useMemo(() => {
        if (data?.categoryList) {
            return data.categoryList.edges.map(
                (edge: IEdgeNode<ICategory>) => edge.node
            )
        }
        return [] as Array<ICategory>
    }, [data])

    const getCategories = (params?: IQueryParams): void => {
        getData({ variables: params })
    }

    return {
        categories,
        getCategories,
        isLoading: loading,
    }
}

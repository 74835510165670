import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'stretch',
        backgroundColor: 'transparent',
        overflowX: 'hidden',
        overflowY: 'auto',
        gap: '16px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        padding: '48px 56px',
        gap: '32px',
    },
    header: {
        ...theme.typography.medium18,
    },
    box: {
        borderBottom: `1px solid #22374E14`,
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: '24px 0',
        gap: '16px',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'stretch',
        gap: '16px',
    },
    icon: {
        flexBasis: '24px',
        flexGrow: '0',
    },
    titleHead: {
        ...theme.typography.medium20,
    },
    title: {
        ...theme.typography.regular16,
        flexDirection: 'row',
        gap: '4px',
    },
}))

export default useStyles

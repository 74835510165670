import useStyles from './index.styles'

import React from 'react'

import { Stack } from '@mui/material'

import { TRender } from 'types/common.types'

interface IGroupHorizontal {
    children: TRender
}

const GroupHorizontal = ({ children }: IGroupHorizontal): JSX.Element => {
    const classes = useStyles()

    return <Stack className={classes.container}>{children}</Stack>
}

export default GroupHorizontal

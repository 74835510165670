import isEmail from 'validator/lib/isEmail'
import validator from 'validator'

export const emailValidator = {
    name: 'email',
    message: 'Email must be valid',
    test: (value = ''): boolean => isEmail(value),
}
export const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W])[a-zA-Z\d\W]{8,32}$/

export const isValidPostalCodeLocale = (
    locale: validator.PostalCodeLocale
): boolean => {
    return validator.isPostalCodeLocales.includes(locale)
}

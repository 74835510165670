import useStyles from './index.styles'

import * as React from 'react'

import { Paper, Stack, Typography } from '@mui/material'

import AppLogo from 'assets/images/AppLogo.png'

interface IPage {
    title?: string
    subtitle?: string
    children: React.ReactElement
}

const Page = ({ children, title, subtitle }: IPage): JSX.Element => {
    const classes = useStyles()
    return (
        <Stack className={classes.container}>
            <Stack
                direction="column"
                justifyContent="center"
                className={classes.formWrapper}
            >
                <Paper className={classes.form}>
                    <img className={classes.logo} src={AppLogo} alt="logo" />
                    {title && (
                        <Typography className={classes.title} variant="bold20">
                            {title}
                            {subtitle && (
                                <Typography
                                    className={classes.subtitle}
                                    variant="regular14"
                                >
                                    {subtitle}
                                </Typography>
                            )}
                        </Typography>
                    )}
                    <Stack>{children}</Stack>
                </Paper>
            </Stack>
        </Stack>
    )
}
export default Page

import useStyles from './index.styles'

import React, { useState } from 'react'

import { Paper, Stack } from '@mui/material'
import { useLocation } from 'react-router-dom'

import { IOrder } from 'models/order.model'
import OrderFilters, { IOrderFilterValues } from './OrderFilters'
import OrderList from './OrderList'
import useScreenSize from 'hooks/useScreenSize'

interface IOrders {
    onOrderClick?: (order: IOrder) => void
}
const Orders = ({ onOrderClick }: IOrders): JSX.Element => {
    const classes = useStyles()
    const { isTablet } = useScreenSize()
    const { search } = useLocation()
    const getFilter = (): IOrderFilterValues => {
        const defaultFilter: IOrderFilterValues = {
            sortBy: 'created_at',
            sortReverse: true,
        }
        const params = new URLSearchParams(search)
        const companyId = params.get('company_id')
        if (companyId) {
            defaultFilter.companyId = companyId
        }
        const administratorId = params.get('administrator_id')
        if (administratorId) {
            defaultFilter.administratorId = administratorId
        }
        const status = params.get('status')
        if (status) {
            defaultFilter.status = status
        }
        return defaultFilter
    }
    const [filters, setFilters] = useState<IOrderFilterValues>(getFilter())

    return (
        <>
            <OrderFilters filters={filters} onChange={setFilters} />
            {isTablet ? (
                <Stack className={classes.content}>
                    <OrderList filters={filters} onOrderClick={onOrderClick} />
                </Stack>
            ) : (
                <Paper className={classes.content}>
                    <OrderList filters={filters} onOrderClick={onOrderClick} />
                </Paper>
            )}
        </>
    )
}
export default Orders

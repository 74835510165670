import useStyles from './index.styles'

import React from 'react'

import { useFormik } from 'formik'
import { Button, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'

import { PasswordField } from 'components/inputs'
import {
    ChangePasswordFormScheme,
    ChangePasswordInitialValues,
} from './index.schema'
import Loader from 'components/common/Loader'
import { useUserApi } from 'hooks/useUser'

interface IChangePaswordForm {
    onCancel: () => void
}
const ChangePasswordForm = ({ onCancel }: IChangePaswordForm): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar()
    const { changePassword, isLoading } = useUserApi()

    const formik = useFormik({
        initialValues: ChangePasswordInitialValues,
        validationSchema: ChangePasswordFormScheme,
        onSubmit: values => {
            changePassword(
                values.old_password,
                values.password,
                values.confirm_password
            )
                .then(response => {
                    const { success, errors } = response
                    if (success) {
                        enqueueSnackbar('Password changed')
                    } else {
                        enqueueSnackbar(errors ? errors[0] : 'Error happened', {
                            variant: 'error',
                        })
                    }
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' })
                })
        },
    })

    const classes = useStyles()

    return (
        <div>
            {isLoading && <Loader />}
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <Stack className={classes.formInput}>
                    <PasswordField
                        id="old_password-input"
                        name="old_password"
                        label="Current Password"
                        placeholder="Enter your current password"
                        value={formik.values.old_password}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.old_password &&
                            Boolean(formik.errors.old_password)
                        }
                        helperText={
                            formik.touched.old_password &&
                            formik.errors.old_password
                        }
                    />
                </Stack>
                <Stack className={classes.formInput}>
                    <PasswordField
                        id="password-input"
                        name="password"
                        label="New Password"
                        placeholder="Enter new password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                        }
                        helperText={
                            formik.touched.password && formik.errors.password
                        }
                    />
                </Stack>
                <Stack className={classes.formInput}>
                    <PasswordField
                        id="confirm-password-input"
                        name="confirm_password"
                        label="Repeat new password"
                        placeholder="Repeat new password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.confirm_password &&
                            Boolean(formik.errors.confirm_password)
                        }
                        helperText={
                            formik.touched.confirm_password &&
                            formik.errors.confirm_password
                        }
                    />
                </Stack>
                <Stack className={classes.rightButtons}>
                    <Button
                        type="button"
                        onClick={(): void => onCancel()}
                        disabled={isLoading}
                        variant="outlined"
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={isLoading}
                        variant="contained"
                        color="primary"
                    >
                        Change Password
                    </Button>
                </Stack>
            </form>
        </div>
    )
}

export default ChangePasswordForm

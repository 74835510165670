import React from 'react'

import ReactDOM from 'react-dom'

import './styles/index.scss'
import App from './App'
import AppProviders from 'AppProviders'
import { appTheme } from 'theme'
import { client } from 'apollo'

ReactDOM.render(
    <AppProviders theme={appTheme} apolloClient={client}>
        <App />
    </AppProviders>,
    document.getElementById('root')
)

import useStyles from './index.styles'

import React, { useEffect, useMemo } from 'react'

import { useFormik } from 'formik'
import Stack from '@mui/material/Stack'
import { Button, InputAdornment } from '@mui/material'
import { useSnackbar } from 'notistack'

import { LabeledTextField } from 'components/inputs'
import { useUser } from 'hooks/useUser'
import validationSchema, {
    initialValues,
    IVesselFormValues,
} from './index.schema'
import Loader from 'components/common/Loader'
import { useVesselApi } from 'hooks/useVessel'
import { IVessel } from 'models/vessel.model'
import SelectField from 'components/inputs/SelectField'
import { useConfig } from 'hooks/useConfig'
import KeyValAutocomplete from 'components/common/KeyValAutocomplete'

export interface IVesselForm {
    id?: string
    companyId: string
    onClose: () => void
}

const VesselForm = ({
    id,
    onClose,
    companyId,
}: IVesselForm): React.ReactElement => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const { user: loggedInUser } = useUser()
    const { vesselBodyTypes, presentFlags } = useConfig()

    const {
        vessel: loadedVessel,
        getVessel,
        addVessel,
        updateVessel,
        isLoading,
    } = useVesselApi()

    const vessel: IVessel | undefined = useMemo(() => {
        if (!id) {
            return undefined
        }
        return loadedVessel
    }, [id, loadedVessel])

    useEffect(() => {
        if (id) {
            getVessel(id)
        }
    }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

    const formValues: IVesselFormValues = useMemo(() => {
        if (vessel) {
            return {
                vesselId: vessel.id,
                name: vessel.name || '',
                bodyType: vessel.bodyType.key || '',
                netRegisterTonnage: vessel.netRegisterTonnage || '',
                grossRegisterTonnage: vessel.grossRegisterTonnage || '',
                lengthOverall: vessel.lengthOverall || '',
                dailyHirePerTc: vessel.dailyHirePerTc || '',
                cifValue: vessel.cifValue || '',
                flag: vessel.flag.key || '',
            } as IVesselFormValues
        }
        return { ...initialValues, companyId }
    }, [vessel, companyId])

    const onVesselSaved = (): void => {
        onClose()
    }

    const formik = useFormik({
        initialValues: formValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values: IVesselFormValues) => {
            if (values.vesselId) {
                updateVessel({ ...values })
                    .then(response => {
                        if (response.success) {
                            enqueueSnackbar('Vessel saved', {
                                variant: 'success',
                            })
                            onVesselSaved()
                        } else {
                            enqueueSnackbar('Vessel not saved', {
                                variant: 'error',
                            })
                        }
                    })
                    .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' })
                    })
            } else {
                addVessel({ ...values })
                    .then(response => {
                        if (response.success) {
                            enqueueSnackbar('Vessel created')
                            onVesselSaved()
                        } else {
                            enqueueSnackbar('Vessel not saved', {
                                variant: 'error',
                            })
                        }
                    })
                    .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' })
                    })
            }
        },
    })
    return (
        <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Stack className={classes.group}>
                <LabeledTextField
                    id="name-input"
                    name="name"
                    label="Vessel Name"
                    placeholder="Vessel name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <SelectField
                    id="vessel-type-input"
                    name="bodyType"
                    label="Vessel Type"
                    options={vesselBodyTypes}
                    value={formik.values.bodyType}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.bodyType &&
                        Boolean(formik.errors.bodyType)
                    }
                    helperText={
                        formik.touched.bodyType && formik.errors.bodyType
                    }
                />
            </Stack>
            <Stack className={classes.group3}>
                <LabeledTextField
                    id="netRegisterTonnage-input"
                    name="netRegisterTonnage"
                    label="NRT"
                    placeholder="NRT"
                    value={formik.values.netRegisterTonnage}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.netRegisterTonnage &&
                        Boolean(formik.errors.netRegisterTonnage)
                    }
                    helperText={
                        formik.touched.netRegisterTonnage &&
                        formik.errors.netRegisterTonnage
                    }
                />
                <LabeledTextField
                    id="grossRegisterTonnage-input"
                    name="grossRegisterTonnage"
                    label="GRT"
                    placeholder="GRT"
                    value={formik.values.grossRegisterTonnage}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.grossRegisterTonnage &&
                        Boolean(formik.errors.grossRegisterTonnage)
                    }
                    helperText={
                        formik.touched.grossRegisterTonnage &&
                        formik.errors.grossRegisterTonnage
                    }
                />
                <LabeledTextField
                    id="lengthOverall-input"
                    name="lengthOverall"
                    label="LOA (m)"
                    placeholder="LOA (m)"
                    value={formik.values.lengthOverall}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.lengthOverall &&
                        Boolean(formik.errors.lengthOverall)
                    }
                    helperText={
                        formik.touched.lengthOverall &&
                        formik.errors.lengthOverall
                    }
                />
            </Stack>

            <Stack className={classes.group3}>
                <KeyValAutocomplete
                    label="Flag"
                    value={formik.values.flag as unknown as string}
                    options={presentFlags}
                    onSelect={(flag): void => {
                        formik.setFieldValue(
                            'flag',
                            (flag as unknown as number) || null
                        )
                    }}
                    error={formik.touched.flag && Boolean(formik.errors.flag)}
                    helperText={formik.touched.flag && formik.errors.flag}
                />
                <LabeledTextField
                    id="dailyHirePerTc-input"
                    name="dailyHirePerTc"
                    label="Daily Hire Per TC (USD)"
                    placeholder="Daily Hire Per TC (USD)"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                    value={formik.values.dailyHirePerTc}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.dailyHirePerTc &&
                        Boolean(formik.errors.dailyHirePerTc)
                    }
                    helperText={
                        formik.touched.dailyHirePerTc &&
                        formik.errors.dailyHirePerTc
                    }
                />
                <LabeledTextField
                    id="cifValue-input"
                    name="cifValue"
                    label="Cif Value (USD)"
                    placeholder="Cif Value (USD)"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                    value={formik.values.cifValue}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.cifValue &&
                        Boolean(formik.errors.cifValue)
                    }
                    helperText={
                        formik.touched.cifValue && formik.errors.cifValue
                    }
                />
            </Stack>
            <Stack className={classes.buttons}>
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={(): void => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    type="submit"
                >
                    Apply
                </Button>
            </Stack>
            {isLoading && <Loader />}
        </form>
    )
}
export default VesselForm

import useStyles from './index.styles'

import React, { Fragment, useCallback, useMemo } from 'react'

import { Stack } from '@mui/material'

import { useServiceList } from 'hooks/useService'
import { IOrder, IOrderService } from 'models/order.model'
import { ICategory } from 'models/category.model'
import { formatMoney } from 'utils/number.utils'
import Loader from 'components/common/Loader'
import { TRender } from 'types/common.types'
import useScreenSize from 'hooks/useScreenSize'

interface IOrderCalculation {
    order?: IOrder
}
const OrderCalculation = ({ order }: IOrderCalculation): JSX.Element => {
    const classes = useStyles()
    const { isTablet } = useScreenSize()
    const { categories, isLoading } = useServiceList()

    const orderServices: Record<string, IOrderService> = useMemo(() => {
        const response: Record<string, IOrderService> = {}

        order?.services?.forEach(service => {
            if (service.quantity > 0) {
                // console.log(service)
                response[service.id] = service
            }
        })
        return response
    }, [order?.services])
    const orderCategories = useMemo(() => {
        const orderServiceKeys = Object.keys(orderServices)
        return categories
            .sort((cat1, cat2) => {
                const getCategoryWeight = (group: number): number => {
                    switch (group) {
                        case 1:
                            return 2
                        case 2:
                            return 1
                        case 3:
                            return 3
                        default:
                            return 4
                    }
                }
                return getCategoryWeight(cat1.group.key) <
                    getCategoryWeight(cat2.group.key)
                    ? -1
                    : 1
            })
            .filter(category => {
                return (
                    category.services.filter(service => {
                        return orderServiceKeys.includes(service.id)
                    }).length > 0
                )
            })
    }, [orderServices, categories])

    const orderAmount: number = useMemo(() => {
        return (
            order?.services?.reduce((acc, item) => {
                return acc + item.amount
            }, 0) || 0
        )
    }, [order])

    const getCategoryServices = (category: ICategory): Array<IOrderService> => {
        const response: Array<IOrderService> = []
        category.services.forEach(service => {
            if (orderServices[service.id]) {
                response.push(orderServices[service.id])
            }
        })
        return response
    }

    const getGroup = (key: number): TRender => {
        if (!orderCategories) return null
        if (key === 0 && orderCategories.length > 0) {
            return (
                <Stack className={classes.groupTitle}>
                    {orderCategories[key].group.val} services
                </Stack>
            )
        }
        if (orderCategories[key] && orderCategories[key - 1]) {
            if (
                orderCategories[key].group.key !==
                orderCategories[key - 1].group.key
            ) {
                return (
                    <Stack className={classes.orderSubtitle}>
                        {orderCategories[key].group.val} services
                    </Stack>
                )
            }
        }
        return null
    }

    const header: TRender = useMemo(() => {
        if (isTablet) {
            return (
                <Stack className={classes.head}>
                    <Stack className={classes.titleHeadMobile}>
                        Service, Rate USD, Unit
                    </Stack>
                    <Stack className={classes.amountHeadMobile}>
                        Amount USD
                    </Stack>
                </Stack>
            )
        }
        return (
            <Stack className={classes.head}>
                <Stack className={classes.titleHead}>Service</Stack>
                <Stack className={classes.rateHead}>Rate USD</Stack>
                <Stack className={classes.unitHead}>Unit</Stack>
                <Stack className={classes.amountHead}>Amount USD</Stack>
            </Stack>
        )
    }, [isTablet, classes])

    const serviceRow = useCallback(
        (service: IOrderService): TRender => {
            if (isTablet) {
                return (
                    <Stack key={service.id} className={classes.serviceMobile}>
                        <Stack className={classes.titleMobile}>
                            {service.title}
                        </Stack>
                        <Stack className={classes.infoMobile}>
                            <Stack className={classes.rateMobile}>
                                {service.rate} {service.description}
                                {' * '}
                                {service.quantity}
                            </Stack>
                            <Stack className={classes.amountMobile}>
                                {formatMoney(service.amount)}
                            </Stack>
                        </Stack>
                    </Stack>
                )
            }
            return (
                <Stack key={service.id} className={classes.service}>
                    <Stack className={classes.title}>{service.title}</Stack>
                    <Stack className={classes.rate}>
                        {service.rate} {service.description}
                    </Stack>
                    <Stack className={classes.unit}>{service.quantity}</Stack>
                    <Stack className={classes.amount}>
                        {formatMoney(service.amount)}
                    </Stack>
                </Stack>
            )
        },
        [isTablet, classes]
    )

    return (
        <Stack className={classes.container}>
            <Stack className={classes.orderTitle}>
                African Harbour Shipping Angola, Lda.
            </Stack>
            <Stack className={classes.orderSubtitle}>
                ANGOLA PORT ESTIMATED DISBURSEMENTS CHARGES
            </Stack>
            {header}
            <Stack className={classes.categories}>
                {isLoading && <Loader />}
                {orderCategories.map((category, key) => (
                    <Fragment key={category.id}>
                        <Stack key={category.id} className={classes.category}>
                            {getGroup(key)}
                            <Stack className={classes.categoryTitle}>{`${
                                key + 1
                            }. ${category.title}`}</Stack>
                            {getCategoryServices(category).map(service =>
                                serviceRow(service)
                            )}
                        </Stack>
                    </Fragment>
                ))}
                <Stack className={classes.summary}>
                    <Stack className={classes.summaryTitle}>
                        GRAND TOTAL USD
                    </Stack>
                    <Stack className={classes.summaryAmount}>
                        {formatMoney(orderAmount)}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}
export default OrderCalculation

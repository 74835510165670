import useStyles from './index.styles'

import React from 'react'

import { Stack } from '@mui/material'

import { IOrder } from 'models/order.model'
import GroupVertical from 'components/common/GroupVertical'
import { daysDiff, formatDate } from 'utils/date.utils'

interface IOrderInfo {
    order: IOrder
}
const OrderInfo = ({ order }: IOrderInfo): JSX.Element => {
    const classes = useStyles()

    return (
        <Stack className={classes.container}>
            {order.portServicesPresent && (
                <>
                    <Stack className={classes.group}>
                        <GroupVertical
                            title="Vessel name"
                            value={order.vesselName}
                        />
                        <GroupVertical
                            title="Vessel type"
                            value={order.vesselBodyType?.val}
                        />
                        <GroupVertical
                            title="NRT"
                            value={order.vesselNetRegisterTonnage}
                        />
                    </Stack>
                    <Stack className={classes.group}>
                        <GroupVertical
                            title="GRT"
                            value={order.vesselGrossRegisterTonnage}
                        />
                        <GroupVertical
                            title="LOA (m)"
                            value={order.vesselLengthOverall}
                        />
                        <GroupVertical
                            title="Flag"
                            value={order.vesselFlag?.val}
                        />
                    </Stack>
                    <Stack className={classes.group}>
                        <GroupVertical title="From" value={order.origin?.val} />
                        <GroupVertical
                            title="To"
                            value={order.destination?.val}
                        />
                    </Stack>
                    <Stack className={classes.divider} />
                    <Stack className={classes.group}>
                        <GroupVertical
                            title="Temporary Importation Needed"
                            value={
                                order.temporaryImportationNeeded ? 'Yes' : 'No'
                            }
                        />
                        <GroupVertical
                            title="Daily Hire per TC (USD)"
                            value={order.vesselDailyHirePerTc}
                        />
                        <GroupVertical
                            title="CIF Value"
                            value={order.vesselCifValue}
                        />
                    </Stack>
                    <Stack className={classes.divider} />
                    <Stack className={classes.group}>
                        <GroupVertical
                            title="ETA"
                            value={formatDate(order.eta)}
                        />
                        <GroupVertical
                            title="ETD"
                            value={formatDate(order.etd)}
                        />
                        <GroupVertical
                            title="Total Days"
                            value={daysDiff(order.eta, order.etd)}
                        />
                    </Stack>
                    <Stack className={classes.divider} />
                    <Stack className={classes.group}>
                        <GroupVertical
                            title="Towage Needed"
                            value={order.towageNeeded ? 'Yes' : 'No'}
                        />
                    </Stack>
                </>
            )}
            {order.crewServicesPresent && (
                <>
                    <Stack className={classes.group}>
                        <GroupVertical
                            title="Crew ETA"
                            value={formatDate(order.crewEta)}
                        />
                        <GroupVertical
                            title="Crew ETD"
                            value={formatDate(order.crewEtd)}
                        />
                        <GroupVertical
                            title="Crew Total Days"
                            value={daysDiff(order.crewEta, order.crewEtd)}
                        />
                    </Stack>
                    <Stack className={classes.group}>
                        <GroupVertical title="HS Code" value={order.hsCode} />
                    </Stack>
                </>
            )}
            {order.materialsServicesPresent && (
                <Stack className={classes.group}>
                    <GroupVertical
                        title="Materials CIF Value"
                        value={order.materialsCifValue}
                    />
                </Stack>
            )}
            <Stack className={classes.divider} />
            {order.portComments && (
                <GroupVertical
                    title="Port Comments"
                    value={order.portComments}
                />
            )}
            {order.crewComments && (
                <GroupVertical
                    title="Crew Comments"
                    value={order.crewComments}
                />
            )}
            {order.materialsComments && (
                <GroupVertical
                    title="Material Comments"
                    value={order.materialsComments}
                />
            )}
        </Stack>
    )
}
export default OrderInfo

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        height: '100vh',
        overflow: 'hidden',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'stretch',
        overflow: 'hidden',
    },
})

export default useStyles

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            padding: '32px 16px',
        },
    },
}))

export default useStyles

import useStyles from './index.styles'

import React, { useCallback, useMemo, useState } from 'react'

import cx from 'classnames'
import { Button, Paper, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import ShipsAgencyIcon from 'assets/images/icons/ShipsAgencyIcon'
import CrewManagementIcon from 'assets/images/icons/CrewManagementIcon'
import MaterialManagementIcon from 'assets/images/icons/MaterialManagementIcon'
import { IReactiveDialog, useDialog } from 'hooks/useDialog'
import { ECategoryGroup, IOrderConfig } from 'models/order.model'
import { paths } from 'config/routes'
import OrderConfigDialog, {
    IOrderConfigDialog,
} from 'components/common/OrderConfigDialog'
import { useOnboarding } from 'hooks/useOnboarding'
import { UserRole } from 'config/user'
import { useUser } from 'hooks/useUser'
import Loader from 'components/common/Loader'

enum EGroups {
    PORT_SERVICES = 1,
    PORT_DOCUMENTS = 2,
    CREW_SERVICES = 3,
    CREW_DOCUMENTS = 4,
    MATERIAL_SERVICES = 5,
    MATERIAL_DOCUMENTS = 6,
}
const Onboarding = (): JSX.Element => {
    const classes = useStyles()
    const { user } = useUser()
    const { enqueueSnackbar } = useSnackbar()

    const [activeSection, setActiveSection] = useState<EGroups>(
        EGroups.PORT_SERVICES
    )
    const navigate = useNavigate()
    const onboardingItems = useOnboarding()

    const downloadChecklist = (group: ECategoryGroup): void => {
        const documentUrl =
            onboardingItems.find(item => item.group.key === group)?.url || ''
        if (documentUrl) {
            window.open(documentUrl, '_blank')
        } else {
            enqueueSnackbar('File not found', { variant: 'warning' })
            // getChecklist()
        }
    }

    const sectionContent = useMemo(() => {
        switch (activeSection) {
            case EGroups.PORT_SERVICES:
                return (
                    onboardingItems.find(
                        item => item.group.key === ECategoryGroup.SHIP
                    )?.servicesDescription || ''
                )
            case EGroups.PORT_DOCUMENTS:
                return (
                    onboardingItems.find(
                        item => item.group.key === ECategoryGroup.SHIP
                    )?.documentsDescription || ''
                )
            case EGroups.CREW_SERVICES:
                return (
                    onboardingItems.find(
                        item => item.group.key === ECategoryGroup.CREW
                    )?.servicesDescription || ''
                )
            case EGroups.CREW_DOCUMENTS:
                return (
                    onboardingItems.find(
                        item => item.group.key === ECategoryGroup.CREW
                    )?.documentsDescription || ''
                )
            case EGroups.MATERIAL_SERVICES:
                return (
                    onboardingItems.find(
                        item => item.group.key === ECategoryGroup.MATERIAL
                    )?.servicesDescription || ''
                )
            case EGroups.MATERIAL_DOCUMENTS:
                return (
                    onboardingItems.find(
                        item => item.group.key === ECategoryGroup.MATERIAL
                    )?.documentsDescription || ''
                )
            default:
                return ''
        }
    }, [onboardingItems, activeSection])

    const sectionTitle = useMemo(() => {
        switch (activeSection) {
            case EGroups.PORT_SERVICES:
                return 'Ships agency list of services'
            case EGroups.PORT_DOCUMENTS:
                return 'Ships agency list of documents'
            case EGroups.CREW_SERVICES:
                return 'Crew management list of services'
            case EGroups.CREW_DOCUMENTS:
                return 'Crew management list of documents'
            case EGroups.MATERIAL_SERVICES:
                return 'Materials management list of services'
            case EGroups.MATERIAL_DOCUMENTS:
                return 'Materials management list of documents'
            default:
                return ''
        }
    }, [activeSection])
    const getGroupClassName = useCallback(
        (group: EGroups): string =>
            cx(classes.sectionTitle, {
                [classes.isActive]: group === activeSection,
            }),
        [classes, activeSection]
    )
    const { open, close } = useDialog()

    const openOrderConfigDialog = useCallback((): void => {
        const addOrderLink =
            user?.role.key === UserRole.ADMIN
                ? paths.admin.addOrder
                : paths.client.addOrder
        const createNewOrder = (config: IOrderConfig): void => {
            close()
            navigate(addOrderLink, {
                state: {
                    orderConfig: config,
                },
            })
        }

        open({
            component: OrderConfigDialog,
            props: {
                onConfirm: createNewOrder,
                onCancel: close,
            },
            options: {
                title: 'Choose services for a new order',
                onClose: close,
            },
        } as IReactiveDialog<IOrderConfigDialog>)
    }, [open, close])

    return (
        <Stack className={classes.container}>
            <Stack className={classes.header}>
                <Stack className={classes.headerText}>
                    <Stack className={classes.headerTitle}>
                        Welcome to African Harbour!
                    </Stack>
                    <Stack className={classes.headerDescription}>
                        See the list of available services, required documents.
                        Then you can calculate price, and place an order.
                    </Stack>
                </Stack>
                <Stack className={classes.headerAction}>
                    <Button onClick={openOrderConfigDialog} color="primary">
                        Calculate price and place an order
                    </Button>
                </Stack>
            </Stack>
            <Stack className={classes.content}>
                <Paper className={classes.section}>
                    <Stack className={classes.title}>Available services</Stack>
                    <Stack className={classes.group}>
                        <Stack className={classes.icon}>
                            <ShipsAgencyIcon />
                        </Stack>
                        <Stack className={classes.sectionContent}>
                            <Stack className={classes.groupTitle}>
                                Ships agency
                            </Stack>
                            <Stack
                                onClick={(): void =>
                                    setActiveSection(EGroups.PORT_SERVICES)
                                }
                                className={getGroupClassName(
                                    EGroups.PORT_SERVICES
                                )}
                            >
                                View full list of services
                            </Stack>
                            <Stack
                                onClick={(): void =>
                                    setActiveSection(EGroups.PORT_DOCUMENTS)
                                }
                                className={getGroupClassName(
                                    EGroups.PORT_DOCUMENTS
                                )}
                            >
                                View full list of documents
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack className={classes.group}>
                        <Stack className={classes.icon}>
                            <CrewManagementIcon />
                        </Stack>
                        <Stack className={classes.sectionContent}>
                            <Stack className={classes.groupTitle}>
                                Crew management
                            </Stack>
                            <Stack
                                onClick={(): void =>
                                    setActiveSection(EGroups.CREW_SERVICES)
                                }
                                className={getGroupClassName(
                                    EGroups.CREW_SERVICES
                                )}
                            >
                                View full list of services
                            </Stack>
                            <Stack
                                onClick={(): void =>
                                    setActiveSection(EGroups.CREW_DOCUMENTS)
                                }
                                className={getGroupClassName(
                                    EGroups.CREW_DOCUMENTS
                                )}
                            >
                                View full list of documents
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack className={classes.group}>
                        <Stack className={classes.icon}>
                            <MaterialManagementIcon />
                        </Stack>
                        <Stack className={classes.sectionContent}>
                            <Stack className={classes.groupTitle}>
                                Materials management
                            </Stack>
                            <Stack
                                onClick={(): void =>
                                    setActiveSection(EGroups.MATERIAL_SERVICES)
                                }
                                className={getGroupClassName(
                                    EGroups.MATERIAL_SERVICES
                                )}
                            >
                                View full list of services
                            </Stack>
                            <Stack
                                onClick={(): void =>
                                    setActiveSection(EGroups.MATERIAL_DOCUMENTS)
                                }
                                className={getGroupClassName(
                                    EGroups.MATERIAL_DOCUMENTS
                                )}
                            >
                                View full list of documents
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
                <Paper className={classes.rightSection}>
                    <Stack className={classes.title}>{sectionTitle}</Stack>
                    {sectionContent && sectionContent.length > 0 ? (
                        <Stack
                            className={classes.sectionText}
                            dangerouslySetInnerHTML={{ __html: sectionContent }}
                        />
                    ) : (
                        <Loader />
                    )}
                    {activeSection === EGroups.PORT_DOCUMENTS && (
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={(): void =>
                                downloadChecklist(ECategoryGroup.SHIP)
                            }
                        >
                            Download full list of documents
                        </Button>
                    )}
                    {activeSection === EGroups.CREW_DOCUMENTS && (
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={(): void =>
                                downloadChecklist(ECategoryGroup.CREW)
                            }
                        >
                            Download full list of documents
                        </Button>
                    )}
                    {activeSection === EGroups.MATERIAL_DOCUMENTS && (
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={(): void =>
                                downloadChecklist(ECategoryGroup.MATERIAL)
                            }
                        >
                            Download full list of documents
                        </Button>
                    )}
                </Paper>
            </Stack>
        </Stack>
    )
}

export default Onboarding

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexBasis: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flexGrow: 0,
        flexShrink: 1,
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            width: '100%',
            flexBasis: '100%',
        },
    },
    logo: {
        width: '274px',
        height: '120px',
        display: 'flex',
        alignSelf: 'center',
        marginBottom: '72px',
    },
    formWrapper: {
        flexGrow: 1,
        width: `min(100%, 550px)`,
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            width: '100%',
        },
    },
    form: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: '40px 24px',
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            flexDirection: 'column',
            margin: 0,
        },
    },
    title: {
        display: 'flex',
        lineHeight: '24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'center',
        marginBottom: '24px',
    },
    subtitle: {
        display: 'flex',
        textAlign: 'center',
        marginTop: '8px',
    },
}))

export default useStyles

import useStyles from './index.styles'

import React, { useCallback, useMemo } from 'react'

import { Button, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { paths } from 'config/routes'
import Plus from 'assets/images/icons/Plus'
import { useUser } from 'hooks/useUser'
import { IOrder, IOrderConfig } from 'models/order.model'
import { prepareUrl } from 'utils/route.utils'
import Orders from 'components/common/Orders'
import OrderConfigDialog, {
    IOrderConfigDialog,
} from 'components/common/OrderConfigDialog'
import { IReactiveDialog, useDialog } from 'hooks/useDialog'
import { useCheckOrders } from 'hooks/useOrder'
import Onboarding from 'components/pages/Onboarding'
import Loader from 'components/common/Loader'

const Home = (): JSX.Element => {
    const { user } = useUser()
    const navigate = useNavigate()
    const { hasOrders, isLoading } = useCheckOrders()

    const classes = useStyles()
    const { open, close } = useDialog()

    const openOrderConfigDialog = useCallback((): void => {
        const createNewOrder = (config: IOrderConfig): void => {
            close()
            navigate(paths.client.addOrder, {
                state: {
                    orderConfig: config,
                },
            })
        }

        open({
            component: OrderConfigDialog,
            props: {
                onConfirm: createNewOrder,
                onCancel: close,
            },
            options: {
                title: 'Choose services for a new order',
                onClose: close,
            },
        } as IReactiveDialog<IOrderConfigDialog>)
    }, [open, close])

    const onOrderClick = useCallback(
        (order: IOrder): void => {
            navigate(prepareUrl(paths.client.viewOrder, { orderId: order.id }))
        },
        [navigate]
    )

    const content = useMemo((): JSX.Element => {
        return hasOrders ? (
            <>
                <Stack className={classes.crumbs}>
                    <Stack className={classes.title}>Orders</Stack>
                    <Button
                        onClick={openOrderConfigDialog}
                        color="primary"
                        startIcon={<Plus />}
                    >
                        New Order
                    </Button>
                </Stack>
                <Stack className={classes.page}>
                    {user?.id && <Orders onOrderClick={onOrderClick} />}
                </Stack>
            </>
        ) : (
            <Onboarding />
        )
    }, [hasOrders, user, classes, onOrderClick, openOrderConfigDialog])
    return (
        <Stack className={classes.container}>
            {isLoading ? <Loader /> : content}
        </Stack>
    )
}

export default Home

import React from 'react'

const AttachmentCheck = (): React.ReactElement => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="18" cy="18" r="18" fill="#1AA449" />
            <g clipPath="url(#clip0_1616_3743)">
                <path
                    d="M15.0004 22.2L10.8004 18L9.40039 19.4L15.0004 25L27.0004 13L25.6004 11.6L15.0004 22.2Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1616_3743">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(6 6)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default AttachmentCheck

import useStyles from './index.styles'

import React from 'react'

import { Typography } from '@mui/material'

const NotFound = (): JSX.Element => {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <Typography variant="h1">Page not found</Typography>
        </div>
    )
}

export default NotFound

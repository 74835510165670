import { DocumentNode, gql } from '@apollo/client'

class OnboardingAPI {
    static onboardingList(): DocumentNode {
        return gql`
            query onboardingList(
                $offset: Int
                $before: String
                $after: String
                $first: Int
                $last: Int
            ) {
                onboardingList(
                    offset: $offset
                    before: $before
                    after: $after
                    first: $first
                    last: $last
                ) {
                    edges {
                        node {
                            documentsDescription
                            servicesDescription
                            group {
                                key
                                val
                            }
                            filename
                            url
                        }
                    }
                }
            }
        `
    }
}

export default OnboardingAPI

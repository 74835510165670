import React from 'react'

import { useUserList } from 'hooks/useUser'
import Table from 'components/common/Table'
import { columns } from './config'
import { ITableRowValue } from 'types/table.types'
import { UserRole } from 'config/user'

interface IAdminList {
    onAction: (id: string, action?: string) => void
}
const AdminList = ({ onAction }: IAdminList): JSX.Element => {
    const { users, isLoading, loadMore, hasNextPage } = useUserList({
        role: UserRole.ADMIN,
    })

    const onCellClicked = (row: ITableRowValue, action?: string): void => {
        if (onAction) onAction(row.id, action)
    }

    return (
        <Table
            columns={columns}
            rows={users as unknown as Array<ITableRowValue>}
            isLoading={isLoading}
            onCellClicked={onCellClicked}
            loadMore={loadMore}
            hasNextPage={hasNextPage}
        />
    )
}

export default AdminList

import { DocumentNode, gql } from '@apollo/client'

import { VESSEL_INFO } from 'api/fragments/vessel.fragment'
import { PAGE_INFO } from 'api/fragments/pagination.fragment'

class VesselAPI {
    static vesselDetails(): DocumentNode {
        return gql`
            query vesselDetails($id: ID!) {
                vesselDetails(id: $id) {
                    ...VesselFields
                }
            }
            ${VESSEL_INFO}
        `
    }

    static addVessel(): DocumentNode {
        return gql`
            ${VESSEL_INFO}
            mutation addVessel($input: AddVesselInput!) {
                addVessel(input: $input) {
                    success
                    errors
                    vessel {
                        ...VesselFields
                    }
                }
            }
        `
    }

    static updateVessel(): DocumentNode {
        return gql`
            ${VESSEL_INFO}
            mutation updateVessel($input: UpdateVesselInput!) {
                updateVessel(input: $input) {
                    success
                    errors
                    vessel {
                        ...VesselFields
                    }
                }
            }
        `
    }

    static deleteVessel(): DocumentNode {
        return gql`
            mutation deleteVessel($input: DeleteVesselInput!) {
                deleteVessel(input: $input) {
                    success
                    errors
                }
            }
        `
    }

    static vesselList(): DocumentNode {
        return gql`
            ${VESSEL_INFO}, ${PAGE_INFO}
            query vesselList(
                $offset: Int
                $before: String
                $after: String
                $first: Int
                $last: Int
                $search: String
                $companyId: String
            ) {
                vesselList(
                    offset: $offset
                    before: $before
                    after: $after
                    first: $first
                    last: $last
                    search: $search
                    companyId: $companyId
                ) {
                    pageInfo {
                        ...PageInfoFields
                    }
                    edges {
                        node {
                            ...VesselFields
                        }
                    }
                }
            }
        `
    }
}

export default VesselAPI

import useStyles from './index.styles'

import React from 'react'

import { Button, Paper, Stack } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { useUser } from 'hooks/useUser'
import { paths } from 'config/routes'
import ContactName from 'assets/images/icons/ContactName'
import { formatUserName } from 'utils/user.utils'
import ContactCompany from 'assets/images/icons/ContactCompany'
import ContactPhone from 'assets/images/icons/ContactPhone'
import ContactEmail from 'assets/images/icons/ContactEmail'
import { EOrderStatus } from 'models/order.model'

const Profile = (): JSX.Element => {
    const classes = useStyles()
    const { user } = useUser()
    const navigate = useNavigate()
    const onEdit = (): void => {
        navigate(paths.client.profileEdit)
    }
    const activeOrdersLink = (): string => {
        return `${paths.client.home}?status=${EOrderStatus.DRAFT},${EOrderStatus.IN_PROGRESS}`
    }
    const totalOrdersLink = (): string => {
        return `${paths.client.home}`
    }
    return (
        <Stack className={classes.container}>
            <Paper className={classes.content}>
                <Stack className={classes.header}>Profile</Stack>
                <Stack className={classes.box}>
                    <Stack className={classes.row}>
                        <Stack className={classes.icon}>
                            <ContactName />
                        </Stack>
                        <Stack className={classes.titleHead}>
                            {formatUserName(user)}
                        </Stack>
                    </Stack>
                    <Stack className={classes.row}>
                        <Stack className={classes.icon} />
                        <Stack className={classes.title}>
                            <Link to={activeOrdersLink()}>
                                {user?.activeOrders || 0} active orders,
                            </Link>
                            <Link to={totalOrdersLink()}>
                                {user?.totalOrders || 0} total
                            </Link>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack className={classes.box}>
                    <Stack className={classes.row}>
                        <Stack className={classes.icon}>
                            <ContactCompany />
                        </Stack>
                        <Stack className={classes.title}>
                            {user?.company?.title || ''}
                        </Stack>
                    </Stack>
                    <Stack className={classes.row}>
                        <Stack className={classes.icon}>
                            <ContactPhone />
                        </Stack>
                        <Stack className={classes.title}>
                            {user?.phone || 'Not set'}
                        </Stack>
                    </Stack>
                    <Stack className={classes.row}>
                        <Stack className={classes.icon}>
                            <ContactEmail />
                        </Stack>
                        <Stack className={classes.title}>
                            {user?.email || ''}
                        </Stack>
                    </Stack>
                </Stack>
                <Stack className={classes.box}>
                    <Stack className={classes.row}>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={onEdit}
                        >
                            Manage Profile
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    )
}

export default Profile

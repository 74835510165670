import useStyles from './index.styles'

import React, { SyntheticEvent, useEffect, useMemo } from 'react'

import { Autocomplete, TextFieldProps } from '@mui/material'

import { LabeledTextField } from 'components/inputs'
import { IKeyVal } from 'types/common.types'

interface IKeyValAutocomplete {
    options: Array<IKeyVal<number | string>>
    label: string
    value: string
    onSelect: (value: string | null) => void
    setSearch?: (search: string) => void
    error?: boolean
    helperText?: string | boolean
}

const KeyValAutocomplete = (props: IKeyValAutocomplete): JSX.Element => {
    const { onSelect, setSearch, options, value, label, error, helperText } =
        props
    const classes = useStyles()

    const [inputValue, setInputValue] = React.useState('')

    useEffect(() => {
        if (setSearch) setSearch(inputValue || '')
    }, [inputValue])

    const selectedOption = useMemo(() => {
        if (!value) return null
        return (
            options.find(
                option => option.key.toString() === value.toString()
            ) || null
        )
    }, [options, value])

    return (
        <Autocomplete
            fullWidth
            value={selectedOption}
            inputValue={inputValue}
            className={classes.container}
            onInputChange={(event, newInputValue, reason): void => {
                setInputValue(newInputValue)
                if (reason === 'clear') {
                    onSelect(null)
                }
            }}
            renderInput={(params: TextFieldProps): JSX.Element => (
                <LabeledTextField
                    {...params}
                    error={error}
                    helperText={helperText}
                    label={label}
                />
            )}
            getOptionLabel={(item: IKeyVal<number | string>): string =>
                item.val || 'label'
            }
            onChange={(
                event: SyntheticEvent<Element, Event>,
                item: IKeyVal<number | string> | null
            ): void => onSelect(item?.key.toString() || null)}
            options={options}
            filterSelectedOptions
        />
    )
}

export default KeyValAutocomplete

import useStyles from './index.styles'

import React, { useEffect, useMemo, useState } from 'react'

import { useFormik } from 'formik'
import Stack from '@mui/material/Stack'
import { Button, IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { CloseOutlined, CopyAllOutlined } from '@mui/icons-material'

import { LabeledTextField } from 'components/inputs'
import { useLazyUserList, useUserApi } from 'hooks/useUser'
import validationSchema, {
    initialValues,
    ICompanyFormValues,
} from './index.schema'
import Loader from 'components/common/Loader'
import { IUser } from 'models/user.model'
import { useCompanyApi } from 'hooks/useCompany'
import { ICompany } from 'models/company.model'
import InviteClientAutocomplete from './InviteClientAutocomplete'
import Avatar from 'assets/images/icons/Avatar'
import ContactAvatar from 'assets/images/icons/ContactAvatar'
import CopyIcon from 'assets/images/icons/CopyIcon'

export enum CompanyTabs {
    COMPANY = 0,
    USERS = 1,
}

export interface ICompanyForm {
    id?: string
    onSuccess: () => void
    onClose: () => void
}

const CompanyForm = ({
    id,
    onSuccess,
    onClose,
}: ICompanyForm): React.ReactElement => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const [companyId, setCompanyId] = useState<string | undefined>(id)
    const { users, getUsers } = useLazyUserList()
    const { deactivateUser, getInvitationLink } = useUserApi()
    const [currentTab, setCurrentTab] = useState<CompanyTabs>(
        CompanyTabs.COMPANY
    )

    useEffect(() => {
        if (companyId) {
            getUsers({ companyId })
        }
    }, [companyId])
    const title: string = useMemo(() => {
        return currentTab === CompanyTabs.COMPANY
            ? '1. Add company details'
            : '2. Invite users'
    }, [currentTab])
    const {
        company: loadedCompany,
        getCompany,
        addCompany,
        updateCompany,
        isLoading,
    } = useCompanyApi()

    const company: ICompany | undefined = useMemo(() => {
        if (!id && !companyId) {
            return undefined
        }
        return loadedCompany
    }, [id, companyId, loadedCompany])

    useEffect(() => {
        if (companyId) {
            getCompany(companyId)
        }
    }, [companyId]) // eslint-disable-line react-hooks/exhaustive-deps

    const onProceed = (): void => {
        if (currentTab === CompanyTabs.COMPANY) setCurrentTab(CompanyTabs.USERS)
        else onSuccess()
    }

    const onBack = (): void => {
        if (currentTab === CompanyTabs.USERS) setCurrentTab(CompanyTabs.COMPANY)
        else onSuccess()
    }

    const formValues: ICompanyFormValues = useMemo(() => {
        if (company) {
            return {
                companyId: company.id,
                title: company.title,
                margin: company.margin,
            } as ICompanyFormValues
        }
        return initialValues
    }, [company])

    const onRemoveUser = (userId: string): void => {
        deactivateUser(userId).then(response => {
            if (response.success) {
                enqueueSnackbar('User removed from company', {
                    variant: 'success',
                })
            }
        })
    }
    const onCopyInvitationLink = (email: string): void => {
        getInvitationLink(email).then(response => {
            if (response.success && response.link) {
                navigator.clipboard.writeText(response.link).then(
                    () => {
                        enqueueSnackbar('Invitation link copied to clipboard', {
                            variant: 'success',
                        })
                    },
                    () => {
                        enqueueSnackbar('Link not copied', {
                            variant: 'warning',
                        })
                    }
                )
            }
        })
    }

    const formik = useFormik({
        initialValues: formValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values: ICompanyFormValues) => {
            if (values.companyId) {
                updateCompany({ ...values })
                    .then(response => {
                        if (response.success) {
                            enqueueSnackbar('Company saved', {
                                variant: 'success',
                            })
                            onProceed()
                        } else {
                            enqueueSnackbar('Company not saved', {
                                variant: 'error',
                            })
                        }
                    })
                    .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' })
                    })
            } else {
                addCompany({ ...values })
                    .then(response => {
                        if (response.success) {
                            setCompanyId(response.company?.id)
                            enqueueSnackbar('Company created')
                            onProceed()
                        } else {
                            enqueueSnackbar('Company not saved', {
                                variant: 'error',
                            })
                        }
                    })
                    .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' })
                    })
            }
        },
    })

    return (
        <Stack className={classes.container}>
            <Stack className={classes.title}>
                <Stack className={classes.titleText}>
                    <span>{title}</span>
                </Stack>
                <IconButton onClick={onClose}>
                    <CloseOutlined />
                </IconButton>
            </Stack>
            {currentTab === CompanyTabs.COMPANY ? (
                <form className={classes.form} onSubmit={formik.handleSubmit}>
                    <Stack className={classes.group}>
                        <LabeledTextField
                            id="company-name-input"
                            name="title"
                            label="Company name"
                            placeholder="Enter company name"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.title &&
                                Boolean(formik.errors.title)
                            }
                            helperText={
                                formik.touched.title && formik.errors.title
                            }
                        />
                        <LabeledTextField
                            id="last-name-input"
                            name="margin"
                            label="Margin (%)"
                            placeholder="Margin"
                            value={formik.values.margin}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.margin &&
                                Boolean(formik.errors.margin)
                            }
                            helperText={
                                formik.touched.margin && formik.errors.margin
                            }
                        />
                    </Stack>

                    <Stack className={classes.buttons}>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={(): void => onBack()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            type="submit"
                        >
                            Proceed
                        </Button>
                    </Stack>
                    {isLoading && <Loader />}
                </form>
            ) : (
                <Stack className={classes.users}>
                    <Stack className={classes.group}>
                        <Stack className={classes.companyTitle}>
                            {company?.title || ''}
                        </Stack>
                    </Stack>
                    <Stack className={classes.group}>
                        <InviteClientAutocomplete companyId={companyId || ''} />
                    </Stack>
                    {companyId && users && (
                        <Stack className={classes.userList}>
                            {users.map((user: IUser) => (
                                <Stack className={classes.user} key={user.id}>
                                    <Stack className={classes.email}>
                                        <ContactAvatar
                                            color={
                                                user.isActive
                                                    ? '#1AA449'
                                                    : '#CAD2D4'
                                            }
                                        />
                                        {user.email}
                                    </Stack>
                                    <Stack className={classes.status}>
                                        {user.isActive ? (
                                            <span
                                                className={classes.addedClient}
                                            >
                                                Added
                                            </span>
                                        ) : (
                                            <span
                                                className={
                                                    classes.pendingClient
                                                }
                                            >
                                                Invitation pending
                                            </span>
                                        )}
                                    </Stack>
                                    <Stack className={classes.actions}>
                                        <IconButton
                                            onClick={(): void =>
                                                onCopyInvitationLink(user.email)
                                            }
                                        >
                                            <CopyIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={(): void =>
                                                onRemoveUser(user.id)
                                            }
                                        >
                                            <CloseOutlined />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    )}

                    <Stack className={classes.buttons}>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={(): void => onBack()}
                        >
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            type="submit"
                            onClick={(): void => onProceed()}
                        >
                            Proceed
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
}
export default CompanyForm

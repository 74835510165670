import React from 'react'

import { CheckboxProps, Checkbox as MuiCheckbox } from '@mui/material'

import CheckboxChecked from 'assets/images/forms/CheckboxChecked'
import CheckboxUnchecked from 'assets/images/forms/CheckboxUnchecked'
import CheckboxIndeterminate from 'assets/images/forms/CheckboxIndeterminate'

const Checkbox: React.FC<CheckboxProps> = (
    props: CheckboxProps
): React.ReactElement => {
    return (
        <MuiCheckbox
            {...props}
            checkedIcon={<CheckboxChecked />}
            icon={<CheckboxUnchecked />}
            indeterminateIcon={<CheckboxIndeterminate />}
            color="primary"
        />
    )
}
export default Checkbox

import React from 'react'

import { useTheme } from '@mui/material'

interface IArrowLeft {
    color?: string
}

const ArrowLeft = (props: IArrowLeft): React.ReactElement => {
    const theme = useTheme()
    const { color = theme.colors.primary } = props
    return (
        <svg
            width="11"
            height="16"
            viewBox="0 0 11 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10 1L2 9L10 17" stroke={color} strokeWidth="2" />
        </svg>
    )
}
export default ArrowLeft

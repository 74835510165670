import { gql } from '@apollo/client'

export const VESSEL_INFO = gql`
    fragment VesselFields on VesselType {
        id
        createdAt
        name
        flag {
            key
            val
        }
        bodyType {
            key
            val
        }
        netRegisterTonnage
        grossRegisterTonnage
        lengthOverall
        dailyHirePerTc
        cifValue
        company {
            id
        }
    }
`

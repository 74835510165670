import { gql } from '@apollo/client'

export const CATEGORY_INFO = gql`
    fragment CategoryFields on CategoryType {
        id
        title
        position
        group {
            key
            val
        }
    }
`

export const SERVICE_INFO = gql`
    fragment ServiceFields on ServiceType {
        id
        title
        description
        rate
        required
        minCondition
        maxCondition
        maxValueCondition
        averageCost
        dependsOn {
            key
            val
        }
        condition {
            key
            val
        }
        movementType {
            key
            val
        }
        unit {
            key
            val
        }
    }
`

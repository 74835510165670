import useStyles from './index.styles'

import React, { useMemo } from 'react'

import { Stack } from '@mui/material'
import cx from 'classnames'

import { IOrder } from 'models/order.model'

interface IOrderStatus {
    order: IOrder
    isSmall?: boolean
}
const OrderStatus = ({ order, isSmall }: IOrderStatus): JSX.Element => {
    const classes = useStyles()
    const orderStatusClasses = useMemo(() => {
        return cx(classes.status, {
            [classes.small]: !!isSmall,
            [classes.statusDraft]: order.status.key === 1,
            [classes.statusInProgress]: order.status.key === 2,
            [classes.statusFinished]: order.status.key === 3,
            [classes.statusCancelled]: order.status.key === 4,
        })
    }, [classes, order, isSmall])

    return <Stack className={orderStatusClasses}>{order.status.val}</Stack>
}

export default OrderStatus

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        backgroundColor: 'transparent',
        overflow: 'hidden',
        gap: '8px',
    },
    title: {
        flexGrow: 1,
        alignItems: 'flex-end',
        color: theme.colors.grey80,
    },
    value: {
        flexGrow: 1,
        alignItems: 'flex-start',
        color: theme.colors.grey100,
    },
}))

export default useStyles

import React from 'react'

import { Navigate } from 'react-router-dom'

import AdminContacts from 'components/pages/admin/Contacts'
import AdminSettings from 'components/pages/admin/Settings'
import AdminCreateOrder from 'components/pages/admin/CreateOrder'
import ClientCreateOrder from 'components/pages/client/CreateOrder'
import AdminViewOrder from 'components/pages/admin/Order'
import ClientViewOrder from 'components/pages/client/Order'
import {
    AdminLayout,
    AuthLayout,
    ClientLayout,
    EmptyLayout,
} from 'components/layout'
import ClientHome from 'components/pages/client/Home'
import ClientVessels from 'components/pages/client/Vessels'
import ClientServices from 'components/pages/client/Services'
import AdminHome from 'components/pages/admin/Home'
import AdminViewContact from 'components/pages/admin/ViewContact'
import { Loading, NotFound } from 'components/pages'
import { TRoute } from 'types/common.types'
import { UserRole } from 'config/user'
import Login from 'components/pages/authorization/Login'
import ForgotPassword from 'components/pages/authorization/ForgotPassword'
import ResetPassword from 'components/pages/authorization/ResetPassword'
import CreatePassword from 'components/pages/authorization/CreatePassword'
import AdminProfile from 'components/pages/admin/Profile'
import AdminProfileEdit from 'components/pages/admin/ProfileEdit'
import ClientProfile from 'components/pages/client/Profile'
import ClientProfileEdit from 'components/pages/client/ProfileEdit'

export const paths = {
    all: '*',
    empty: '',
    home: '/',
    login: '/login',
    logout: '/logout',
    signup: '/signup',
    forgotPassword: '/forgot_password',
    resetPassword: '/reset-password',
    createPassword: '/create-password',
    client: {
        home: '/client',
        vessels: '/client/vessels',
        services: '/client/services',
        profile: '/client/profile',
        profileEdit: '/client/profile-edit',
        addOrder: '/client/add-order',
        editOrder: '/client/edit-order/:orderId',
        viewOrder: '/client/order/:orderId',
    },
    admin: {
        home: '/admin',
        contacts: '/admin/contacts',
        contact: '/admin/contacts/:contactId',
        settings: '/admin/settings',
        profile: '/admin/profile',
        profileEdit: '/admin/profile-edit',
        addOrder: '/admin/add-order',
        editOrder: '/admin/edit-order/:orderId',
        viewOrder: '/admin/order/:orderId',
    },
}

export const resolveHomepageByRole = (role?: UserRole): string => {
    switch (role) {
        case UserRole.ADMIN:
            return paths.admin.home
        case UserRole.CLIENT:
            return paths.client.home
        default:
            return paths.home
    }
}

const routes: Array<TRoute> = [
    {
        path: paths.home,
        element: <AuthLayout />,
        children: [
            {
                path: paths.login,
                element: <Login />,
            },
            {
                path: paths.forgotPassword,
                element: <ForgotPassword />,
            },
            {
                path: paths.resetPassword,
                element: <ResetPassword />,
            },
            {
                path: paths.createPassword,
                element: <CreatePassword />,
            },
        ],
    },
    {
        path: paths.client.home,
        element: <ClientLayout />,
        restrict: [UserRole.CLIENT],
        children: [
            {
                path: paths.client.home,
                element: <ClientHome />,
                index: true,
            },
            {
                path: paths.client.vessels,
                element: <ClientVessels />,
            },
            {
                path: paths.client.services,
                element: <ClientServices />,
            },
            {
                path: paths.client.addOrder,
                element: <ClientCreateOrder />,
            },
            {
                path: paths.client.profile,
                element: <ClientProfile />,
            },
            {
                path: paths.client.profileEdit,
                element: <ClientProfileEdit />,
            },
            {
                path: paths.client.editOrder,
                element: <ClientCreateOrder />,
            },
            {
                path: paths.client.viewOrder,
                element: <ClientViewOrder />,
            },
        ],
    },
    {
        path: paths.admin.home,
        element: <AdminLayout />,
        restrict: [UserRole.ADMIN],
        children: [
            {
                path: paths.admin.home,
                element: <AdminHome />,
                index: true,
            },
            {
                path: paths.admin.contacts,
                element: <AdminContacts />,
            },
            {
                path: paths.admin.contact,
                element: <AdminViewContact />,
            },
            {
                path: paths.admin.profile,
                element: <AdminProfile />,
            },
            {
                path: paths.admin.profileEdit,
                element: <AdminProfileEdit />,
            },
            {
                path: paths.admin.settings,
                element: <AdminSettings />,
            },
            {
                path: paths.admin.addOrder,
                element: <AdminCreateOrder />,
            },
            {
                path: paths.admin.editOrder,
                element: <AdminCreateOrder />,
            },
            {
                path: paths.admin.viewOrder,
                element: <AdminViewOrder />,
            },
        ],
    },
    {
        path: paths.all,
        element: <AuthLayout />,
        children: [
            {
                index: true,
                element: <Navigate replace to={paths.login} />,
            },
        ],
    },
]

export const NotFoundRoute: TRoute = {
    path: paths.all,
    element: <EmptyLayout />,
    children: [
        {
            path: paths.all,
            element: <NotFound />,
            index: true,
        },
    ],
}
export const LoadingRoute: TRoute = {
    path: paths.all,
    element: <EmptyLayout />,
    children: [
        {
            path: paths.all,
            element: <Loading />,
            index: true,
        },
    ],
}
export { routes }

import useStyles from './index.styles'

import { Button, DialogActions } from '@mui/material'
import cx from 'classnames'

interface IDialogButtons {
    onSubmit: () => void
    onCancel: () => void
    submitTitle?: string
    cancelTitle?: string
    submitButtonColor?: 'primary' | 'secondary'
    removePaddings?: boolean
}
const DialogButtons = ({
    onSubmit,
    onCancel,
    removePaddings = true,
    submitTitle = 'Done',
    cancelTitle = 'Cancel',
    submitButtonColor = 'secondary',
}: IDialogButtons): JSX.Element => {
    const classes = useStyles()
    const actionsClassNames = cx({
        [classes.container]: true,
        [classes.contentCompensation]: removePaddings,
    })

    return (
        <DialogActions className={actionsClassNames}>
            <Button color={submitButtonColor} size="small" onClick={onSubmit}>
                {submitTitle}
            </Button>
            <Button variant="outlined" size="small" onClick={onCancel}>
                {cancelTitle}
            </Button>
        </DialogActions>
    )
}
export default DialogButtons

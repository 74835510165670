import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'column',
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    title: {
        ...theme.typography.medium15,
        color: theme.colors.grey80,
    },
    value: {
        ...theme.typography.regular16,
        color: theme.colors.systemBlack,
        wordBreak: 'break-all',
    },
}))

export default useStyles

import { useMutation } from '@apollo/client'

import { TokenStorage } from 'services'
import { AuthAPI } from 'api'
import { IUser } from 'models/user.model'
import { IBooleanResponse } from 'models/api.model'
import { client } from 'apollo'

export interface IAuthResponse {
    success: boolean
    token: string | null
    refreshToken: string | null
    errors: Array<string>
    user?: IUser
}

interface IUseAuth {
    signIn: (email: string, password: string) => Promise<IAuthResponse>
    socialSignIn: (
        accessToken: string,
        provider: string
    ) => Promise<IAuthResponse>
    signUp: (email: string, password: string) => Promise<IBooleanResponse>
    logout: () => Promise<boolean>
    resetPassword: (email: string) => Promise<IBooleanResponse>
    reinviteUser: (email: string) => Promise<IBooleanResponse>
    createNewPassword: (
        password: string,
        uid?: string,
        token?: string
    ) => Promise<IBooleanResponse>
    isLoading: boolean
}

export const useAuth = (): IUseAuth => {
    const [signInApi, { loading: LoginIsProcessing }] = useMutation(
        AuthAPI.singIn()
    )

    const [signUpApi, { loading: registerProcessing }] = useMutation(
        AuthAPI.singUp()
    )
    const [socialApi, { loading: socialAuthIsProcessing }] = useMutation(
        AuthAPI.socialAuth(),
        { errorPolicy: 'all', notifyOnNetworkStatusChange: true }
    )

    const [resetPasswordApi, { loading: resetPasswordLoading }] = useMutation(
        AuthAPI.resetPassword()
    )
    const [reinviteUserApi, { loading: reinviteUserLoading }] = useMutation(
        AuthAPI.reinviteUser()
    )

    const [createPasswordApi, { loading: createPasswordLoading }] = useMutation(
        AuthAPI.createPassword()
    )

    const signIn = (
        email: string,
        password: string
    ): Promise<IAuthResponse> => {
        return signInApi({ variables: { email, password } }).then(response => {
            const { emailSignIn } = response.data
            if (emailSignIn) {
                const { token, refreshToken } = emailSignIn
                TokenStorage.storeToken(token)
                TokenStorage.storeRefreshToken(refreshToken)
                return client.resetStore().then(() => {
                    return emailSignIn as IAuthResponse
                })
            }
            return emailSignIn as IAuthResponse
        })
    }

    const signUp = (
        email: string,
        password: string
    ): Promise<IBooleanResponse> => {
        return signUpApi({
            variables: { input: { email, password } },
        }).then(response => {
            const { emailSignup } = response.data
            return emailSignup
        })
    }

    const socialSignIn = (
        accessToken: string,
        provider: string
    ): Promise<IAuthResponse> => {
        return socialApi({ variables: { accessToken, provider } }).then(
            response => {
                const { socialAuth } = response.data
                if (socialAuth) {
                    const { token, refreshToken } = socialAuth
                    TokenStorage.storeToken(token)
                    TokenStorage.storeRefreshToken(refreshToken)
                    return client.resetStore().then(() => {
                        return socialAuth as IAuthResponse
                    })
                }
                return socialAuth as IAuthResponse
            }
        )
    }

    const logout = (): Promise<boolean> => {
        TokenStorage.clear()
        localStorage.removeItem('role')
        return client.resetStore().then(() => {
            return true
        })
    }

    const resetPassword = (email: string): Promise<IBooleanResponse> => {
        return resetPasswordApi({ variables: { input: { email } } }).then(
            response => response.data.resetPassword
        )
    }

    const reinviteUser = (email: string): Promise<IBooleanResponse> => {
        return reinviteUserApi({ variables: { input: { email } } }).then(
            response => response.data.reinviteUser
        )
    }

    const createNewPassword = (
        password: string,
        uid?: string,
        token?: string
    ): Promise<IBooleanResponse> => {
        return createPasswordApi({
            variables: {
                input: {
                    newPassword1: password,
                    newPassword2: password,
                    uid,
                    token,
                },
            },
        }).then(response => response.data.resetPasswordConfirm)
    }

    return {
        signIn,
        socialSignIn,
        signUp,
        logout,
        resetPassword,
        reinviteUser,
        createNewPassword,
        isLoading:
            resetPasswordLoading ||
            createPasswordLoading ||
            LoginIsProcessing ||
            socialAuthIsProcessing ||
            registerProcessing,
    }
}

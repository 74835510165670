import useStyles from './index.styles'

import React, { useMemo, useState } from 'react'

import { Button, Paper, Stack, Tab } from '@mui/material'
import { useSnackbar } from 'notistack'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { IServiceUpdate, useServiceApi, useServiceList } from 'hooks/useService'
import Services from 'components/pages/admin/Settings/Services'

const isNumeric = (value: string): boolean => {
    return /^\d+(\.\d+)?$/.test(value)
}
enum PageTabs {
    PORT = 'Port Services',
    MATERIAL = 'Material Services',
    CREW = 'Crew Services',
}

const Settings = (): JSX.Element => {
    const classes = useStyles()
    const { categories } = useServiceList()
    const { updateServices } = useServiceApi()
    const { enqueueSnackbar } = useSnackbar()
    const [tabValue, setTabValue] = useState<PageTabs>(PageTabs.PORT)

    const [changedServices, setChangedServices] = useState<
        Record<string, string>
    >({})

    const serviceErrors: Record<string, string> = useMemo(() => {
        const response: Record<string, string> = {}
        Object.entries(changedServices).forEach(([id, value]) => {
            if (value) {
                const rate = parseFloat(value)
                if (rate < 0) {
                    response[id] = 'Value should be > 0'
                } else if (!isNumeric(value)) {
                    response[id] = 'Value should be a number'
                } else if (response[id]) {
                    delete response[id]
                }
            }
        })
        return response
    }, [changedServices])

    const isSaveButtonActive: boolean = useMemo(() => {
        return Object.keys(changedServices).length > 0
    }, [changedServices])

    const saveChanges = (): void => {
        if (Object.keys(serviceErrors).length > 0) {
            enqueueSnackbar('Please fix errors', { variant: 'warning' })
        } else {
            updateServices(
                Object.keys(changedServices).map(serviceId => {
                    return {
                        serviceId,
                        rate: (changedServices[serviceId] ||
                            0) as unknown as number,
                    } as IServiceUpdate
                })
            ).then(() => {
                enqueueSnackbar('Service saved', { variant: 'success' })
                setChangedServices({})
            })
        }
    }

    const setServiceRate = (id: string, newRate: string): void => {
        setChangedServices(changed => {
            return { ...changed, [id]: newRate }
        })
    }
    const onTabChange = (
        event: React.SyntheticEvent,
        activeTab: PageTabs
    ): void => {
        setTabValue(activeTab)
    }

    return (
        <Stack className={classes.container}>
            <Paper className={classes.content}>
                <Stack className={classes.head}>
                    <Stack className={classes.title}>
                        Configure Calculation Parameters
                    </Stack>
                </Stack>
                <TabContext value={tabValue}>
                    <TabList className={classes.tabs} onChange={onTabChange}>
                        <Tab label={PageTabs.PORT} value={PageTabs.PORT} />
                        <Tab label={PageTabs.CREW} value={PageTabs.CREW} />
                        <Tab
                            label={PageTabs.MATERIAL}
                            value={PageTabs.MATERIAL}
                        />
                    </TabList>
                    <TabPanel
                        className={classes.tabContent}
                        value={PageTabs.PORT}
                    >
                        <Services
                            group={2}
                            changedServices={changedServices}
                            setServiceRate={setServiceRate}
                            serviceErrors={serviceErrors}
                        />
                    </TabPanel>
                    <TabPanel
                        className={classes.tabContent}
                        value={PageTabs.CREW}
                    >
                        <Services
                            group={1}
                            changedServices={changedServices}
                            setServiceRate={setServiceRate}
                            serviceErrors={serviceErrors}
                        />
                    </TabPanel>
                    <TabPanel
                        className={classes.tabContent}
                        value={PageTabs.MATERIAL}
                    >
                        <Services
                            group={3}
                            changedServices={changedServices}
                            setServiceRate={setServiceRate}
                            serviceErrors={serviceErrors}
                        />
                    </TabPanel>
                </TabContext>
                <Stack className={classes.buttons}>
                    <Button
                        disabled={!isSaveButtonActive}
                        onClick={saveChanges}
                        color="primary"
                    >
                        Save Changes
                    </Button>
                </Stack>
            </Paper>
        </Stack>
    )
}

export default Settings

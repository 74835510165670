import React, { useMemo } from 'react'

import { TextField as MaterialTextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

import { LabeledTextField } from 'components/inputs/index'
import { IKeyVal } from 'types/common.types'

interface SelectFieldProps {
    id?: string
    name?: string
    label: string
    value?: number | string
    size?: 'small' | 'medium'
    variant?: 'standard' | 'filled' | 'outlined'
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    error?: boolean | undefined
    helperText?: string | false | undefined
    options: Array<IKeyVal<number | string>>
    disabled?: boolean
}

const SelectField = ({
    options,
    value,
    ...rest
}: SelectFieldProps): JSX.Element => {
    const safeValue = useMemo(() => {
        const exists = options.find(option => option.key === value)
        if (exists) return value
        return ''
    }, [options, value])
    return (
        <LabeledTextField
            select
            value={safeValue}
            fullWidth
            autoComplete="off"
            {...rest}
        >
            {options.map(option => (
                <MenuItem key={option.key} value={option.key}>
                    {option.val}
                </MenuItem>
            ))}
        </LabeledTextField>
    )
}

export default SelectField

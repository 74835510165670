import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    heading: {
        fontSize: '200px',
        fontWeight: 900,
        color: theme.palette.primary.main,
    },
}))

export default useStyles

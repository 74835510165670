import React from 'react'

import { TextField as MaterialTextField, TextFieldProps } from '@mui/material'

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
    return (
        <MaterialTextField
            FormHelperTextProps={{
                variant: 'standard',
            }}
            type="search"
            fullWidth
            variant="outlined"
            autoComplete="off"
            {...props}
        />
    )
}

export default TextField

import moment from 'moment'

import { IOrder } from 'models/order.model'

export const DATE_FORMAT = 'DD-MM-YYYY'
export const QUERY_DATE_FORMAT = 'YYYY-MM-DD'

export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'

export const MONTH_FORMAT = 'MMM YYYY'

export const formatDateMonth = (date?: string): string => {
    if (!date) return ''
    return moment(date).format(MONTH_FORMAT)
}

export const formatDate = (date?: string): string => {
    if (!date) return ''
    return moment(date).format(DATE_FORMAT)
}

export const formatDateTime = (date?: string): string => {
    if (!date) return ''
    return moment(date).format(DATE_TIME_FORMAT)
}

export const isBefore = (startTime: string, endTime?: string): boolean => {
    return moment(startTime).isBefore(moment(endTime))
}

export const daysDiff = (startTime?: string, endTime?: string): number => {
    if (!startTime || !endTime) return 0
    return moment(endTime).diff(moment(startTime), 'days')
}

export const isOrdersDifferInDates = (
    first: IOrder,
    second: IOrder
): boolean => {
    if (!first.eta && !second.eta) return false
    return !moment(first.eta).isSame(second.eta, 'month')
}

const CheckboxChecked = (): JSX.Element => {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 18.5005C1.45 18.5005 0.979 18.3048 0.587 17.9135C0.195667 17.5215 0 17.0505 0 16.5005V2.50049C0 1.95049 0.195667 1.47949 0.587 1.08749C0.979 0.696155 1.45 0.500488 2 0.500488H16C16.55 0.500488 17.021 0.696155 17.413 1.08749C17.8043 1.47949 18 1.95049 18 2.50049V16.5005C18 17.0505 17.8043 17.5215 17.413 17.9135C17.021 18.3048 16.55 18.5005 16 18.5005H2ZM7.6 13.2755C7.73333 13.2755 7.85833 13.2548 7.975 13.2135C8.09167 13.1715 8.2 13.1005 8.3 13.0005L13.975 7.32549C14.1583 7.14215 14.25 6.91715 14.25 6.65049C14.25 6.38382 14.15 6.15049 13.95 5.95049C13.7667 5.76715 13.5333 5.67549 13.25 5.67549C12.9667 5.67549 12.7333 5.76715 12.55 5.95049L7.6 10.9005L5.425 8.72549C5.24167 8.54216 5.01667 8.45049 4.75 8.45049C4.48333 8.45049 4.25 8.55049 4.05 8.75049C3.86667 8.93382 3.775 9.16715 3.775 9.45049C3.775 9.73382 3.86667 9.96715 4.05 10.1505L6.9 13.0005C7 13.1005 7.10833 13.1715 7.225 13.2135C7.34167 13.2548 7.46667 13.2755 7.6 13.2755Z"
                fill="#2394A2"
            />
        </svg>
    )
}
export default CheckboxChecked

import { DocumentNode, gql } from '@apollo/client'

import { USER_DATA } from 'api/fragments/user.fragment'
import { PAGE_INFO } from 'api/fragments/pagination.fragment'

class UserAPI {
    static me(): DocumentNode {
        return gql`
            query me {
                me {
                    ...UserInfo
                    totalOrders
                    activeOrders
                }
            }
            ${USER_DATA}
        `
    }

    static userDetails(): DocumentNode {
        return gql`
            query userDetails($id: ID!) {
                userDetails(id: $id) {
                    ...UserInfo
                }
            }
            ${USER_DATA}
        `
    }

    static addUser(): DocumentNode {
        return gql`
            ${USER_DATA}
            mutation addUser($input: AddUserInput!) {
                addUser(input: $input) {
                    success
                    errors
                    user {
                        ...UserInfo
                    }
                }
            }
        `
    }

    static inviteUser(): DocumentNode {
        return gql`
            mutation inviteUser($input: InviteUserInput!) {
                inviteUser(input: $input) {
                    success
                    errors
                }
            }
        `
    }

    static deleteUser(): DocumentNode {
        return gql`
            mutation deleteUser($input: DeleteUserInput!) {
                deleteUser(input: $input) {
                    success
                    errors
                }
            }
        `
    }

    static getInvitationLink(): DocumentNode {
        return gql`
            mutation getInvitationLink($input: GetInvitationLinkInput!) {
                getInvitationLink(input: $input) {
                    link
                    success
                    errors
                }
            }
        `
    }

    static updateUser(): DocumentNode {
        return gql`
            ${USER_DATA}
            mutation updateUser($input: UpdateUserInput!) {
                updateUser(input: $input) {
                    success
                    errors
                    user {
                        ...UserInfo
                    }
                }
            }
        `
    }

    static changePassword(): DocumentNode {
        return gql`
            mutation changePassword($input: ChangePasswordInput!) {
                changePassword(input: $input) {
                    success
                }
            }
        `
    }

    static userList(): DocumentNode {
        return gql`
            ${USER_DATA}, ${PAGE_INFO}
            query userList(
                $offset: Int
                $before: String
                $after: String
                $first: Int
                $last: Int
                $search: String
                $companyId: String
                $role: Float
            ) {
                userList(
                    offset: $offset
                    before: $before
                    after: $after
                    first: $first
                    last: $last
                    search: $search
                    companyId: $companyId
                    role: $role
                ) {
                    pageInfo {
                        ...PageInfoFields
                    }
                    edges {
                        node {
                        ...UserInfo
                        }
                    }
                }
            }
        `
    }
}

export default UserAPI

import React from 'react'

const CrewManagementIcon = (): React.ReactElement => {
    return (
        <svg
            width="42"
            height="38"
            viewBox="0 0 42 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2507 29.3367L15.2505 29.3899C15.2503 29.4686 15.2813 29.5442 15.3371 29.5999C15.3926 29.6556 15.4681 29.687 15.5467 29.687H31.6851C31.8485 29.687 31.9813 29.5542 31.9813 29.3908C31.9813 24.774 28.2328 21.0255 23.616 21.0255C19.0172 21.0255 15.2796 24.7447 15.2507 29.3366L15.2507 29.3367ZM26.2279 24.3389L27.7549 22.8119C29.8597 24.1397 31.2842 26.449 31.3835 29.0947H26.2278L26.2279 24.3389ZM27.2237 22.5053C26.1452 21.9386 24.9177 21.618 23.616 21.618C22.3143 21.618 21.0871 21.9387 20.0084 22.5053L21.5099 24.0068C21.5654 24.0623 21.5966 24.1376 21.5966 24.2162V29.0948H25.6354V24.2162C25.6354 24.1376 25.6667 24.0623 25.7222 24.0068L27.2237 22.5053ZM19.4772 22.8119L21.0042 24.3389V29.0948H15.8489C15.9481 26.4487 17.3727 24.1394 19.4773 22.812L19.4772 22.8119ZM20.2707 10.5104H17.9738C17.8784 10.5104 17.7888 10.5564 17.7331 10.634C17.6776 10.7116 17.6626 10.8112 17.6933 10.9017L19.0314 14.8575C19.0663 14.9605 19.1541 15.0347 19.2585 15.0537C19.2188 15.2916 19.1982 15.5358 19.1982 15.7847C19.1982 18.223 21.1778 20.2024 23.616 20.2024C26.0541 20.2024 28.0337 18.223 28.0337 15.7847C28.0337 15.5357 28.0131 15.2915 27.9734 15.0537C28.0778 15.0347 28.1656 14.9605 28.2005 14.8575L29.5386 10.9017C29.5693 10.8112 29.5543 10.7116 29.4988 10.634C29.4431 10.5564 29.3535 10.5104 29.2581 10.5104H26.9621C26.5829 9.01624 25.2279 7.90918 23.6163 7.90918C22.0049 7.90918 20.6499 9.01602 20.2707 10.5104H20.2707ZM27.3722 15.0587H19.8596C19.8142 15.2939 19.7906 15.5365 19.7906 15.7847C19.7906 17.896 21.5047 19.61 23.616 19.61C25.7272 19.61 27.4413 17.896 27.4413 15.7847C27.4413 15.5365 27.4177 15.2939 27.3724 15.0587H27.3722ZM28.8451 11.1028L27.7072 14.4663H19.5243L18.3864 11.1028H28.8451ZM26.3465 10.5104C25.9842 9.34699 24.8981 8.50157 23.6162 8.50157C22.3343 8.50157 21.248 9.34713 20.8857 10.5104H26.3465Z"
                fill="#153B64"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.000177055 21.4275L7.47123e-07 21.4807C-0.000175549 21.5594 0.0308544 21.635 0.0865692 21.6907C0.142107 21.7464 0.217564 21.7778 0.296198 21.7778H16.4346C16.598 21.7778 16.7308 21.6451 16.7308 21.4816C16.7308 16.8648 12.9823 13.1163 8.3655 13.1163C3.76672 13.1163 0.0291122 16.8355 0.000225953 21.4275L0.000177055 21.4275ZM10.9774 16.4297L12.5044 14.9027C14.6092 16.2305 16.0337 18.5398 16.133 21.1855H10.9773L10.9774 16.4297ZM11.9732 14.5961C10.8947 14.0295 9.66723 13.7088 8.36554 13.7088C7.06386 13.7088 5.8366 14.0295 4.75792 14.5961L6.25937 16.0976C6.31491 16.1531 6.34612 16.2284 6.34612 16.307V21.1856H10.385V16.307C10.385 16.2284 10.4162 16.1531 10.4717 16.0976L11.9732 14.5961ZM4.22669 14.9027L5.75368 16.4297V21.1856H0.598393C0.697653 18.5395 2.12223 16.2302 4.22682 14.9028L4.22669 14.9027ZM5.02024 2.60125H2.72329C2.62791 2.60125 2.53834 2.64727 2.48263 2.72484C2.42709 2.80242 2.41211 2.90203 2.44279 2.99248L3.78094 6.94831C3.81585 7.05127 3.90366 7.1255 4.00803 7.14455C3.96836 7.38238 3.94773 7.62658 3.94773 7.8755C3.94773 10.3138 5.92729 12.2932 8.36547 12.2932C10.8037 12.2932 12.7832 10.3139 12.7832 7.8755C12.7832 7.62656 12.7626 7.38236 12.7229 7.14455C12.8273 7.1255 12.9151 7.05128 12.95 6.94831L14.2882 2.99248C14.3188 2.90203 14.3039 2.80242 14.2483 2.72484C14.1926 2.64727 14.103 2.60125 14.0077 2.60125H11.7116C11.3324 1.10706 9.97744 0 8.36581 0C6.75436 0 5.39942 1.10684 5.0202 2.60125H5.02024ZM12.1217 7.14948H4.60907C4.56376 7.38467 4.54013 7.62727 4.54013 7.87552C4.54013 9.98678 6.25421 11.7009 8.36548 11.7009C10.4768 11.7009 12.1908 9.98678 12.1908 7.87552C12.1908 7.62727 12.1672 7.38468 12.1219 7.14948H12.1217ZM13.5946 3.19364L12.4567 6.55709H4.27381L3.13591 3.19364H13.5946ZM11.096 2.60125C10.7337 1.43781 9.64764 0.592393 8.36572 0.592393C7.08381 0.592393 5.99755 1.43795 5.63525 2.60125H11.096Z"
                fill="#153B64"
            />
            <mask
                id="mask0_2039_5204"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="5"
                y="1"
                width="37"
                height="37"
            >
                <circle cx="23.4279" cy="19.7783" r="18.2217" fill="#2394A2" />
            </mask>
            <g mask="url(#mask0_2039_5204)">
                <circle cx="23.4279" cy="19.7783" r="18.2217" fill="#153B64" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.2507 29.3367L15.2505 29.3899C15.2503 29.4686 15.2813 29.5442 15.3371 29.5999C15.3926 29.6556 15.4681 29.687 15.5467 29.687H31.6851C31.8485 29.687 31.9813 29.5542 31.9813 29.3908C31.9813 24.774 28.2328 21.0255 23.616 21.0255C19.0172 21.0255 15.2796 24.7447 15.2507 29.3366L15.2507 29.3367ZM26.2279 24.3389L27.7549 22.8119C29.8597 24.1397 31.2842 26.449 31.3835 29.0947H26.2278L26.2279 24.3389ZM27.2237 22.5053C26.1452 21.9386 24.9177 21.618 23.616 21.618C22.3143 21.618 21.0871 21.9387 20.0084 22.5053L21.5099 24.0068C21.5654 24.0623 21.5966 24.1376 21.5966 24.2162V29.0948H25.6354V24.2162C25.6354 24.1376 25.6667 24.0623 25.7222 24.0068L27.2237 22.5053ZM19.4772 22.8119L21.0042 24.3389V29.0948H15.8489C15.9481 26.4487 17.3727 24.1394 19.4773 22.812L19.4772 22.8119ZM20.2707 10.5104H17.9738C17.8784 10.5104 17.7888 10.5564 17.7331 10.634C17.6776 10.7116 17.6626 10.8112 17.6933 10.9017L19.0314 14.8575C19.0663 14.9605 19.1541 15.0347 19.2585 15.0537C19.2188 15.2916 19.1982 15.5358 19.1982 15.7847C19.1982 18.223 21.1778 20.2024 23.616 20.2024C26.0541 20.2024 28.0337 18.223 28.0337 15.7847C28.0337 15.5357 28.0131 15.2915 27.9734 15.0537C28.0778 15.0347 28.1656 14.9605 28.2005 14.8575L29.5386 10.9017C29.5693 10.8112 29.5543 10.7116 29.4988 10.634C29.4431 10.5564 29.3535 10.5104 29.2581 10.5104H26.9621C26.5829 9.01624 25.2279 7.90918 23.6163 7.90918C22.0049 7.90918 20.6499 9.01602 20.2707 10.5104H20.2707ZM27.3722 15.0587H19.8596C19.8142 15.2939 19.7906 15.5365 19.7906 15.7847C19.7906 17.896 21.5047 19.61 23.616 19.61C25.7272 19.61 27.4413 17.896 27.4413 15.7847C27.4413 15.5365 27.4177 15.2939 27.3724 15.0587H27.3722ZM28.8451 11.1028L27.7072 14.4663H19.5243L18.3864 11.1028H28.8451ZM26.3465 10.5104C25.9842 9.34699 24.8981 8.50157 23.6162 8.50157C22.3343 8.50157 21.248 9.34713 20.8857 10.5104H26.3465Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.000177055 21.4275L7.47123e-07 21.4807C-0.000175549 21.5594 0.0308544 21.635 0.0865692 21.6907C0.142107 21.7464 0.217564 21.7778 0.296198 21.7778H16.4346C16.598 21.7778 16.7308 21.6451 16.7308 21.4816C16.7308 16.8648 12.9823 13.1163 8.3655 13.1163C3.76672 13.1163 0.0291122 16.8355 0.000225953 21.4275L0.000177055 21.4275ZM10.9774 16.4297L12.5044 14.9027C14.6092 16.2305 16.0337 18.5398 16.133 21.1855H10.9773L10.9774 16.4297ZM11.9732 14.5961C10.8947 14.0295 9.66723 13.7088 8.36554 13.7088C7.06386 13.7088 5.8366 14.0295 4.75792 14.5961L6.25937 16.0976C6.31491 16.1531 6.34612 16.2284 6.34612 16.307V21.1856H10.385V16.307C10.385 16.2284 10.4162 16.1531 10.4717 16.0976L11.9732 14.5961ZM4.22669 14.9027L5.75368 16.4297V21.1856H0.598393C0.697653 18.5395 2.12223 16.2302 4.22682 14.9028L4.22669 14.9027ZM5.02024 2.60125H2.72329C2.62791 2.60125 2.53834 2.64727 2.48263 2.72484C2.42709 2.80242 2.41211 2.90203 2.44279 2.99248L3.78094 6.94831C3.81585 7.05127 3.90366 7.1255 4.00803 7.14455C3.96836 7.38238 3.94773 7.62658 3.94773 7.8755C3.94773 10.3138 5.92729 12.2932 8.36547 12.2932C10.8037 12.2932 12.7832 10.3139 12.7832 7.8755C12.7832 7.62656 12.7626 7.38236 12.7229 7.14455C12.8273 7.1255 12.9151 7.05128 12.95 6.94831L14.2882 2.99248C14.3188 2.90203 14.3039 2.80242 14.2483 2.72484C14.1926 2.64727 14.103 2.60125 14.0077 2.60125H11.7116C11.3324 1.10706 9.97744 0 8.36581 0C6.75436 0 5.39942 1.10684 5.0202 2.60125H5.02024ZM12.1217 7.14948H4.60907C4.56376 7.38467 4.54013 7.62727 4.54013 7.87552C4.54013 9.98678 6.25421 11.7009 8.36548 11.7009C10.4768 11.7009 12.1908 9.98678 12.1908 7.87552C12.1908 7.62727 12.1672 7.38468 12.1219 7.14948H12.1217ZM13.5946 3.19364L12.4567 6.55709H4.27381L3.13591 3.19364H13.5946ZM11.096 2.60125C10.7337 1.43781 9.64764 0.592393 8.36572 0.592393C7.08381 0.592393 5.99755 1.43795 5.63525 2.60125H11.096Z"
                    fill="white"
                />
            </g>
        </svg>
    )
}
export default CrewManagementIcon

import useStyles from './index.styles'

import React from 'react'

import AuthorizationPage from 'components/common/AuthorizationPage'
import ForgotPasswordForm from 'components/form/ForgotPasswordForm'

const ForgotPassword = (): JSX.Element => {
    const classes = useStyles()

    return (
        <AuthorizationPage>
            <div className={classes.container}>
                <ForgotPasswordForm />
            </div>
        </AuthorizationPage>
    )
}

export default ForgotPassword

import useStyles from './index.styles'

import React, { SyntheticEvent, useMemo } from 'react'

import { Autocomplete, TextFieldProps } from '@mui/material'
import { useSnackbar } from 'notistack'

import { TextField } from 'components/inputs'
import { TRender } from 'types/common.types'
import { useUserApi } from 'hooks/useUser'

interface IInviteClientAutocomplete {
    companyId: string
}

const InviteClientAutocomplete = (
    props: IInviteClientAutocomplete
): JSX.Element => {
    const { companyId } = props
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()
    const { inviteUser } = useUserApi()
    const [inputValue, setInputValue] = React.useState('')

    const validateEmail = (email: string): boolean => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }
    const options: Array<string> = useMemo(() => {
        if (validateEmail(inputValue)) return [inputValue]
        return []
    }, [inputValue])

    const onInvite = (email: string | null): void => {
        if (email) {
            inviteUser({ email, companyId }).then(response => {
                if (response.success) {
                    enqueueSnackbar('Invitation sent', {
                        variant: 'success',
                    })
                } else if (response?.errors && response?.errors.length > 0) {
                    enqueueSnackbar(response.errors[0], {
                        variant: 'error',
                    })
                }
                setInputValue('')
            })
        }
    }
    return (
        <Autocomplete
            freeSolo
            fullWidth
            inputValue={inputValue}
            className={classes.container}
            onInputChange={(event, newInputValue): void => {
                setInputValue(newInputValue)
            }}
            noOptionsText="Input email"
            renderInput={(params: TextFieldProps): JSX.Element => (
                <TextField {...params} type="text" label="Invite client" />
            )}
            getOptionLabel={(item: string): string => item || 'label'}
            renderOption={(optionProps, option, { selected }): TRender => (
                <li {...optionProps}>
                    <span className={classes.option}>
                        send invitation to{' '}
                        <span className={classes.optionEmail}>{option}</span>
                    </span>
                </li>
            )}
            onChange={(
                event: SyntheticEvent<Element, Event>,
                item: string | null
            ): void => onInvite(item)}
            options={options}
            filterSelectedOptions
        />
    )
}

export default InviteClientAutocomplete

import useStyles from '../index.styles'
import useServiceStyles from './index.styles'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Stack,
    Typography,
} from '@mui/material'
import { useFormikContext } from 'formik'
import { ExpandMore } from '@mui/icons-material'

import {
    IOrderFormValues,
    IOrderServiceFormValues,
} from 'components/form/OrderForm/index.schema'
import TextField from 'components/inputs/TextField'
import { ICategory } from 'models/category.model'
import Loader from 'components/common/Loader'
import { IService } from 'models/service.model'
import {
    updateServices,
    getChangedValues,
    calculateServiceRate,
    formatServiceRate,
} from '../service.utils'
import { formatMoney } from 'utils/number.utils'

interface IOrderServices {
    categories?: Array<ICategory>
    isLoading?: boolean
}
interface IOrderServiceError {
    quantity: string
}
const OrderServices = ({
    categories = [],
    isLoading,
}: IOrderServices): JSX.Element => {
    const classes = useStyles()
    const serviceClasses = useServiceStyles()
    const formik = useFormikContext<IOrderFormValues>()

    const [formValues, setFormValues] = useState<Record<string, string>>()

    const groupedServices: Record<string, IService> = useMemo(() => {
        const response: Record<string, IService> = {}

        categories.forEach(category => {
            category.services.forEach(service => {
                response[service.id] = service
            })
        })
        return response
    }, [categories])
    useEffect(() => {
        if (Object.keys(groupedServices).length > 0) {
            // need recalculate order services when services loaded
            setFormValues({})
        }
    }, [groupedServices])
    useEffect(() => {
        const formikValues = formik.values as unknown as Record<string, string>
        const newValues: Record<string, string> = getChangedValues(
            formikValues,
            formValues
        )
        if (Object.keys(newValues).length > 0) {
            const newFormValues = {
                ...formValues,
                ...newValues,
                orderId: formikValues.orderId,
            }
            if (!formValues?.orderId && newFormValues.orderId) {
                setFormValues(newFormValues)
            } else {
                const newServiceValues = updateServices(
                    formik.values,
                    Object.keys(newValues),
                    formik.values.serviceList || [],
                    groupedServices
                )
                formik.setFieldValue('serviceList', newServiceValues)
                setFormValues(newFormValues)
            }
        }
    }, [formik.values, formValues, groupedServices])

    const [serviceErrors, setServiceErrors] = useState<
        Record<string, IOrderServiceError>
    >({})

    const [expandedCategories, setExpandedCategories] = useState<
        Record<string, boolean>
    >({})

    const isServiceVisible = useCallback(
        (service: IService) => {
            const formService = formik.values.serviceList?.find(
                item => item.serviceId === service.id
            )
            return formService ? !formService.isSkipped : true
        },
        [formik.values.serviceList]
    )
    const isCategoryVisible = useCallback(
        (category: ICategory) => {
            return category.services.some(service => {
                const formService = formik.values.serviceList?.find(
                    item => item.serviceId === service.id
                )
                return !formService || !formService.isSkipped
            })
        },
        [formik.values.serviceList]
    )

    const getAmount = (id: string, quantity?: string): number => {
        if (quantity && parseFloat(quantity) > 0) {
            return (
                calculateServiceRate(id, formik.values, groupedServices) *
                parseFloat(quantity)
            )
        }
        return 0
    }
    const setServiceQuantity = (id: string, newQuantity?: string): void => {
        const amount = getAmount(id, newQuantity)
        const index = (formik.values.serviceList || [])
            .map((service: IOrderServiceFormValues) => service.serviceId)
            .indexOf(id)
        if (index !== -1) {
            const existingService = formik.values.serviceList
                ? formik.values.serviceList[index]
                : undefined
            if (existingService) {
                formik.setFieldValue(`serviceList.${index}`, {
                    ...existingService,
                    quantity: newQuantity || '',
                    amount,
                })
            }
        } else {
            formik.setFieldValue('serviceList', [
                ...(formik.values.serviceList || []),
                {
                    serviceId: id,
                    quantity: newQuantity || '',
                    amount,
                },
            ])
        }
    }

    useEffect(() => {
        const errors: Record<string, IOrderServiceError> = {}
        const formikServiceErrors: Array<IOrderServiceError | undefined> =
            (formik.errors.serviceList as unknown as Array<
                IOrderServiceError | undefined
            >) || []

        formikServiceErrors.forEach((service, key) => {
            if (service) {
                const serviceId: string | undefined =
                    formik.values.serviceList?.[key]?.serviceId
                if (serviceId) {
                    errors[serviceId] = service
                }
            }
        })
        setServiceErrors(errors)
    }, [formik.errors.serviceList, formik.values.serviceList]) // eslint-disable-line react-hooks/exhaustive-deps

    const getServiceQuantity = (service: IService): string => {
        const existing: IOrderServiceFormValues | undefined =
            formik.values.serviceList?.find(
                item => item.serviceId === service.id
            )
        if (existing) {
            return existing.quantity.toString()
        }
        return ''
    }
    const getServiceAmount = (service: IService): number => {
        const existing: IOrderServiceFormValues | undefined =
            formik.values.serviceList?.find(
                item => item.serviceId === service.id
            )
        if (existing) {
            return existing.amount
        }
        return 0
    }

    const isCategoryExpanded = (category: ICategory): boolean => {
        if (
            Object.prototype.hasOwnProperty.call(
                expandedCategories,
                category.id
            )
        ) {
            return expandedCategories[category.id]
        }
        const services: Array<string> = (formik.values.serviceList || [])
            .filter(service => service.quantity !== '0')
            .map(service => service.serviceId)
        return (
            category.services.filter(service => services.includes(service.id))
                .length > 0
        )
    }

    const setExpanded = (id: string): void => {
        setExpandedCategories(expanded => {
            const current = expanded[id]
            return { ...expanded, [id]: !current }
        })
    }
    return (
        <Stack className={`${classes.formGroup} services`}>
            <Stack className={serviceClasses.head}>
                <Stack className={serviceClasses.titleHeader}>Service</Stack>
                <Stack className={serviceClasses.rateHeader}>Rate usd</Stack>
                <Stack className={serviceClasses.quantityHeader}>
                    Quantity
                </Stack>
                <Stack className={serviceClasses.totalHeader}>Total</Stack>
            </Stack>
            <Stack className={serviceClasses.categories}>
                {isLoading && <Loader />}
                {categories.filter(isCategoryVisible).map((category, key) => (
                    <Accordion
                        key={category.id}
                        expanded={isCategoryExpanded(category)}
                        className={serviceClasses.category}
                        onChange={(): void => setExpanded(category.id)}
                    >
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className={serviceClasses.categorySummary}
                            expandIcon={<ExpandMore />}
                        >
                            <Typography
                                noWrap
                                className={serviceClasses.categoryTitle}
                            >{`${key + 1}. ${category.title}`}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={serviceClasses.category}>
                            <Stack
                                key={category.id}
                                className={serviceClasses.category}
                            >
                                {category.services
                                    .filter(isServiceVisible)
                                    .map(service => (
                                        <Stack
                                            className={
                                                serviceClasses.serviceHolder
                                            }
                                            key={service.id}
                                        >
                                            <Stack
                                                className={
                                                    serviceClasses.service
                                                }
                                            >
                                                <Stack
                                                    className={
                                                        serviceClasses.serviceTitle
                                                    }
                                                >
                                                    {service.title}
                                                    {service.averageCost && (
                                                        <Stack
                                                            className={
                                                                serviceClasses.averageCost
                                                            }
                                                        >
                                                            <span>
                                                                average cost:
                                                            </span>
                                                            <span>
                                                                $
                                                                {formatMoney(
                                                                    service.averageCost
                                                                )}
                                                            </span>
                                                        </Stack>
                                                    )}
                                                </Stack>
                                                <Stack
                                                    className={
                                                        serviceClasses.serviceRate
                                                    }
                                                >
                                                    {formatServiceRate(service)}{' '}
                                                    {service.description}
                                                </Stack>
                                                <Stack
                                                    className={
                                                        serviceClasses.serviceQuantity
                                                    }
                                                >
                                                    <TextField
                                                        type="text"
                                                        value={getServiceQuantity(
                                                            service
                                                        )}
                                                        error={
                                                            !!serviceErrors[
                                                                service.id
                                                            ]
                                                        }
                                                        onChange={(e): void =>
                                                            setServiceQuantity(
                                                                service.id,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                                <Stack
                                                    className={
                                                        serviceClasses.serviceTotal
                                                    }
                                                >
                                                    {formatMoney(
                                                        getServiceAmount(
                                                            service
                                                        )
                                                    )}
                                                </Stack>
                                            </Stack>
                                            {serviceErrors[service.id] && (
                                                <Stack
                                                    className={
                                                        serviceClasses.error
                                                    }
                                                >
                                                    {
                                                        serviceErrors[
                                                            service.id
                                                        ].quantity
                                                    }
                                                </Stack>
                                            )}
                                        </Stack>
                                    ))}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Stack>
        </Stack>
    )
}
export default OrderServices

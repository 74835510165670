import useStyles from './index.styles'

import React from 'react'

import { Paper, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useUser } from 'hooks/useUser'
import { paths } from 'config/routes'
import AdminAside from 'components/common/AdminAside'

const Profile = (): JSX.Element => {
    const classes = useStyles()
    const { user } = useUser()
    const navigate = useNavigate()
    const onEdit = (): void => {
        navigate(paths.admin.profileEdit)
    }
    return (
        <Stack className={classes.container}>
            <Paper className={classes.content}>
                <Stack className={classes.header}>Profile</Stack>
                <AdminAside onEditClick={onEdit} profile={user} />
            </Paper>
        </Stack>
    )
}

export default Profile

import React from 'react'

const OrderDocument = (): React.ReactElement => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_614_2980)">
                <path
                    d="M10.6663 21.3332H21.333V23.9998H10.6663V21.3332ZM10.6663 15.9998H21.333V18.6665H10.6663V15.9998ZM18.6663 2.6665H7.99967C6.53301 2.6665 5.33301 3.8665 5.33301 5.33317V26.6665C5.33301 28.1332 6.51967 29.3332 7.98634 29.3332H23.9997C25.4663 29.3332 26.6663 28.1332 26.6663 26.6665V10.6665L18.6663 2.6665ZM23.9997 26.6665H7.99967V5.33317H17.333V11.9998H23.9997V26.6665Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_614_2980">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default OrderDocument

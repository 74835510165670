import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useLoginFormStyles = makeStyles((theme: Theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        minWidth: '300px',
    },
    formInput: {},
    description: {
        ...theme.typography.regular12,
        color: theme.colors.grey60,
    },
    title: {
        ...theme.typography.medium20,
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
    },
    subtitle: {
        ...theme.typography.regular16,
    },
    subtitleBold: {
        ...theme.typography.medium16,
    },
}))

export default useLoginFormStyles

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

import { topBarHeight } from 'theme'

const useStyles = makeStyles((theme: Theme) => ({
    menuHolder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 1,
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            flexGrow: 0,
        },
    },
    logoLink: {
        display: 'flex',
        justifyContent: 'center',
        margin: '24px 0',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            margin: 0,
        },
    },
    logo: {
        width: '138px',
        height: '60px',
        display: 'flex',
        flexGrow: 0,
        flexShrink: 0,
        justifyContent: 'flex-start',
        alignItems: 'center',
        objectFit: 'contain',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            width: '48px',
            height: '48px',
        },
    },
    menuIconButton: {
        display: 'flex',
        cursor: 'pointer',
    },
    menuList: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    mobileMenu: {
        top: topBarHeight,
        '& .MuiBackdrop-root': {
            top: topBarHeight,
            backgroundColor: 'none',
        },
        '& .MuiDrawer-paper': {
            top: topBarHeight,
            boxShadow: 'none',
        },
    },
}))

export default useStyles

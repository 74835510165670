import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    stepper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    page: {
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        overflowY: 'auto',
        overflowX: 'hidden',
        gap: '16px',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '40px 60px',
        gap: '32px',
        position: 'relative',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '16px',
        },
    },
    orderGroup: {
        gap: '32px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            gap: '12px',
        },
    },
    groupTitle: {
        ...theme.typography.bold16,
    },
    groupRow: {
        flexDirection: 'row',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            flexDirection: 'column',
            gap: '8px',
        },
    },
    customerInfoBlock: {
        flexDirection: 'row',
        flexBasis: '25%',
        gap: '8px',
        alignItems: 'center',
    },
    icon: {
        flexBasis: '24px',
        flexGrow: '0',
    },
    title: {
        ...theme.typography.regular16,
    },
    buttons: {
        flexDirection: 'row',
        gap: '12px',
        justifyContent: 'flex-end',
    },
}))

export default useStyles

import { useCallback, useEffect, useMemo } from 'react'

import {
    ApolloError,
    useLazyQuery,
    useMutation,
    useQuery,
} from '@apollo/client'
import { FileWithPath } from 'react-dropzone'

import { IQueryParams, useExtQuery } from 'hooks/useExtQuery'
import { ITableQueryInfo } from 'types/table.types'
import { IBooleanResponse, IEdgeNode } from 'models/api.model'
import { IOrder } from 'models/order.model'
import OrderAPI from 'api/order.api'
import { IOrderFormValues } from 'components/form/OrderForm/index.schema'

interface IOrderMutationResponse extends IBooleanResponse {
    order?: IOrder
}
interface IOrderPdfResponse extends IBooleanResponse {
    text?: string
}

interface UseChecklistResponse {
    getChecklist: () => void
    filename?: string
    url?: string
    isLoading: boolean
    error: ApolloError | undefined
}

export const useChecklist = (): UseChecklistResponse => {
    const [getData, { data, loading, error }] = useLazyQuery(
        OrderAPI.checklist()
    )
    let response

    if (data?.checklist) {
        response = data.checklist
    }
    const getChecklist = (): void => {
        getData()
    }
    return {
        filename: response?.filename,
        url: response?.url,
        getChecklist,
        isLoading: loading,
        error,
    }
}

interface IUseOrderApi {
    order?: IOrder
    getOrder: (id: string) => Promise<IOrder | undefined>
    addOrder: (values: IOrderFormValues) => Promise<IOrderMutationResponse>
    updateOrder: (values: IOrderFormValues) => Promise<IOrderMutationResponse>
    cloneOrder: (id: string) => Promise<IOrderMutationResponse>
    getOrderPdf: (id: string) => Promise<IOrderPdfResponse>
    changeAdministrator: (
        orderId: string,
        administratorId: string
    ) => Promise<IOrderMutationResponse>
    changeStatus: (
        orderId: string,
        status: string
    ) => Promise<IOrderMutationResponse>
    addOrderAttachments: (
        orderId: string,
        attachments: Array<FileWithPath>
    ) => Promise<IOrderMutationResponse>
    copyOrderAttachments: (
        fromOrderId: string,
        toOrderId: string
    ) => Promise<IOrderMutationResponse>
    deleteOrderAttachments: (
        orderAttachmentIds: Array<string>
    ) => Promise<IOrderMutationResponse>
    isLoading: boolean
}

export const useOrderApi = (): IUseOrderApi => {
    const [getData, { data, loading }] = useLazyQuery(OrderAPI.orderDetails(), {
        notifyOnNetworkStatusChange: true,
    })
    const [getPdf, { loading: pdfLoading }] = useMutation(
        OrderAPI.getOrderPdf(),
        {
            notifyOnNetworkStatusChange: true,
        }
    )

    const [create, { loading: addOrderLoading }] = useMutation(
        OrderAPI.addOrder(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'orderList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    const [update, { loading: updateOrderLoading }] = useMutation(
        OrderAPI.updateOrder(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'orderList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    const [clone, { loading: cloneOrderLoading }] = useMutation(
        OrderAPI.cloneOrder(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'orderList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    const [changeStatusApi, { loading: changeStatusLoading }] = useMutation(
        OrderAPI.changeOrderStatus(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'orderList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    const [addAttachments, { loading: addAttachmentsLoading }] = useMutation(
        OrderAPI.addOrderAttachments(),
        {
            notifyOnNetworkStatusChange: true,
        }
    )
    const [copyAttachments, { loading: copyAttachmentsLoading }] = useMutation(
        OrderAPI.copyOrderDocs(),
        {
            notifyOnNetworkStatusChange: true,
        }
    )

    const [deleteAttachments, { loading: deleteAttachmentsLoading }] =
        useMutation(OrderAPI.deleteOrderAttachments(), {
            notifyOnNetworkStatusChange: true,
        })

    let order: IOrder | undefined

    if (data?.orderDetails) {
        order = data.orderDetails
    }
    const getOrder = (id: string): Promise<IOrder | undefined> => {
        return getData({ variables: { id } }).then(
            response => response.data.orderDetails
        )
    }

    const addOrder = (
        values: IOrderFormValues
    ): Promise<IOrderMutationResponse> => {
        return create({
            variables: { input: values },
        }).then(response => {
            return response.data.addOrder
        })
    }

    const updateOrder = (
        values: IOrderFormValues
    ): Promise<IOrderMutationResponse> => {
        return update({
            variables: { input: values },
        }).then(response => {
            return response.data.updateOrder
        })
    }
    const changeStatus = (
        orderId: string,
        status: string
    ): Promise<IOrderMutationResponse> => {
        return changeStatusApi({
            variables: { input: { orderId, status } },
        }).then(response => {
            return response.data.changeOrderStatus
        })
    }

    const getOrderPdf = (orderId: string): Promise<IOrderPdfResponse> => {
        return getPdf({
            variables: { input: { orderId } },
        }).then(response => {
            return response.data.getOrderPdf
        })
    }

    const cloneOrder = (orderId: string): Promise<IOrderMutationResponse> => {
        return clone({
            variables: { input: { orderId } },
        }).then(response => {
            return response.data.cloneOrder
        })
    }

    const changeAdministrator = (
        orderId: string,
        administratorId: string
    ): Promise<IOrderMutationResponse> => {
        return update({
            variables: { input: { orderId, administratorId } },
        }).then(response => {
            return response.data.updateOrder
        })
    }
    const addOrderAttachments = (
        id: string,
        attachments: Array<FileWithPath>
    ): Promise<IOrderMutationResponse> => {
        return addAttachments({
            variables: { input: { orderId: id, attachments } },
        }).then(response => {
            return response.data.addOrderAttachments
        })
    }

    const copyOrderAttachments = (
        fromOrderId: string,
        toOrderId: string
    ): Promise<IOrderMutationResponse> => {
        return copyAttachments({
            variables: { input: { fromOrderId, toOrderId } },
        }).then(response => {
            return response.data.copyOrderDocs
        })
    }

    const deleteOrderAttachments = (
        orderAttachmentIds: Array<string>
    ): Promise<IOrderMutationResponse> => {
        return deleteAttachments({
            variables: { input: { orderAttachmentIds } },
        }).then(response => {
            return response.data.deleteOrderAttachments
        })
    }

    return {
        addOrder,
        updateOrder,
        changeStatus,
        cloneOrder,
        getOrderPdf,
        changeAdministrator,
        addOrderAttachments,
        copyOrderAttachments,
        deleteOrderAttachments,
        getOrder,
        order,
        isLoading:
            loading ||
            addOrderLoading ||
            updateOrderLoading ||
            addAttachmentsLoading ||
            deleteAttachmentsLoading ||
            copyAttachmentsLoading ||
            changeStatusLoading ||
            cloneOrderLoading ||
            pdfLoading,
    }
}

interface IUseOrderList {
    orders: Array<IOrder>
    isLoading: boolean
    loadMore: () => void
    hasNextPage: boolean
    setSearch: (search: string) => void
}

export const useOrderList = (params?: IQueryParams): IUseOrderList => {
    const { rows, setParam, dataIsLoading, loadMore, hasNextPage } =
        useExtQuery(
            {
                dataApi: OrderAPI.orderList,
                dataPath: 'orderList',
                itemsPerPage: 20,
            } as ITableQueryInfo,
            params
        )
    useEffect(() => {
        if (params) {
            setParam(params)
        }
    }, [params])
    const setSearch = useCallback(
        (str: string): void => {
            setParam('search', str)
        },
        [setParam]
    )

    const orders: Array<IOrder> = useMemo(() => {
        return rows as Array<IOrder>
    }, [rows])

    return {
        orders,
        setSearch,
        loadMore,
        hasNextPage,
        isLoading: dataIsLoading,
    }
}

interface IUseCheckOrders {
    hasOrders: boolean
    isLoading: boolean
}

export const useCheckOrders = (): IUseCheckOrders => {
    const { data, loading } = useQuery(OrderAPI.orderList(false), {
        variables: { limit: 1 },
        errorPolicy: 'all',
    })

    const hasOrders = !!(
        data &&
        data.orderList &&
        data.orderList.edges &&
        data.orderList.edges.length > 0
    )
    return { hasOrders, isLoading: loading }
}

interface IUseLazyOrderList {
    getOrders: (params?: IQueryParams) => void
    fetchMoreOrders: () => void
    orders: Array<IOrder>
    hasNextPage: boolean
    isLoading: boolean
}

export const useLazyOrderList = (
    showAttachments: boolean
): IUseLazyOrderList => {
    const [getData, { data, loading, fetchMore }] = useLazyQuery(
        OrderAPI.orderList(showAttachments)
    )

    let orders: Array<IOrder> = []

    const pageInfo = data?.orderList.pageInfo

    if (data?.orderList) {
        orders = data.orderList.edges.map(
            (edge: IEdgeNode<IOrder>) => edge.node
        )
    }
    const getOrders = (params?: IQueryParams): void => {
        getData({ variables: params })
    }

    const fetchMoreOrders = (): void => {
        if (pageInfo.hasNextPage) {
            fetchMore({ variables: { offset: orders.length } })
        }
    }

    return {
        getOrders,
        fetchMoreOrders,
        orders,
        isLoading: loading,
        hasNextPage: pageInfo?.hasNextPage,
    }
}

import useStyles from './index.styles'

import React from 'react'

import classNames from 'classnames/bind'

import { ITableColumn } from 'types/table.types'
import { RowSelector } from './fields'
import { TRender } from 'types/common.types'

interface ITableHeader {
    columns: Array<ITableColumn>
    onSelect?: (id: string) => void
    onSelectAll?: () => void
    selectedItems?: Array<string>
    isMultiSelect?: boolean
    isLeftSideFixed: boolean
    isRightSideFixed: boolean
    wrapHeaders?: boolean
}
const TableHeader = ({
    columns,
    onSelect,
    selectedItems,
    isMultiSelect,
    onSelectAll,
    isLeftSideFixed,
    isRightSideFixed,
    wrapHeaders,
}: ITableHeader): JSX.Element => {
    const classes = useStyles()
    const cx = classNames.bind(classes)
    const getClassName = (column: ITableColumn, key: number): string =>
        cx({
            header: true,
            wordWrap: wrapHeaders,
            headerFirst: key === 0,
            headerLast: key === columns.length - 1,
            alignStart: column.align === 'start',
            alignEnd: column.align === 'end',
            stickyStart: key === 0 && column.isSticky,
            shadowStart: key === 0 && column.isSticky && isLeftSideFixed,
            stickyEnd: key === columns.length - 1 && column.isSticky,
            shadowEnd:
                key === columns.length - 1 &&
                column.isSticky &&
                isRightSideFixed,
        })

    const getHeaderContent = (column: ITableColumn, key: number): TRender => {
        if (key === 0 && onSelect) {
            if (isMultiSelect) {
                return (
                    <RowSelector
                        data={{
                            row: {
                                id: 'all',
                            },
                            onCellClicked: (): void => {
                                if (onSelectAll) onSelectAll()
                            },
                        }}
                        isMultiSelect={isMultiSelect}
                        isSelected={!!selectedItems?.length}
                    />
                )
            }
            return ''
        }
        return column.headerName || ''
    }

    return (
        <>
            {columns.map((column: ITableColumn, key: number) => (
                <div
                    id={column.field}
                    key={column.field}
                    className={getClassName(column, key)}
                >
                    {getHeaderContent(column, key)}
                </div>
            ))}
        </>
    )
}
export default TableHeader

import {
    ApolloClient,
    ApolloLink,
    from,
    fromPromise,
    Observable,
    NormalizedCacheObject,
    split,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

import { API_ROOT, GRAPHQL_PATH, SUBSCRIPTIONS_ROOT } from 'config/enviropment'
import { AuthAPI } from 'api'
import { TokenStorage } from 'services'
import { cache } from 'apollo/cache'

const graphQlRoot = `${API_ROOT}${GRAPHQL_PATH}`
const wsRoot = `${SUBSCRIPTIONS_ROOT}${GRAPHQL_PATH}`

const httpLink = createUploadLink({
    uri: graphQlRoot,
})

const ApiLink = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
        credentials: 'include',
        headers: {
            ...headers,
            authorization: TokenStorage.isAuthenticated()
                ? TokenStorage.getAuthenticationHeader()
                : '',
        },
    }))

    return forward(operation)
})

const getNewTokenByRefreshToken = (
    refreshToken: string | null
): Observable<ApolloClient<NormalizedCacheObject>> => {
    return fromPromise(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        client
            .mutate({
                mutation: AuthAPI.refreshToken(),
                variables: { refreshToken },
            })
            .then(response => {
                if (response?.data) {
                    const { token, refreshToken: newRefreshToken } =
                        response.data.refreshToken
                    if (token && newRefreshToken) {
                        TokenStorage.storeToken(token)
                        TokenStorage.storeRefreshToken(newRefreshToken)
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        return token
                    }
                }
                return null
            })
            .catch(error => {
                if (
                    error.message === 'Invalid refresh token' ||
                    error.message === 'Refresh token is expired'
                ) {
                    TokenStorage.clear()
                    window.location.href = window.location.origin
                }
            })
    )
}
let shouldRefreshToken = false
/*

const wsClient = new SubscriptionClient(`${wsRoot}`, {
    reconnect: true,
    lazy: false,
    connectionParams: (): Record<string, string | null> => ({
        token: TokenStorage.getToken(),
    }),
})
*/

const wsClient = createClient({
    url: wsRoot,
    lazy: true,
    connectionParams: async (): Promise<{ token: string | null }> => {
        if (shouldRefreshToken) {
            // refresh the token because it is no longer valid
            await getNewTokenByRefreshToken(TokenStorage.getRefreshToken())
            // and reset the flag to avoid refreshing too many times
            shouldRefreshToken = false
        }
        return { token: TokenStorage.getToken() }
    },
    on: {
        closed: event => {
            // if closed with the `4403: Forbidden` close event
            // the client or the server is communicating that the token
            // is no longer valid and should be therefore refreshed
            const err = event as unknown as Record<string, string>
            if (
                [
                    'RuntimeError: Error decoding signature',
                    'RuntimeError: Signature has expired',
                ].includes(err.message)
            )
                shouldRefreshToken = true
        },
    },
})
// eslint-disable-next-line consistent-return
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        const messages = graphQLErrors.map(({ message }) => message)
        if (
            messages.includes('Signature has expired') ||
            messages.includes('Error decoding signature')
        ) {
            return getNewTokenByRefreshToken(TokenStorage.getRefreshToken())
                .filter(value => Boolean(value))
                .flatMap(newToken => {
                    const oldHeaders = operation.getContext().headers
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: `JWT ${newToken}`,
                        },
                    })
                    return forward(operation)
                })
        }
    }
})

// const wsLink = new WebSocketLink(wsClient)
const wsLink = new GraphQLWsLink(wsClient)

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    ApiLink.concat(httpLink)
)

const client = new ApolloClient({
    cache,
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: from([errorLink, splitLink]),
})

export { client }

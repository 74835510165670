import useStyles from './index.styles'

import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik'
import { Button, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { PasswordField } from 'components/inputs'
import {
    CreatePasswordFormScheme,
    CreatePasswordInitialValues,
} from './index.schema'
import { useAuth } from 'hooks/useAuth'
import Loader from 'components/common/Loader'
import { paths } from 'config/routes'

const ResetPasswordForm = (): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { createNewPassword, isLoading } = useAuth()
    const [searchParams] = useSearchParams()
    const [uuid64, setUuid64] = useState<string>('')
    const [token, setToken] = useState<string>('')

    useEffect(() => {
        const queryUuid = searchParams.get('uid64')
        const queryToken = searchParams.get('token')
        if (queryUuid) setUuid64(queryUuid)
        if (queryToken) setToken(queryToken)
    }, [searchParams])

    const formik = useFormik({
        initialValues: CreatePasswordInitialValues,
        validationSchema: CreatePasswordFormScheme,
        onSubmit: values => {
            createNewPassword(values.password, uuid64, token)
                .then(response => {
                    const { success, errors } = response
                    if (success) {
                        enqueueSnackbar('Password has been updated')
                        navigate(paths.login)
                    } else {
                        enqueueSnackbar(errors ? errors[0] : 'Error happened', {
                            variant: 'error',
                        })
                    }
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' })
                })
        },
    })

    const classes = useStyles()

    return (
        <div>
            {isLoading && <Loader />}
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                <Stack className={classes.formInput}>
                    <PasswordField
                        id="password-input"
                        name="password"
                        label="Password"
                        placeholder="Enter your password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                        }
                        helperText={
                            formik.touched.password && formik.errors.password
                        }
                    />
                </Stack>
                <Stack className={classes.formInput}>
                    <PasswordField
                        id="confirm-password-input"
                        name="confirm_password"
                        label="Repeat password"
                        placeholder="Repeat password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.confirm_password &&
                            Boolean(formik.errors.confirm_password)
                        }
                        helperText={
                            formik.touched.confirm_password &&
                            formik.errors.confirm_password
                        }
                    />
                </Stack>
                <Button
                    type="submit"
                    disabled={isLoading}
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Continue
                </Button>
                <Button
                    type="button"
                    onClick={(): void => navigate(paths.login)}
                    disabled={isLoading}
                    fullWidth
                    variant="outlined"
                    color="primary"
                >
                    Go to Login page
                </Button>
            </form>
        </div>
    )
}

export default ResetPasswordForm

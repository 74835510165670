import { array, number, object, string } from 'yup'
import moment from 'moment'

import { isBefore } from 'utils/date.utils'

export interface IOrderServiceFormValues {
    serviceId: string
    quantity: string | number
    amount: number
    rate: number
    required?: boolean
    isSkipped?: boolean
    skipValidation?: boolean
}
export interface IOrderFormValues {
    orderId?: string
    clientId?: string
    movementType?: number
    origin?: number
    destination?: number
    temporaryImportationNeeded?: boolean
    towageNeeded?: boolean
    etd?: string
    eta?: string
    crewEtd?: string
    crewEta?: string
    vesselId?: string
    vesselName?: string
    vesselBodyType?: number
    vesselNetRegisterTonnage?: number
    vesselGrossRegisterTonnage?: number
    vesselLengthOverall?: number
    vesselDailyHirePerTc?: number
    vesselCifValue?: number
    materialsCifValue?: number
    vesselFlag?: number
    portComments?: string
    crewComments?: string
    materialsComments?: string
    hsCode?: string
    crewServicesPresent?: boolean
    portServicesPresent?: boolean
    materialsServicesPresent?: boolean
    serviceList?: Array<IOrderServiceFormValues>
}

export const customerInitialValues: IOrderFormValues = {
    clientId: '',
}

export const shipInitialValues: IOrderFormValues = {
    temporaryImportationNeeded: false,
    towageNeeded: false,
    etd: '',
    eta: '',
    vesselId: '',
    vesselName: '',
    vesselNetRegisterTonnage: 0,
    vesselGrossRegisterTonnage: 0,
    vesselLengthOverall: 0,
    vesselDailyHirePerTc: 0,
    vesselCifValue: 0,
    portComments: '',
    serviceList: [],
}

export const crewInitialValues: IOrderFormValues = {
    crewEtd: '',
    crewEta: '',
    serviceList: [],
}

export const infoValidationSchema = object({
    clientId: string().nullable(true).required('Please select client'),
})
const servicesValidationSchema = array(
    object({
        quantity: number()
            .nullable(true)
            .typeError('Value must be a number')
            .min(0, 'Value can not be negative')
            .test(
                'service_required',
                'Service is required',
                function (value, context) {
                    const { required, isSkipped, skipValidation } =
                        context.parent
                    if (!required || isSkipped || skipValidation) {
                        return true
                    }
                    return !!value && value > 0
                }
            ),
    })
)
export const portValidationSchema = object({
    clientId: string().nullable(true).required('Please select client'),
    vesselName: string().nullable(true).required('Vessel is required'),
    vesselBodyType: number().nullable(true).required('Select Type'),
    vesselNetRegisterTonnage: number()
        .typeError('NRT must be a number')
        .moreThan(0, 'NRT must be greater than 0')
        .required('NRT is required'),
    vesselGrossRegisterTonnage: number()
        .typeError('GRT must be a number')
        .moreThan(0, 'GRT must be greater than 0')
        .required('GRT is required'),
    vesselLengthOverall: number()
        .typeError('LOA must be a number')
        .moreThan(0, 'LOA must be greater than 0')
        .required('LOA is required'),
    vesselDailyHirePerTc: number()
        .nullable(true)
        .typeError('Daily Hire Per TC must be a number')
        .when('temporaryImportationNeeded', {
            is: true,
            then: number()
                .required('Daily Hire Per TC is required')
                .moreThan(0, 'Daily Hire Per TC must be greater than 0'),
        }),
    vesselCifValue: number()
        .nullable(true)
        .typeError('Cif value must be a number')
        .when('temporaryImportationNeeded', {
            is: true,
            then: number()
                .required('Cif value is required')
                .moreThan(0, 'Cif value must be greater than 0'),
        }),
    vesselFlag: number().nullable(true).required('Flag is required'),
    origin: number().nullable(true).required('Origin is required'),
    destination: number().nullable(true).required('Destination is required'),
    movementType: number().nullable(true).required('Select movement type'),
    eta: string()
        .nullable(true)
        .required('ETA is required')
        .test('is future', 'ETA can not be in the past', function (value) {
            return !!value && moment().isSameOrBefore(value, 'day')
        }),
    etd: string()
        .nullable(true)
        .required('ETD is required')
        .test(
            'start_time_test',
            'ETA must be before ETD',
            function (value, context) {
                const { eta } = context.parent
                return !!value && isBefore(eta, value)
            }
        ),
    serviceList: servicesValidationSchema,
})
export const crewValidationSchema = object({
    crewEta: string()
        .nullable(true)
        .required('ETA is required')
        .test('is future', 'ETA can not be in the past', function (value) {
            return !!value && moment().isSameOrBefore(value, 'day')
        }),
    crewEtd: string()
        .nullable(true)
        .required('ETD is required')
        .test(
            'start_time_test',
            'ETA must be before ETD',
            function (value, context) {
                const { crewEta } = context.parent
                return !!value && isBefore(crewEta, value)
            }
        ),
    serviceList: servicesValidationSchema,
})
export const materialsValidationSchema = object({
    materialsCifValue: number()
        .nullable(true)
        .typeError('Cif value must be a number')
        .required('Cif value is required')
        .moreThan(0, 'Cif value must be greater than 0'),

    serviceList: servicesValidationSchema,
})

import React from 'react'

import { FormControlLabel } from '@mui/material'

import { Checkbox } from '..'

interface ICheckboxField {
    name?: string
    label?: string | number | React.ReactElement
    indeterminate?: boolean
    checked?: boolean
    onChange?: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void
}

const CheckboxField = ({
    name,
    label,
    indeterminate,
    checked,
    onChange,
}: ICheckboxField): JSX.Element => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    name={name}
                    onChange={onChange}
                    indeterminate={indeterminate}
                />
            }
            label={label || ''}
        />
    )
}
export default CheckboxField

import { useMediaQuery, useTheme } from '@mui/material'

interface IUseScreenSize {
    isMobile: boolean
    isTablet: boolean
}
const useScreenSize = (): IUseScreenSize => {
    const theme = useTheme()
    const isMobile = useMediaQuery(
        theme.breakpoints.down(theme.breakpoints.values.sm)
    )
    const isTablet = useMediaQuery(
        theme.breakpoints.down(theme.breakpoints.values.md)
    )
    return {
        isMobile,
        isTablet,
    }
}

export default useScreenSize

import { useQuery } from '@apollo/client'

import { IKeyVal } from 'types/common.types'
import { UserRole } from 'config/user'
import ConfigAPI from 'api/config.api'

interface IUseConfig {
    statuses: Array<IKeyVal<string>>
    originTypes: Array<IKeyVal>
    destinationTypes: Array<IKeyVal<string>>
    movementTypes: Array<IKeyVal>
    presentFlags: Array<IKeyVal>
    vesselBodyTypes: Array<IKeyVal>
    roles: Array<IKeyVal<UserRole>>
    categoryGroups: Array<IKeyVal<string>>
    serviceConditions: Array<IKeyVal<string>>
    serviceDependsOn: Array<IKeyVal<string>>
}

export const useConfig = (): IUseConfig => {
    const { data } = useQuery(ConfigAPI.getConfig(), {
        errorPolicy: 'all',
    })
    let config

    if (data?.config) {
        config = data.config as IUseConfig
    }

    return {
        statuses: config?.statuses || [],
        originTypes: config?.originTypes || [],
        destinationTypes: config?.destinationTypes || [],
        movementTypes: config?.movementTypes || [],
        presentFlags: config?.presentFlags || [],
        vesselBodyTypes: config?.vesselBodyTypes || [],
        roles: config?.roles || [],
        categoryGroups: config?.categoryGroups || [],
        serviceConditions: config?.serviceConditions || [],
        serviceDependsOn: config?.serviceDependsOn || [],
    }
}

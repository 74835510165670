import { number, object, string } from 'yup'

export interface ICompanyFormValues {
    companyId?: string
    title: string
    margin: number
}

export const initialValues: ICompanyFormValues = {
    title: '',
    margin: 0,
}

const validationSchema = object({
    title: string().required('Title is required'),
    margin: number()
        .typeError('Price must be a number')
        .min(0, 'value can be from 0 to 100 (%)')
        .max(100, 'value can be from 0 to 100 (%)')
        .required('Margin is required'),
})

export default validationSchema

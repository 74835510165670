import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { alpha } from '@mui/material'

import { topBarHeight } from 'theme'

const useStyles = makeStyles((theme: Theme) => ({
    filters: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        maxHeight: '100%',
        padding: '32px 56px',
        gap: '32px',
    },
    mobileContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '16px',
        '&>div, &>button': {
            display: 'flex',
            flex: '50% 1 1',
        },
    },
    container: {
        flexDirection: 'column',
        gap: '16px',
    },
    filterBlock: {
        flexDirection: 'row',
        gap: '8px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            flexDirection: 'column',
            gap: '16px',
        },
    },
    filterItem: {
        flexBasis: '20%',
        flexDirection: 'row',
        alignItems: 'center',
        '& label': {
            color: theme.colors.grey50,
        },
    },
    numberSearch: {
        backgroundColor: theme.colors.white,
    },
    mobileFilter: {
        padding: '20px 12px',
        gap: '16px',
    },
    filterTitle: {
        ...theme.typography.medium20,
        padding: '12px 0',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    filterPopup: {
        top: topBarHeight,
        '& .MuiBackdrop-root': {
            top: topBarHeight,
            backgroundColor: 'none',
        },
        '& .MuiDrawer-paper': {
            top: topBarHeight,
            boxShadow: 'none',
        },
    },
}))

export default useStyles

import useStyles from './index.styles'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, IconButton, Paper, Stack, Tab } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { IReactiveDialog, useDialog } from 'hooks/useDialog'
import Plus from 'assets/images/icons/Plus'
import VesselsList from 'components/common/VesselsList'
import { paths } from 'config/routes'
import VesselForm, { IVesselForm } from 'components/form/VesselForm'
import ArrowLeft from 'assets/images/icons/ArrowLeft'
import { useCompanyApi } from 'hooks/useCompany'
import ClientList from 'components/pages/admin/Contacts/ClientList'
import UserForm, { IUserForm } from 'components/form/UserForm'
import ConfirmationDialog, {
    IConfirmationDialog,
} from 'components/common/ConfirmationDialog'
import CompanyAside from 'components/common/CompanyAside'

enum PageTabs {
    VESSELS = 'Vessels',
    CLIENTS = 'Clients',
}

const ViewContact = (): JSX.Element => {
    const classes = useStyles()

    const params = useParams()
    const contactId = params.contactId || ''
    const navigate = useNavigate()
    const { company, getCompany, refetchCompany } = useCompanyApi()
    const [tabValue, setTabValue] = useState<PageTabs>(PageTabs.VESSELS)

    const pageTitle: string = useMemo(() => {
        return tabValue === PageTabs.CLIENTS ? 'Clients' : 'Vessels'
    }, [tabValue])

    const { open, close } = useDialog()

    useEffect(() => {
        if (contactId) {
            getCompany(contactId)
        }
    }, [contactId]) // eslint-disable-line react-hooks/exhaustive-deps

    const openVesselForm = useCallback(
        (vesselId?: string): void => {
            open({
                component: VesselForm,
                props: {
                    onSuccess: close,
                    onClose: close,
                    id: vesselId,
                    companyId: contactId,
                },
                options: {
                    title: 'Add Vessel',
                    onClose: close,
                },
            } as IReactiveDialog<IVesselForm>)
        },
        [open, close, contactId]
    )
    const onClientSuccess = useCallback(() => {
        refetchCompany()
        close()
    }, [close, contactId])
    const openClientForm = useCallback(
        (userId?: string): void => {
            open({
                component: UserForm,
                props: {
                    onSuccess: close,
                    onClose: onClientSuccess,
                    id: userId,
                    company,
                },
                options: {
                    title: userId ? 'Manage Client’s Profile' : 'Add client',
                    onClose: close,
                },
            } as IReactiveDialog<IUserForm>)
        },
        [open, close, company, onClientSuccess]
    )

    const openConfirmationDialog = useCallback((): void => {
        open({
            component: ConfirmationDialog,
            props: {
                onConfirm: close,
                onCancel: close,
            },
            options: {
                title: 'Confirm action',
                onClose: close,
            },
        } as IReactiveDialog<IConfirmationDialog>)
    }, [open, close])

    const onClientAction = (id: string, action = 'edit'): void => {
        switch (action) {
            case 'delete':
                openConfirmationDialog()
                break
            case 'edit':
                openClientForm(id)
                break
            default:
                break
        }
    }

    const onTabChange = (
        event: React.SyntheticEvent,
        activeTab: PageTabs
    ): void => {
        setTabValue(activeTab)
    }

    return (
        <Stack className={classes.container}>
            <Stack className={classes.crumbs}>
                <IconButton
                    onClick={(): void => navigate(paths.admin.contacts)}
                >
                    <ArrowLeft />
                </IconButton>
                Back
            </Stack>
            <Stack className={classes.page}>
                <Paper className={classes.profile}>
                    <CompanyAside company={company} />
                </Paper>
                <Paper className={classes.content}>
                    <Stack className={classes.head}>
                        <Stack className={classes.title}>{pageTitle}</Stack>
                        <Stack className={classes.buttons}>
                            {tabValue === PageTabs.VESSELS ? (
                                <Button
                                    onClick={(): void => openVesselForm()}
                                    color="primary"
                                    startIcon={<Plus />}
                                >
                                    Add New Vessel
                                </Button>
                            ) : (
                                <Button
                                    onClick={(): void => openClientForm()}
                                    color="primary"
                                    startIcon={<Plus />}
                                >
                                    Add New Client
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                    <TabContext value={tabValue}>
                        <TabList
                            className={classes.tabs}
                            onChange={onTabChange}
                        >
                            <Tab
                                label={PageTabs.VESSELS}
                                value={PageTabs.VESSELS}
                            />
                            <Tab
                                label={PageTabs.CLIENTS}
                                value={PageTabs.CLIENTS}
                            />
                        </TabList>
                        <TabPanel
                            className={classes.tabContent}
                            value={PageTabs.VESSELS}
                        >
                            <VesselsList companyId={contactId} />
                        </TabPanel>
                        <TabPanel
                            className={classes.tabContent}
                            value={PageTabs.CLIENTS}
                        >
                            <ClientList
                                onAction={onClientAction}
                                companyId={company?.id}
                            />
                        </TabPanel>
                    </TabContext>
                </Paper>
            </Stack>
        </Stack>
    )
}

export default ViewContact

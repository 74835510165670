import { number, object, string } from 'yup'

export interface IVesselFormValues {
    vesselId?: string
    companyId?: string
    name?: string
    bodyType?: string
    netRegisterTonnage?: string
    grossRegisterTonnage?: string
    lengthOverall?: string
    dailyHirePerTc?: string
    cifValue?: string
    flag?: string
}

export const initialValues: IVesselFormValues = {
    name: '',
    bodyType: '',
    netRegisterTonnage: '',
    grossRegisterTonnage: '',
    lengthOverall: '',
    dailyHirePerTc: '',
    cifValue: '',
    flag: '',
}

const validationSchema = object({
    name: string().required('Name is required'),
    bodyType: number().required('Select Type'),
    grossRegisterTonnage: number()
        .typeError('GRT must be a number')
        .moreThan(0, 'GRT must be greater than 0')
        .required('GRT is required'),
    netRegisterTonnage: number()
        .typeError('NRT must be a number')
        .moreThan(0, 'NRT must be greater than 0')
        .required('NRT is required'),
    lengthOverall: number()
        .typeError('LOA must be a number')
        .moreThan(0, 'LOA must be greater than 0')
        .required('LOA is required'),
    dailyHirePerTc: number()
        .typeError('Daily Hire Per TC must be a number')
        .moreThan(0, 'Daily Hire Per TC must be greater than 0')
        .required('Daily Hire Per TC is required'),
    cifValue: number()
        .typeError('Cif value must be a number')
        .moreThan(0, 'Cif value must be greater than 0')
        .required('Cif value is required'),
    flag: number().required('Flag is required'),
})

export default validationSchema

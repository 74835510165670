import useStyles from '../index.styles'

import React, { useEffect, useState } from 'react'

import { InputAdornment, Stack } from '@mui/material'
import { useFormikContext } from 'formik'

import { LabeledTextField } from 'components/inputs'
import { IUser } from 'models/user.model'
import { useUserApi } from 'hooks/useUser'
import { IOrderFormValues } from 'components/form/OrderForm/index.schema'
import VesselAutocomplete from './VesselAutocomplete'
import SelectField from 'components/inputs/SelectField'
import KeyValAutocomplete from 'components/common/KeyValAutocomplete'
import { IVessel } from 'models/vessel.model'
import { useConfig } from 'hooks/useConfig'
import CheckboxField from 'components/inputs/CheckboxField'

const OrderVessel = (): JSX.Element => {
    const classes = useStyles()
    const formik = useFormikContext<IOrderFormValues>()
    const {
        vesselBodyTypes,
        presentFlags,
        movementTypes,
        originTypes,
        destinationTypes,
    } = useConfig()

    const { user, getUser } = useUserApi()
    const [orderClient, setOrderClient] = useState<IUser | null>(null)

    useEffect(() => {
        if (formik.values.clientId && user?.id === formik.values.clientId) {
            setOrderClient(user)
        } else {
            setOrderClient(null)
        }
    }, [user, formik.values.clientId])

    useEffect(() => {
        if (formik.values.clientId) {
            getUser(formik.values.clientId)
        } else {
            setOrderClient(null)
        }
    }, [formik.values.clientId])

    const orderVesselChanged = (
        orderVessel: IVessel | null,
        newName?: string
    ): void => {
        const oldVasselName = formik.values.vesselName
        if (orderVessel) {
            formik.setValues({
                ...formik.values,
                vesselId: orderVessel.id,
                vesselName: orderVessel.name,
                vesselBodyType: orderVessel.bodyType.key,
                vesselNetRegisterTonnage: orderVessel.netRegisterTonnage,
                vesselGrossRegisterTonnage: orderVessel.grossRegisterTonnage,
                vesselLengthOverall: orderVessel.lengthOverall,
                vesselFlag: orderVessel.flag.key,
                vesselDailyHirePerTc: orderVessel.dailyHirePerTc,
                vesselCifValue: orderVessel.cifValue,
            } as unknown as IOrderFormValues)
        } else if (oldVasselName !== newName) {
            formik.setValues({
                ...formik.values,
                vesselId: '',
                vesselName: newName || '',
                vesselBodyType: '',
                vesselNetRegisterTonnage: '',
                vesselGrossRegisterTonnage: '',
                vesselLengthOverall: '',
                vesselFlag: '',
                vesselDailyHirePerTc: 0,
                vesselCifValue: 0,
            } as unknown as IOrderFormValues)
        }
    }
    return (
        <Stack className={classes.formGroup}>
            <Stack className={classes.group}>
                <VesselAutocomplete
                    companyId={orderClient?.company?.id}
                    value={formik.values.vesselId}
                    vesselName={formik.values.vesselName}
                    onSelect={orderVesselChanged}
                    error={
                        formik.touched.vesselName &&
                        Boolean(formik.errors.vesselName)
                    }
                    helperText={
                        formik.touched.vesselName && formik.errors.vesselName
                    }
                />
                <SelectField
                    id="vessel-type-input"
                    name="vesselBodyType"
                    label="Vessel Type"
                    options={vesselBodyTypes}
                    value={formik.values.vesselBodyType || ''}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.vesselBodyType &&
                        Boolean(formik.errors.vesselBodyType)
                    }
                    helperText={
                        formik.touched.vesselBodyType &&
                        formik.errors.vesselBodyType
                    }
                />
            </Stack>
            <Stack className={classes.group3}>
                <LabeledTextField
                    id="vesselNetRegisterTonnage-input"
                    name="vesselNetRegisterTonnage"
                    label="NRT"
                    placeholder="NRT"
                    value={formik.values.vesselNetRegisterTonnage || 0}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.vesselNetRegisterTonnage &&
                        Boolean(formik.errors.vesselNetRegisterTonnage)
                    }
                    helperText={
                        formik.touched.vesselNetRegisterTonnage &&
                        formik.errors.vesselNetRegisterTonnage
                    }
                />
                <LabeledTextField
                    id="vesselGrossRegisterTonnage-input"
                    name="vesselGrossRegisterTonnage"
                    label="GRT"
                    placeholder="GRT"
                    value={formik.values.vesselGrossRegisterTonnage || 0}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.vesselGrossRegisterTonnage &&
                        Boolean(formik.errors.vesselGrossRegisterTonnage)
                    }
                    helperText={
                        formik.touched.vesselGrossRegisterTonnage &&
                        formik.errors.vesselGrossRegisterTonnage
                    }
                />
                <LabeledTextField
                    id="vesselLengthOverall-input"
                    name="vesselLengthOverall"
                    label="LOA (m)"
                    placeholder="LOA (m)"
                    value={formik.values.vesselLengthOverall || 0}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.vesselLengthOverall &&
                        Boolean(formik.errors.vesselLengthOverall)
                    }
                    helperText={
                        formik.touched.vesselLengthOverall &&
                        formik.errors.vesselLengthOverall
                    }
                />
            </Stack>

            <Stack className={classes.group}>
                <KeyValAutocomplete
                    label="Flag"
                    value={formik.values.vesselFlag as unknown as string}
                    options={presentFlags}
                    onSelect={(flag): void => {
                        formik.setFieldValue(
                            'vesselFlag',
                            flag as unknown as number
                        )
                    }}
                    error={
                        formik.touched.vesselFlag &&
                        Boolean(formik.errors.vesselFlag)
                    }
                    helperText={
                        formik.touched.vesselFlag && formik.errors.vesselFlag
                    }
                />
                <KeyValAutocomplete
                    label="Local or Foreign Movement"
                    value={
                        (formik.values.movementType as unknown as string) || ''
                    }
                    options={movementTypes}
                    onSelect={(type): void => {
                        formik.setFieldValue(
                            'movementType',
                            type ? parseFloat(type) : null
                        )
                    }}
                    error={
                        formik.touched.movementType &&
                        Boolean(formik.errors.movementType)
                    }
                    helperText={
                        formik.touched.movementType &&
                        formik.errors.movementType
                    }
                />
            </Stack>
            <Stack className={classes.group}>
                <KeyValAutocomplete
                    label="From"
                    value={formik.values.origin as unknown as string}
                    options={originTypes}
                    onSelect={(origin): void => {
                        formik.setFieldValue(
                            'origin',
                            (origin as unknown as number) || null
                        )
                    }}
                    error={
                        formik.touched.origin && Boolean(formik.errors.origin)
                    }
                    helperText={formik.touched.origin && formik.errors.origin}
                />
                <KeyValAutocomplete
                    label="To"
                    value={formik.values.destination as unknown as string}
                    options={destinationTypes}
                    onSelect={(destination): void => {
                        formik.setFieldValue(
                            'destination',
                            (destination as unknown as number) || null
                        )
                    }}
                    error={
                        formik.touched.destination &&
                        Boolean(formik.errors.destination)
                    }
                    helperText={
                        formik.touched.destination && formik.errors.destination
                    }
                />
            </Stack>
            <Stack className={classes.divider} />
            <CheckboxField
                name="temporaryImportationNeeded"
                label="Temporary Importation Needed"
                checked={formik.values.temporaryImportationNeeded || false}
                onChange={(): void =>
                    formik.setFieldValue(
                        'temporaryImportationNeeded',
                        !formik.values.temporaryImportationNeeded
                    )
                }
            />
            <Stack className={classes.group}>
                <LabeledTextField
                    disabled={!formik.values.temporaryImportationNeeded}
                    id="dailyHirePerTc-input"
                    name="vesselDailyHirePerTc"
                    label="Daily Hire Per TC (USD)"
                    placeholder="Daily Hire Per TC (USD)"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                    value={formik.values.vesselDailyHirePerTc || 0}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.vesselDailyHirePerTc &&
                        Boolean(formik.errors.vesselDailyHirePerTc)
                    }
                    helperText={
                        formik.touched.vesselDailyHirePerTc &&
                        formik.errors.vesselDailyHirePerTc
                    }
                />
                <LabeledTextField
                    disabled={!formik.values.temporaryImportationNeeded}
                    id="cifValue-input"
                    name="vesselCifValue"
                    label="Cif Value (USD)"
                    placeholder="Cif Value (USD)"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                    value={formik.values.vesselCifValue || 0}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.vesselCifValue &&
                        Boolean(formik.errors.vesselCifValue)
                    }
                    helperText={
                        formik.touched.vesselCifValue &&
                        formik.errors.vesselCifValue
                    }
                />
            </Stack>
        </Stack>
    )
}
export default OrderVessel

import useStyles from './index.styles'

import React, { useEffect, useMemo, useState } from 'react'

import { Button, Paper, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import FormStepper from 'components/form/FormStepper'
import { EOrderStep, defaultOrderSteps, EOrderStatus } from 'models/order.model'
import { useOrderApi } from 'hooks/useOrder'
import OrderCalculation from 'components/form/OrderForm/OrderCalculation'
import OrderInfo from 'components/common/ViewOrder/OrderInfo'
import OrderHeader from 'components/form/OrderForm/OrderHeader'
import OrderClient from './OrderClient'
import Loader from 'components/common/Loader'
import OrderDocuments from 'components/form/OrderForm/OrderDocuments'
import { UserRole } from 'config/user'
import { paths } from 'config/routes'
import { prepareUrl } from 'utils/route.utils'
import { useUser } from 'hooks/useUser'
import useScreenSize from 'hooks/useScreenSize'
import OrderStatus from 'components/common/OrderStatus'

interface IViewOrder {
    orderId: string
}
const ViewOrder = ({ orderId }: IViewOrder): JSX.Element => {
    const classes = useStyles()
    const { isTablet } = useScreenSize()
    const { user } = useUser()
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState<EOrderStep>(EOrderStep.INFO)
    const { order, getOrder, getOrderPdf, isLoading } = useOrderApi()
    useEffect(() => {
        getOrder(orderId)
    }, [orderId])

    const orderSteps: Record<number, string> = useMemo(() => {
        const currentOrderSteps = { ...defaultOrderSteps }
        currentOrderSteps[EOrderStep.INFO] = 'View Info'
        currentOrderSteps[EOrderStep.DOCUMENTS] = 'Documents'
        delete currentOrderSteps[EOrderStep.MATERIALS_SERVICES]
        delete currentOrderSteps[EOrderStep.CREW_SERVICES]
        delete currentOrderSteps[EOrderStep.SHIP_SERVICES]
        return currentOrderSteps
    }, [])
    const onSavePdf = (): void => {
        if (orderId) {
            getOrderPdf(orderId).then(response => {
                if (response.success) {
                    window.open(response.text, '_blank')
                }
            })
        }
    }

    const isOrderEditable: boolean = useMemo(() => {
        return !(!user || !order || order.status.key !== EOrderStatus.DRAFT)
    }, [user, order])

    const onEdit = (): void => {
        const getOrderUrl = (): string => {
            if (user?.role.key === UserRole.ADMIN) {
                return paths.admin.editOrder
            }
            return paths.client.editOrder
        }
        navigate(
            prepareUrl(getOrderUrl(), {
                orderId,
            })
        )
    }
    const stepContent = useMemo(() => {
        if (!order) return null
        switch (currentStep) {
            case EOrderStep.CALCULATION:
                return <OrderCalculation order={order} />
            case EOrderStep.DOCUMENTS:
                return <OrderDocuments orderId={order.id} />
            default:
                return <OrderInfo order={order} />
        }
    }, [order, currentStep])

    const onNext = (): void => {
        if (currentStep === EOrderStep.INFO)
            setCurrentStep(EOrderStep.CALCULATION)
        if (currentStep === EOrderStep.CALCULATION)
            setCurrentStep(EOrderStep.DOCUMENTS)
    }

    const onBack = (): void => {
        if (currentStep === EOrderStep.CALCULATION)
            setCurrentStep(EOrderStep.INFO)
        if (currentStep === EOrderStep.DOCUMENTS)
            setCurrentStep(EOrderStep.CALCULATION)
    }
    const stepButtons = useMemo(() => {
        return (
            <Stack className={classes.buttons}>
                {currentStep !== EOrderStep.INFO && (
                    <Button
                        fullWidth={isTablet}
                        variant="contained"
                        color="inherit"
                        onClick={onBack}
                    >
                        Back
                    </Button>
                )}
                {isOrderEditable && (
                    <Button
                        fullWidth={isTablet}
                        variant="outlined"
                        color="primary"
                        onClick={onEdit}
                    >
                        Edit order
                    </Button>
                )}
                {currentStep === EOrderStep.CALCULATION && (
                    <Button
                        fullWidth={isTablet}
                        variant="contained"
                        color="secondary"
                        type="button"
                        disabled={isLoading}
                        onClick={(): void => onSavePdf()}
                    >
                        Save pdf
                    </Button>
                )}
                {currentStep !== EOrderStep.DOCUMENTS && (
                    <Button
                        fullWidth={isTablet}
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={onNext}
                    >
                        Next
                    </Button>
                )}
            </Stack>
        )
    }, [currentStep, isTablet, isOrderEditable])

    const onStepChange = (step: number): void => {
        setCurrentStep(step)
    }
    return (
        <>
            <Stack className={classes.stepper}>
                <FormStepper
                    steps={orderSteps}
                    currentStep={currentStep}
                    onStepChange={onStepChange}
                />
            </Stack>
            <Stack className={classes.page}>
                {order ? (
                    <>
                        <Paper className={classes.paper}>
                            <OrderHeader order={order} />
                            <OrderClient client={order.client} />
                        </Paper>
                        <Paper className={classes.paper}>
                            {stepContent}
                            {stepButtons}
                        </Paper>
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </>
    )
}
export default ViewOrder

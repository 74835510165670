import { object, string } from 'yup'
import parsePhoneNumberFromString from 'libphonenumber-js'

import { emailValidator } from 'components/form/index.validators'

export interface IUserFormValues {
    userId?: string
    email?: string
    isAdmin?: boolean
    firstName?: string
    lastName?: string
    phone?: string
}

export const initialValues: IUserFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    isAdmin: true,
    phone: '',
}

const validationSchema = object({
    email: string().test(emailValidator).required('Email must be valid'),
    phone: string()
        .nullable()
        .test('phone-number', 'Phone number must be valid', (value = '') => {
            if (!value) {
                return true
            }
            const result = parsePhoneNumberFromString(value)
            if (result) {
                return result.isValid()
            }
            return false
        }),
    firstName: string()
        .nullable()
        .min(1, 'First name min length should be of 1 character')
        .required('First name is required'),
    lastName: string()
        .nullable()
        .min(1, 'Last name min length should be of 1 character')
        .required('Last name is required'),
})

export default validationSchema

import useStyles from './index.styles'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Stack,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import { useLazyVesselList, useVesselApi } from 'hooks/useVessel'
import VesselCell from './VesselCell'
import VesselForm, { IVesselForm } from 'components/form/VesselForm'
import { IReactiveDialog, useDialog } from 'hooks/useDialog'
import ConfirmationDialog, {
    IConfirmationDialog,
} from 'components/common/ConfirmationDialog'
import { useUser } from 'hooks/useUser'

interface IVesselsList {
    companyId?: string
}
const VesselsList = ({ companyId }: IVesselsList): JSX.Element => {
    const classes = useStyles()
    const { user } = useUser()
    const { vessels, getVessels } = useLazyVesselList()
    const { deleteVessel } = useVesselApi()
    const [expanded, setExpanded] = useState<string | null>(null)
    const { enqueueSnackbar } = useSnackbar()
    const { open, close } = useDialog()

    useEffect(() => {
        getVessels({ companyId: companyId || null })
    }, [companyId])

    const openVesselForm = useCallback(
        (vesselId: string): void => {
            open({
                component: VesselForm,
                props: {
                    onSuccess: close,
                    onClose: close,
                    id: vesselId,
                    companyId,
                },
                options: {
                    title: 'Edit Vessel',
                    onClose: close,
                },
            } as IReactiveDialog<IVesselForm>)
        },
        [open, close, companyId]
    )

    const openConfirmationDialog = useCallback(
        (vesselId: string): void => {
            const confirmDeleteVessel = (): void => {
                deleteVessel(vesselId).then(response => {
                    close()
                    if (response.success) {
                        enqueueSnackbar('Vessel deleted', {
                            variant: 'success',
                        })
                    } else {
                        enqueueSnackbar('Error happened while deleting', {
                            variant: 'error',
                        })
                    }
                })
            }
            open({
                component: ConfirmationDialog,
                props: {
                    onConfirm: () => confirmDeleteVessel(),
                    onCancel: close,
                },
                options: {
                    title: 'Delete vessel',
                    onClose: close,
                },
            } as IReactiveDialog<IConfirmationDialog>)
        },
        [open, close]
    )

    return (
        <Stack>
            {vessels.length === 0
                ? 'Company don’t have any vessels added yet'
                : vessels.map(vessel => (
                      <Accordion
                          key={vessel.id}
                          expanded={expanded === vessel.id}
                          className={classes.vessel}
                          onChange={(): void =>
                              setExpanded(current =>
                                  current === vessel.id ? null : vessel.id
                              )
                          }
                      >
                          <AccordionSummary
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className={classes.summary}
                              expandIcon={<ExpandMore />}
                          >
                              <Stack className={classes.name}>
                                  {vessel.name}
                              </Stack>
                              <Stack>{vessel.bodyType.val}</Stack>
                          </AccordionSummary>
                          <AccordionDetails className={classes.vesselDetails}>
                              <Stack className={classes.vesselRow}>
                                  <VesselCell
                                      title="NRT:"
                                      value={vessel.netRegisterTonnage}
                                  />
                                  <VesselCell
                                      title="GRT:"
                                      value={vessel.grossRegisterTonnage}
                                  />
                                  <VesselCell
                                      title="LOA(m):"
                                      value={vessel.lengthOverall}
                                  />
                              </Stack>
                              <Stack className={classes.vesselRow}>
                                  <VesselCell
                                      title="Flag:"
                                      value={vessel.flag.val}
                                  />
                              </Stack>
                              <Stack className={classes.vesselRow}>
                                  <VesselCell
                                      title="Daily Hire per TC (USD):"
                                      value={vessel.dailyHirePerTc}
                                  />
                                  <VesselCell
                                      title="CIF Value (USD):"
                                      value={vessel.cifValue}
                                  />
                              </Stack>
                              <Stack className={classes.vesselRow}>
                                  <Stack className={classes.buttons}>
                                      <Button
                                          variant="outlined"
                                          color="secondary"
                                          onClick={(): void =>
                                              openVesselForm(vessel.id)
                                          }
                                      >
                                          Edit
                                      </Button>
                                      <Button
                                          variant="outlined"
                                          color="secondary"
                                          onClick={(): void =>
                                              openConfirmationDialog(vessel.id)
                                          }
                                      >
                                          Remove
                                      </Button>
                                  </Stack>
                              </Stack>
                          </AccordionDetails>
                      </Accordion>
                  ))}
        </Stack>
    )
}

export default VesselsList

import { DocumentNode, gql } from '@apollo/client'

import { PAGE_INFO } from 'api/fragments/pagination.fragment'
import { CATEGORY_INFO, SERVICE_INFO } from 'api/fragments/service.fragment'

class ServiceAPI {
    static updateServices(): DocumentNode {
        return gql`
            ${SERVICE_INFO}
            mutation updateServices($input: UpdateServicesInput!) {
                updateServices(input: $input) {
                    success
                    errors
                    services {
                        ...ServiceFields
                    }
                }
            }
        `
    }

    static categoryList(): DocumentNode {
        return gql`
            ${SERVICE_INFO}, ${CATEGORY_INFO}, ${PAGE_INFO}
            query categoryList(
                $offset: Int
                $before: String
                $after: String
                $first: Int
                $last: Int
                $group: Float
                $search: String
            ) {
                categoryList(
                    offset: $offset
                    before: $before
                    after: $after
                    first: $first
                    last: $last
                    group: $group
                    search: $search
                ) {
                    pageInfo {
                        ...PageInfoFields
                    }
                    edges {
                        node {
                            ...CategoryFields
                            services {
                                ...ServiceFields
                            }
                        }
                    }
                }
            }
        `
    }
}

export default ServiceAPI

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { alpha } from '@mui/material'

import { draftColor, inProgressColor } from 'theme'

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        display: 'inline-block',
        maxWidth: '100%',
        overflow: 'auto',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            width: '100%',
        },
    },
    container: {
        display: 'grid',
        padding: '0',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            width: '100%',
            gap: '16px',
        },
    },
    header: {
        ...theme.typography.bold14,
        color: theme.colors.systemBlack,
        backgroundColor: theme.colors.white,
        zIndex: '100',
        minHeight: '20px',
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '12px 8px',
        top: 0,
        textTransform: 'uppercase',
    },
    cell: {
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: '48px',
        whiteSpace: 'nowrap',
        display: 'flex',
        padding: '6px 8px',
        '& fieldset': {
            display: 'none',
        },
        '& .MuiSelect-select': {
            backgroundColor: theme.colors.grey20,
        },
        '& .MuiInputLabel-shrink': {
            display: 'none',
        },
        '& label': {
            color: theme.colors.grey50,
        },
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            flexDirection: 'column',
            gap: '8px',
            alignItems: 'flex-start',
            flexGrow: 1,
        },
    },
    firstMobileCell: {
        flex: '35% 0 0',
        alignItems: 'stretch',
        overflow: 'hidden',
    },
    lastMobileCell: {
        flex: '25% 0 0',
        overflow: 'hidden',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        textAlign: 'end',
        '& .MuiTextField-root': {
            maxWidth: '180px',
            alignSelf: 'end',
        },
    },
    statusDraft: {
        '& .MuiSelect-select': {
            backgroundColor: draftColor,
            color: theme.colors.white,
        },
    },
    statusInProgress: {
        '& .MuiSelect-select': {
            backgroundColor: inProgressColor,
            color: theme.colors.white,
        },
    },
    statusFinished: {
        '& .MuiSelect-select': {
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
        },
    },
    statusCancelled: {
        '& .MuiSelect-select': {
            backgroundColor: theme.colors.grey20,
        },
    },

    delimiter: {
        ...theme.typography.trajanRegular16,
        marginTop: '12px',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '12px 14px',
        textTransform: 'uppercase',
        position: 'relative',
        minHeight: '40px',
    },
    rowWrapper: {
        display: 'contents',
        '&:hover > div': {
            backgroundColor: alpha(theme.colors.grey20 || '#fff', 0.4),
            cursor: 'pointer',
        },
    },
    rowWrapperMobile: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        '&:hover > div': {
            backgroundColor: alpha(theme.colors.grey20 || '#fff', 0.4),
            cursor: 'pointer',
        },
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '8px',
            '&:hover > div': {
                backgroundColor: 'inherit',
                cursor: 'pointer',
            },
        },
    },
    orderNumberMobile: {
        ...theme.typography.medium20,
    },
}))

export default useStyles

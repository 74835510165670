import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        backgroundColor: 'transparent',
        gap: '20px',
        overflow: 'hidden',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerText: {},
    headerAction: {},
    headerTitle: {
        ...theme.typography.trajanBold28,
    },
    headerDescription: {
        ...theme.typography.medium16,
    },
    content: {
        gap: '20px',
        flexDirection: 'row',
        overflow: 'hidden',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: '28px',
        flex: '50% 1 1',
        padding: '28px',
        height: '100%',
        overflowY: 'auto',
    },
    rightSection: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        gap: '12px',
        flex: '50% 1 1',
        padding: '28px',
        height: '100%',
        overflowY: 'auto',
        '& button': {
            alignSelf: 'flex-start',
            marginTop: '8px',
        },
    },
    title: {
        ...theme.typography.medium20,
        marginBottom: '12px',
    },
    group: {
        flexDirection: 'row',
        gap: '16px',
    },
    icon: {
        flex: '130px 0 0',
        '& svg': {
            width: '130px',
            height: '105px',
        },
    },
    sectionContent: {
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        gap: '4px',
    },
    sectionText: {
        '&>ol, &>ul': {
            listStyleType: 'decimal',
            listStylePosition: 'outside',
            marginLeft: '16px',
            '&>li': {
                paddingLeft: '4px',
                '&>ol, &>ul': {
                    listStyleType: 'lower-latin',
                    listStylePosition: 'outside',
                    marginLeft: '16px',
                    '&>li': {
                        paddingLeft: '4px',
                        '&>ol, &>ul': {
                            listStyleType: 'lower-roman',
                            listStylePosition: 'outside',
                            marginLeft: '16px',
                            '&>li': {
                                paddingLeft: '4px',
                            },
                        },
                    },
                },
            },
            '& li': {
                ...theme.typography.regular16,
            },
        },
    },
    sectionGroup: {
        gap: '8px',
    },
    groupTitle: {
        color: theme.colors.secondary,
        ...theme.typography.trajanBold22,
        marginBottom: '4px',
    },
    sectionTitle: {
        cursor: 'pointer',
    },
    isActive: {
        cursor: 'default',
        color: theme.colors.secondary,
    },
    topList: {
        listStyleType: 'decimal',
        listStylePosition: 'outside',
        marginLeft: '16px',
        '&>li': {
            paddingLeft: '4px',
        },
        '& li': {
            ...theme.typography.regular16,
        },
        '& li::marker': {
            width: '12px',
        },
    },
    secondList: {
        listStyleType: 'lower-latin',
        listStylePosition: 'outside',
        marginLeft: '16px',
        '&>li': {
            paddingLeft: '4px',
        },
    },
    thirdList: {
        listStyleType: 'lower-roman',
        listStylePosition: 'outside',
        marginLeft: '16px',
        '&>li': {
            paddingLeft: '4px',
        },
    },
}))

export default useStyles

import { useCallback, useEffect, useMemo } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'

import { IQueryParams, useExtQuery } from 'hooks/useExtQuery'
import { ITableQueryInfo } from 'types/table.types'
import { IBooleanResponse, IEdgeNode } from 'models/api.model'
import { ICompany } from 'models/company.model'
import { ICompanyFormValues } from 'components/form/CompanyForm/index.schema'
import CompanyAPI from 'api/company.api'
import { IVessel } from 'models/vessel.model'
import { IVesselFormValues } from 'components/form/VesselForm/index.schema'
import VesselAPI from 'api/vessel.api'

interface IVesselMutationResponse extends IBooleanResponse {
    vessel?: IVessel
}
interface IUseVesselApi {
    vessel?: IVessel
    getVessel: (id: string) => void
    addVessel: (values: IVesselFormValues) => Promise<IVesselMutationResponse>
    deleteVessel: (id: string) => Promise<IBooleanResponse>
    updateVessel: (
        values: IVesselFormValues
    ) => Promise<IVesselMutationResponse>
    isLoading: boolean
}

export const useVesselApi = (): IUseVesselApi => {
    const [getData, { data, loading }] = useLazyQuery(
        VesselAPI.vesselDetails(),
        {
            notifyOnNetworkStatusChange: true,
        }
    )

    const [create, { loading: addVesselLoading }] = useMutation(
        VesselAPI.addVessel(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'vesselList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    const [update, { loading: updateVesselLoading }] = useMutation(
        VesselAPI.updateVessel(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'vesselList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    const [remove, { loading: deleteVesselLoading }] = useMutation(
        VesselAPI.deleteVessel(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'vesselList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    let vessel: IVessel | undefined

    if (data?.vesselDetails) {
        vessel = data.vesselDetails
    }
    const getVessel = (id: string): void => {
        getData({ variables: { id } })
    }

    const addVessel = (
        values: IVesselFormValues
    ): Promise<IVesselMutationResponse> => {
        return create({
            variables: { input: values },
        }).then(response => {
            return response.data.addVessel
        })
    }

    const updateVessel = (
        values: IVesselFormValues
    ): Promise<IVesselMutationResponse> => {
        return update({
            variables: { input: values },
        }).then(response => {
            return response.data.updateVessel
        })
    }

    const deleteVessel = (id: string): Promise<IBooleanResponse> => {
        return remove({
            variables: { input: { vesselId: id } },
        }).then(response => {
            return response.data.deleteVessel
        })
    }

    return {
        addVessel,
        updateVessel,
        deleteVessel,
        getVessel,
        vessel,
        isLoading:
            loading ||
            addVesselLoading ||
            updateVesselLoading ||
            deleteVesselLoading,
    }
}

interface IUseVesselList {
    vessels: Array<IVessel>
    isLoading: boolean
    loadMore: () => void
    hasNextPage: boolean
    setSearch: (search: string) => void
}

export const useVesselList = (params?: IQueryParams): IUseVesselList => {
    const { rows, setParam, dataIsLoading, loadMore, hasNextPage } =
        useExtQuery(
            {
                dataApi: VesselAPI.vesselList,
                dataPath: 'vesselList',
                itemsPerPage: 20,
            } as ITableQueryInfo,
            params
        )
    useEffect(() => {})

    const setSearch = useCallback(
        (str: string): void => {
            setParam('search', str)
        },
        [setParam]
    )

    const vessels: Array<IVessel> = useMemo(() => {
        return rows as Array<IVessel>
    }, [rows])

    return {
        vessels,
        setSearch,
        loadMore,
        hasNextPage,
        isLoading: dataIsLoading,
    }
}

interface IUseLazyVesselList {
    getVessels: (params?: IQueryParams) => void
    fetchMoreVessels: () => void
    vessels: Array<IVessel>
    hasNextPage: boolean
    isLoading: boolean
}

export const useLazyVesselList = (): IUseLazyVesselList => {
    const [getData, { data, loading, fetchMore }] = useLazyQuery(
        VesselAPI.vesselList()
    )

    let vessels: Array<IVessel> = []

    const pageInfo = data?.vesselList.pageInfo

    if (data?.vesselList) {
        vessels = data.vesselList.edges.map(
            (edge: IEdgeNode<IVessel>) => edge.node
        )
    }
    const getVessels = (params?: IQueryParams): void => {
        getData({ variables: params })
    }

    const fetchMoreVessels = (): void => {
        if (pageInfo.hasNextPage) {
            fetchMore({ variables: { offset: vessels.length } })
        }
    }

    return {
        getVessels,
        fetchMoreVessels,
        vessels,
        isLoading: loading,
        hasNextPage: pageInfo?.hasNextPage,
    }
}

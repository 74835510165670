import useStyles from './index.styles'

import React, { useMemo, useState } from 'react'

import Stack from '@mui/material/Stack'
import { Button } from '@mui/material'

import { IOrderConfig } from 'models/order.model'
import { CheckboxField } from 'components/inputs'
import ShipsAgencyIcon from 'assets/images/icons/ShipsAgencyIcon'
import CrewManagementIcon from 'assets/images/icons/CrewManagementIcon'
import MaterialManagementIcon from 'assets/images/icons/MaterialManagementIcon'

export interface IOrderConfigDialog {
    orderConfig?: IOrderConfig
    onConfirm: (config: IOrderConfig) => void
    onCancel: () => void
}

const OrderConfigDialog = ({
    orderConfig,
    onConfirm,
    onCancel,
}: IOrderConfigDialog): JSX.Element => {
    const classes = useStyles()
    const [config, setConfig] = useState<IOrderConfig>(orderConfig || {})
    const onOrderConfirm = (): void => {
        onConfirm(config)
    }
    const isConfirmationDisabled = useMemo(() => {
        return !(
            config.CREW_SERVICES ||
            config.SHIP_SERVICES ||
            config.MATERIAL_SERVICES
        )
    }, [config])
    return (
        <Stack className={classes.container} gap={3}>
            <Stack className={classes.title}>Service</Stack>
            <Stack className={classes.content}>
                <Stack className={classes.checkboxItem}>
                    <CheckboxField
                        label={
                            <>
                                <ShipsAgencyIcon />
                                Ships agency
                            </>
                        }
                        checked={!!config.SHIP_SERVICES}
                        onChange={(): void =>
                            setConfig(previous => ({
                                ...previous,
                                SHIP_SERVICES: !previous.SHIP_SERVICES,
                            }))
                        }
                    />
                </Stack>
                <Stack className={classes.checkboxItem}>
                    <CheckboxField
                        label={
                            <>
                                <CrewManagementIcon />
                                Crew management
                            </>
                        }
                        checked={!!config.CREW_SERVICES}
                        onChange={(): void =>
                            setConfig(previous => ({
                                ...previous,
                                CREW_SERVICES: !previous.CREW_SERVICES,
                            }))
                        }
                    />
                </Stack>
                <Stack className={classes.checkboxItem}>
                    <CheckboxField
                        label={
                            <>
                                <MaterialManagementIcon />
                                Materials management
                            </>
                        }
                        checked={!!config.MATERIAL_SERVICES}
                        onChange={(): void =>
                            setConfig(previous => ({
                                ...previous,
                                MATERIAL_SERVICES: !previous.MATERIAL_SERVICES,
                            }))
                        }
                    />
                </Stack>
            </Stack>
            <Stack className={classes.buttons}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                    disabled={isConfirmationDisabled}
                    color="primary"
                    onClick={onOrderConfirm}
                >
                    Confirm
                </Button>
            </Stack>
        </Stack>
    )
}

export default OrderConfigDialog

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

import { adminNavbarWidth, topBarHeight } from 'theme'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.colors.themeBackground,
        justifyContent: 'flex-start',
    },
    wrapper: {
        flexDirection: 'row',
        width: `min(${theme.breakpoints.values.xl}px, 100%)`,
        flexGrow: 0,
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            flexDirection: 'column',
        },
    },
    mainMenuWrapper: {
        flex: `0 0 ${adminNavbarWidth}`,
        flexDirection: 'column',
        backgroundColor: theme.colors.white,
        padding: 0,
        overflow: 'hidden',
        justifyContent: 'self-start',
        alignItems: 'stretch',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            flex: `0 0 ${topBarHeight}`,
            flexDirection: 'row',
            padding: '0 16px',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    pageWrapper: {
        justifyContent: 'stretch',
        flexGrow: 1,
        overflow: 'hidden',
        padding: '24px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '16px',
        },
    },
}))

export default useStyles

import useStyles from './index.styles'

import React, { Fragment, useCallback, useMemo, useRef } from 'react'

import { Paper, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'
import moment from 'moment'
import { useSnackbar } from 'notistack'

import { useOrderApi, useOrderList } from 'hooks/useOrder'
import { IQueryParams } from 'hooks/useExtQuery'
import {
    formatDate,
    formatDateMonth,
    isOrdersDifferInDates,
    QUERY_DATE_FORMAT,
} from 'utils/date.utils'
import { IOrder } from 'models/order.model'
import { formatUserName } from 'utils/user.utils'
import { prepareUrl } from 'utils/route.utils'
import { paths } from 'config/routes'
import Loader from 'components/common/Loader'
import { useUser, useUserList } from 'hooks/useUser'
import { IOrderFilterValues } from '../OrderFilters'
import { IKeyVal, TRender } from 'types/common.types'
import { UserRole } from 'config/user'
import { SelectShrinkField } from 'components/inputs'
import { useConfig } from 'hooks/useConfig'
import OrderStatus from 'components/common/OrderStatus'
import useScreenSize from 'hooks/useScreenSize'

interface IOrderList {
    filters: IOrderFilterValues
    onOrderClick?: (order: IOrder) => void
}

const OrderList = ({ filters, onOrderClick }: IOrderList): JSX.Element => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { isTablet } = useScreenSize()
    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const { user } = useUser()
    const { statuses } = useConfig()
    const { users: administrators } = useUserList({ role: UserRole.ADMIN })
    const {
        changeAdministrator,
        changeStatus,
        isLoading: orderApiLoading,
    } = useOrderApi()
    const administratorsKeyVal: Array<IKeyVal<string>> = useMemo(() => {
        if (!administrators) return []
        return administrators.map(administrator => {
            return {
                key: administrator.id,
                val: `${formatUserName(administrator)} ${
                    administrator.id === user?.id ? '(You)' : ''
                }`,
            }
        })
    }, [administrators, user])

    const queryParams: IQueryParams = useMemo(() => {
        const { days, sortBy, sortReverse, ...rest } = filters
        return {
            ...rest,
            dateFrom: days
                ? moment().subtract(days, 'days').format(QUERY_DATE_FORMAT)
                : null,
            ordering: `${sortReverse ? '-' : ''}${sortBy || 'created_at'},id`,
        }
    }, [filters])

    const {
        orders,
        loadMore,
        isLoading: ordersLoading,
        hasNextPage,
    } = useOrderList(queryParams)

    const isLoading: boolean = useMemo(() => {
        return ordersLoading || orderApiLoading
    }, [ordersLoading, orderApiLoading])
    const scrollHandler = useCallback((): void => {
        if (wrapperRef?.current) {
            const isBottom = (): boolean => {
                if (wrapperRef.current) {
                    const scrollHeight = wrapperRef.current?.scrollHeight || 0
                    const scrollTop = wrapperRef.current?.scrollTop || 0
                    const clientHeight = wrapperRef.current?.clientHeight || 0

                    return scrollHeight - (scrollTop + clientHeight) <= 50
                }
                return false
            }
            if (isBottom() && !isLoading && loadMore && hasNextPage) {
                loadMore()
            }
        }
    }, [wrapperRef, hasNextPage, loadMore, isLoading])

    const onRowClick = (order: IOrder): void => {
        if (onOrderClick) {
            onOrderClick(order)
        } else {
            navigate(prepareUrl(paths.admin.editOrder, { orderId: order.id }))
        }
    }
    const preventClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        event.preventDefault()
        event.stopPropagation()
    }
    const onAdministratorChange = (
        orderId: string,
        administratorId: string
    ): void => {
        changeAdministrator(orderId, administratorId).then(() => {
            enqueueSnackbar('Administrator changed', { variant: 'success' })
        })
    }
    const onStatusChange = (orderId: string, status: string): void => {
        changeStatus(orderId, status).then(() => {
            enqueueSnackbar('Status changed', { variant: 'success' })
        })
    }

    const orderStatusClasses = useCallback(
        (status: number) => {
            return cx({
                [classes.statusDraft]: status === 1,
                [classes.statusInProgress]: status === 2,
                [classes.statusFinished]: status === 3,
                [classes.statusCancelled]: status === 4,
            })
        },
        [classes]
    )

    const gridStyle = useMemo(() => {
        if (isTablet) {
            return {}
        }
        return {
            gridTemplateColumns: `repeat(${
                user?.role.key === UserRole.ADMIN ? 7 : 6
            }, 1fr)`,
        }
    }, [user, isTablet])

    const delimiterStyle = useMemo(() => {
        return {
            gridColumn: `span ${user?.role.key === UserRole.ADMIN ? 7 : 6}`,
        }
    }, [user])

    const getDelimiter = (key: number): TRender => {
        if (!orders) return null
        if (filters.sortBy !== 'eta') return null
        if (key === 0 && orders.length > 0) {
            return (
                <Stack className={classes.delimiter} style={delimiterStyle}>
                    {formatDateMonth(orders[key].eta)}
                </Stack>
            )
        }
        if (orders[key] && orders[key - 1]) {
            if (isOrdersDifferInDates(orders[key], orders[key - 1])) {
                return (
                    <Stack className={classes.delimiter} style={delimiterStyle}>
                        {formatDateMonth(orders[key].eta)}
                    </Stack>
                )
            }
        }
        return null
    }
    const getOrderAdministrator = (id?: string): string => {
        if (administratorsKeyVal) {
            const admin = administratorsKeyVal.find(
                adminKeyVal => adminKeyVal.key === id
            )
            if (admin) return admin.key
        }
        return ''
    }
    const orderHeader: TRender = useMemo(() => {
        if (isTablet) return null
        return (
            <>
                {user?.role.key === UserRole.ADMIN ? (
                    <div className={classes.header}>Administrator</div>
                ) : (
                    <div className={classes.header}>Order number</div>
                )}
                {user?.role.key === UserRole.ADMIN ? (
                    <div className={classes.header}>Order number</div>
                ) : (
                    <div className={classes.header}>Administrator</div>
                )}
                {user?.role.key === UserRole.ADMIN && (
                    <div className={classes.header}>Company</div>
                )}
                <div className={classes.header}>ETA</div>
                <div className={classes.header}>Status</div>
                <div className={classes.header}>Vessel name</div>
                <div className={classes.header}>Destination port</div>
            </>
        )
    }, [user, classes, isTablet])

    const orderRow = (order: IOrder): TRender => {
        return (
            <Stack
                className={classes.rowWrapper}
                onClick={(): void => onRowClick(order)}
            >
                {user?.role.key === UserRole.ADMIN ? (
                    <Stack className={classes.cell} onClick={preventClick}>
                        <SelectShrinkField
                            label="Administrator"
                            value={getOrderAdministrator(
                                order?.administrator?.id
                            )}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ): void =>
                                onAdministratorChange(order.id, e.target.value)
                            }
                            options={administratorsKeyVal}
                        />
                    </Stack>
                ) : (
                    <Stack className={classes.cell}>{order.number}</Stack>
                )}
                {user?.role.key === UserRole.ADMIN ? (
                    <Stack className={classes.cell}>{order.number}</Stack>
                ) : (
                    <Stack className={classes.cell}>
                        {formatUserName(order.administrator, 'Not set')}
                    </Stack>
                )}
                {user?.role.key === UserRole.ADMIN && (
                    <Stack className={classes.cell}>
                        {order.client.company?.title || ''}
                    </Stack>
                )}
                <Stack className={classes.cell}>{formatDate(order.eta)}</Stack>
                {user?.role.key === UserRole.ADMIN ? (
                    <Stack className={classes.cell} onClick={preventClick}>
                        <SelectShrinkField
                            className={orderStatusClasses(order.status.key)}
                            label="Status"
                            value={order?.status?.key || ''}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ): void => onStatusChange(order.id, e.target.value)}
                            options={statuses}
                        />
                    </Stack>
                ) : (
                    <Stack className={classes.cell}>
                        <OrderStatus order={order} />
                    </Stack>
                )}
                <Stack className={classes.cell}>{order.vesselName}</Stack>
                <Stack className={classes.cell}>
                    {order.destination?.val || ''}
                </Stack>
            </Stack>
        )
    }
    const firstMobileCell = cx(classes.cell, classes.firstMobileCell)
    const lastMobileCell = cx(classes.cell, classes.lastMobileCell)

    const orderRowMobile = (order: IOrder): TRender => {
        return (
            <Paper
                className={classes.rowWrapperMobile}
                elevation={2}
                onClick={(): void => onRowClick(order)}
            >
                <Stack className={firstMobileCell}>
                    <Stack className={classes.orderNumberMobile}>
                        {order.number}
                    </Stack>
                    {user?.role.key === UserRole.ADMIN ? (
                        <Stack onClick={preventClick}>
                            <SelectShrinkField
                                label="Administrator"
                                size="small"
                                value={getOrderAdministrator(
                                    order?.administrator?.id
                                )}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ): void =>
                                    onAdministratorChange(
                                        order.id,
                                        e.target.value
                                    )
                                }
                                options={administratorsKeyVal}
                            />
                        </Stack>
                    ) : (
                        formatUserName(order.administrator, 'Not set')
                    )}
                </Stack>
                <Stack className={classes.cell}>
                    {formatDate(order.eta)}
                    {user?.role.key === UserRole.ADMIN && (
                        <Stack flexDirection="column" gap="8px">
                            <div>{order.client.company?.title || ''}</div>
                            <div>{order.vesselName}</div>
                        </Stack>
                    )}
                </Stack>
                <Stack className={lastMobileCell}>
                    {user?.role.key === UserRole.ADMIN ? (
                        <Stack
                            className={orderStatusClasses(order.status.key)}
                            onClick={preventClick}
                        >
                            <SelectShrinkField
                                label="Status"
                                size="small"
                                value={order?.status?.key || ''}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ): void =>
                                    onStatusChange(order.id, e.target.value)
                                }
                                options={statuses}
                            />
                        </Stack>
                    ) : (
                        <OrderStatus order={order} />
                    )}
                    {order.destination?.val || ''}
                </Stack>
            </Paper>
        )
    }

    return (
        <div
            ref={wrapperRef}
            className={classes.wrapper}
            onScroll={scrollHandler}
        >
            <div className={classes.container} style={gridStyle}>
                {orderHeader}
                {orders.map((order: IOrder, key: number) => (
                    <Fragment key={order.id}>
                        {getDelimiter(key)}
                        {isTablet ? orderRowMobile(order) : orderRow(order)}
                    </Fragment>
                ))}
                {isLoading && (
                    <Stack className={classes.delimiter} style={delimiterStyle}>
                        <Loader />
                    </Stack>
                )}
            </div>
        </div>
    )
}
export default OrderList

import { useCallback, useEffect, useMemo } from 'react'

import {
    ApolloError,
    useLazyQuery,
    useMutation,
    useQuery,
} from '@apollo/client'

import { IQueryParams, useExtQuery } from 'hooks/useExtQuery'
import { ITableQueryInfo } from 'types/table.types'
import { UserAPI } from 'api'
import { IBooleanResponse, IEdgeNode } from 'models/api.model'
import { IUser } from 'models/user.model'
import { IUserFormValues } from 'components/form/UserForm/index.schema'

interface UseUserResponse {
    user?: IUser
    userIsLoading: boolean
    userError: ApolloError | undefined
}

export const useUser = (): UseUserResponse => {
    const { data, loading, error } = useQuery(UserAPI.me(), {
        errorPolicy: 'all',
    })
    let user

    if (data?.me) {
        user = data.me
    }

    return {
        user,
        userIsLoading: loading,
        userError: error,
    }
}

interface IUserMutationResponse extends IBooleanResponse {
    user?: IUser
}
interface IUserInvitationLinkResponse extends IBooleanResponse {
    link?: string
}
interface IInviteFormValues {
    email: string
    companyId: string
}

interface IUseUserApi {
    user?: IUser
    getUser: (id: string) => Promise<IUser>
    inviteUser: (values: IInviteFormValues) => Promise<IBooleanResponse>
    deactivateUser: (id: string) => Promise<IBooleanResponse>
    getInvitationLink: (email: string) => Promise<IUserInvitationLinkResponse>
    addUser: (values: IUserFormValues) => Promise<IUserMutationResponse>
    changePassword: (
        oldPassword: string,
        newPassword: string,
        repeatPassword: string
    ) => Promise<IBooleanResponse>
    updateUser: (
        values: Record<string, string | number | null | boolean>
    ) => Promise<IUserMutationResponse>
    isLoading: boolean
}

export const useUserApi = (): IUseUserApi => {
    const [getData, { data, loading }] = useLazyQuery(UserAPI.userDetails(), {
        notifyOnNetworkStatusChange: true,
    })

    const [create, { loading: addUserLoading }] = useMutation(
        UserAPI.addUser(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'companyList' })
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'userList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    const [changePasswordApi, { loading: changePasswordLoading }] = useMutation(
        UserAPI.changePassword()
    )

    const [invite, { loading: inviteUserLoading }] = useMutation(
        UserAPI.inviteUser(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'userList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )
    const [deactivate, { loading: deactivateUserLoading }] = useMutation(
        UserAPI.deleteUser(),
        {
            errorPolicy: 'all',
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'userList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    const [getLink, { loading: getLinkLoading }] = useMutation(
        UserAPI.getInvitationLink(),
        {
            notifyOnNetworkStatusChange: true,
        }
    )

    const [update, { loading: updateUserLoading }] = useMutation(
        UserAPI.updateUser(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'userList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    let user: IUser | undefined

    if (data?.userDetails) {
        user = data.userDetails
    }
    const getUser = (id: string): Promise<IUser> => {
        return getData({ variables: { id } }).then(
            response => response.data.userDetails
        )
    }

    const addUser = (
        values: IUserFormValues
    ): Promise<IUserMutationResponse> => {
        return create({
            variables: { input: values },
        }).then(response => {
            return response.data.addUser
        })
    }

    const inviteUser = (
        values: IInviteFormValues
    ): Promise<IBooleanResponse> => {
        return invite({
            variables: { input: values },
        }).then(response => {
            return response.data.inviteUser
        })
    }

    const updateUser = (
        values: IUserFormValues
    ): Promise<IUserMutationResponse> => {
        return update({
            variables: { input: values },
        }).then(response => {
            return response.data.updateUser
        })
    }

    const deactivateUser = (userId: string): Promise<IBooleanResponse> => {
        return deactivate({
            variables: { input: { userId } },
        }).then(response => {
            if (response.errors) {
                return { error: response.errors[0].message || '' }
            }
            return response.data.deleteUser
        })
    }
    const getInvitationLink = (
        email: string
    ): Promise<IUserInvitationLinkResponse> => {
        return getLink({
            variables: { input: { email } },
        }).then(response => {
            return response.data.getInvitationLink
        })
    }

    const changePassword = (
        oldPassword: string,
        newPassword1: string,
        newPassword2: string
    ): Promise<IBooleanResponse> => {
        return changePasswordApi({
            variables: { input: { oldPassword, newPassword1, newPassword2 } },
        }).then(response => {
            return response.data.changePassword
        })
    }

    return {
        addUser,
        inviteUser,
        updateUser,
        deactivateUser,
        getInvitationLink,
        changePassword,
        getUser,
        user,
        isLoading:
            loading ||
            addUserLoading ||
            updateUserLoading ||
            inviteUserLoading ||
            changePasswordLoading ||
            getLinkLoading ||
            deactivateUserLoading,
    }
}

interface IUseUserList {
    users: Array<IUser>
    isLoading: boolean
    loadMore: () => void
    hasNextPage: boolean
    setSearch: (search: string) => void
}

export const useUserList = (params?: IQueryParams): IUseUserList => {
    const { rows, setParam, dataIsLoading, loadMore, hasNextPage } =
        useExtQuery(
            {
                dataApi: UserAPI.userList,
                dataPath: 'userList',
                itemsPerPage: 20,
            } as ITableQueryInfo,
            params
        )
    useEffect(() => {})

    const setSearch = useCallback(
        (str: string): void => {
            setParam('search', str)
        },
        [setParam]
    )

    const users: Array<IUser> = useMemo(() => {
        return rows as Array<IUser>
    }, [rows])

    return {
        users,
        setSearch,
        loadMore,
        hasNextPage,
        isLoading: dataIsLoading,
    }
}

interface IUseLazyUserList {
    getUsers: (params?: IQueryParams) => void
    fetchMoreUsers: () => void
    users: Array<IUser>
    hasNextPage: boolean
    isLoading: boolean
}

export const useLazyUserList = (): IUseLazyUserList => {
    const [getData, { data, loading, fetchMore }] = useLazyQuery(
        UserAPI.userList()
    )

    let users: Array<IUser> = []

    const pageInfo = data?.userList.pageInfo

    if (data?.userList) {
        users = data.userList.edges.map((edge: IEdgeNode<IUser>) => edge.node)
    }
    const getUsers = (params?: IQueryParams): void => {
        getData({ variables: params })
    }

    const fetchMoreUsers = (): void => {
        if (pageInfo.hasNextPage) {
            fetchMore({ variables: { offset: users.length } })
        }
    }

    return {
        getUsers,
        fetchMoreUsers,
        users,
        isLoading: loading,
        hasNextPage: pageInfo?.hasNextPage,
    }
}

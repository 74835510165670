import useStyles from '../index.styles'

import React from 'react'

import { Paper, Stack } from '@mui/material'
import { useFormikContext } from 'formik'

import { daysDiff } from 'utils/date.utils'
import OrderServices from 'components/form/OrderForm/OrderServices'
import { DatePicker, LabeledTextField, TextareaField } from 'components/inputs'
import { IOrderFormValues } from 'components/form/OrderForm/index.schema'
import { ICategory } from 'models/category.model'

interface ICrewServices {
    isLoading: boolean
    categories?: Array<ICategory>
}

const CrewServices = ({
    isLoading,
    categories,
}: ICrewServices): JSX.Element => {
    const classes = useStyles()
    const formik = useFormikContext<IOrderFormValues>()
    return (
        <Stack className={classes.container}>
            <Paper className={classes.formPaper}>
                <Stack className={classes.group3}>
                    <DatePicker
                        id="ETA-input"
                        name="crewEta"
                        label="ETA"
                        placeholder="ETA"
                        value={formik.values.crewEta || ''}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.crewEta &&
                            Boolean(formik.errors.crewEta)
                        }
                        helperText={
                            formik.touched.crewEta && formik.errors.crewEta
                        }
                    />
                    <DatePicker
                        id="EDT-input"
                        name="crewEtd"
                        label="ETD"
                        placeholder="ETD"
                        value={formik.values.crewEtd || ''}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.crewEtd &&
                            Boolean(formik.errors.crewEtd)
                        }
                        helperText={
                            formik.touched.crewEtd && formik.errors.crewEtd
                        }
                    />
                    <LabeledTextField
                        id="days-input"
                        className={classes.totalDays}
                        name="days-towage"
                        label="Total Days"
                        placeholder="Total Days"
                        value={daysDiff(
                            formik.values.crewEta,
                            formik.values.crewEtd
                        )}
                    />
                </Stack>
                <Stack className={classes.group}>
                    <LabeledTextField
                        id="hs-code-input"
                        name="hsCode"
                        label="HS Code"
                        placeholder="HS Code"
                        value={formik.values.hsCode || ''}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.hsCode &&
                            Boolean(formik.errors.hsCode)
                        }
                        helperText={
                            formik.touched.hsCode && formik.errors.hsCode
                        }
                    />
                </Stack>
                <OrderServices
                    key="group-1"
                    isLoading={isLoading}
                    categories={categories}
                />
                <Stack className={classes.divider} />
                <TextareaField
                    id="comments-input"
                    name="crewComments"
                    label="Comments (optional)"
                    placeholder="Enter your suggestions here"
                    rows={1}
                    value={formik.values.crewComments || ''}
                    onChange={formik.handleChange}
                />
            </Paper>
        </Stack>
    )
}
export default CrewServices

import useStyles from './index.styles'

import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'

import { Form, Formik, FormikHelpers } from 'formik'
import Stack from '@mui/material/Stack'
import { Button, Paper } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate } from 'react-router-dom'

import {
    crewValidationSchema,
    customerInitialValues,
    infoValidationSchema,
    IOrderFormValues,
    IOrderServiceFormValues,
    materialsValidationSchema,
    portValidationSchema,
    shipInitialValues,
} from './index.schema'
import { useOrderApi } from 'hooks/useOrder'
import OrderHeader from 'components/form/OrderForm/OrderHeader'
import OrderCustomer from 'components/form/OrderForm/OrderCustomer'
import {
    defaultOrderSteps,
    ECategoryGroup,
    EOrderStep,
    IOrderConfig,
    IOrderService,
} from 'models/order.model'
import OrderSummary from './OrderSummary'
import OrderCalculation from './OrderCalculation'
import { prepareUrl } from 'utils/route.utils'
import { paths } from 'config/routes'
import OrderDocument from 'assets/images/icons/OrderDocument'
import OrderDocuments from 'components/form/OrderForm/OrderDocuments'
import { useLazyServiceList } from 'hooks/useService'
import { IService } from 'models/service.model'
import { useUser } from 'hooks/useUser'
import { UserRole } from 'config/user'
import {
    calculateServiceRate,
    getChangedValues,
    getValuesFromOrder,
    setServicesSkipped,
} from 'components/form/OrderForm/service.utils'
import ShipServices from 'components/form/OrderForm/ShipServices'
import FormStepper from 'components/form/FormStepper'
import CrewServices from 'components/form/OrderForm/CrewServices'
import MaterialsServices from 'components/form/OrderForm/MaterialsServices'

interface IOrderLocation {
    state: {
        orderConfig?: IOrderConfig
    }
}
export interface IOrderForm {
    id?: string
    onFinish: () => void
}
const defaultOrderConfig: IOrderConfig = {
    SHIP_SERVICES: false,
    CREW_SERVICES: false,
    MATERIAL_SERVICES: false,
}
const OrderForm = ({ id, onFinish }: IOrderForm): React.ReactElement => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { user } = useUser()
    const { enqueueSnackbar } = useSnackbar()
    const location = useLocation() as IOrderLocation
    const [config, setConfig] = useState<IOrderConfig>({
        ...defaultOrderConfig,
        ...(location.state?.orderConfig || {}),
    })

    const { search } = useLocation()
    const getInitialStep = (): EOrderStep => {
        const params = new URLSearchParams(search)
        const step = params.get('step')
        if (step) {
            return parseFloat(step) as unknown as EOrderStep
        }
        return EOrderStep.INFO
    }

    const [currentStep, setCurrentStep] = useState<EOrderStep>(getInitialStep())
    const [orderSteps, setOrderSteps] = useState<Record<number, string>>({})
    const [orderId, setOrderId] = useState<string | undefined>(id)
    const {
        categories: loadedCategories,
        getCategories,
        isLoading: categoriesLoading,
    } = useLazyServiceList()

    useEffect(() => {
        if (id) {
            setOrderId(id)
        }
    }, [id])

    useEffect(() => {
        if (currentStep === EOrderStep.SHIP_SERVICES)
            getCategories({ group: '2' })
        else if (currentStep === EOrderStep.CREW_SERVICES)
            getCategories({ group: '1' })
        else if (currentStep === EOrderStep.MATERIALS_SERVICES)
            getCategories({ group: '3' })
    }, [currentStep])
    const {
        order,
        getOrder,
        addOrder,
        updateOrder,
        getOrderPdf,
        isLoading: orderIsLoading,
    } = useOrderApi()
    useEffect(() => {
        if (orderId) {
            getOrder(orderId)
        }
    }, [orderId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!order || !user) return
        const orderConfig: IOrderConfig = {
            SHIP_SERVICES: order.portServicesPresent,
            CREW_SERVICES: order.crewServicesPresent,
            MATERIAL_SERVICES: order.materialsServicesPresent,
        }
        const currentOrderSteps = { ...defaultOrderSteps }
        if (user?.role.key !== UserRole.ADMIN) {
            delete currentOrderSteps[EOrderStep.INFO]
        }
        if (orderConfig.SHIP_SERVICES === false) {
            delete currentOrderSteps[EOrderStep.SHIP_SERVICES]
        }
        if (orderConfig.CREW_SERVICES === false) {
            delete currentOrderSteps[EOrderStep.CREW_SERVICES]
        }
        if (orderConfig.MATERIAL_SERVICES === false)
            delete currentOrderSteps[EOrderStep.MATERIALS_SERVICES]

        if (!Object.keys(currentOrderSteps).includes(currentStep.toString())) {
            setCurrentStep(
                parseFloat(
                    Object.keys(currentOrderSteps)[0]
                ) as unknown as EOrderStep
            )
        }
        setOrderSteps(currentOrderSteps)
        setConfig(orderConfig)
    }, [user, order, currentStep])
    const companyMargin: number = useMemo(() => {
        let margin = 1
        if (user?.role.key === UserRole.CLIENT) {
            margin = user.company?.margin ? 1 + user.company.margin / 100 : 1
        } else {
            margin = order?.client.company?.margin
                ? 1 + order.client.company.margin / 100
                : 1
        }
        return margin
    }, [order?.client.company?.margin, user])

    const initialValues = useMemo(() => {
        switch (currentStep) {
            case EOrderStep.SHIP_SERVICES:
                return shipInitialValues
            default:
                return customerInitialValues
        }
    }, [currentStep])

    const [formValues, setFormValues] = useState<IOrderFormValues>({
        ...initialValues,
    })
    const validationSchema = useMemo(() => {
        switch (currentStep) {
            case EOrderStep.SHIP_SERVICES:
                return portValidationSchema
            case EOrderStep.CREW_SERVICES:
                return crewValidationSchema
            case EOrderStep.MATERIALS_SERVICES:
                return materialsValidationSchema
            default:
                return infoValidationSchema
        }
    }, [currentStep])

    const isLoading: boolean = useMemo(() => {
        return orderIsLoading || categoriesLoading
    }, [orderIsLoading, categoriesLoading])

    const categories = useMemo(() => {
        return loadedCategories
            .filter(category => {
                switch (currentStep) {
                    case EOrderStep.SHIP_SERVICES:
                        return category.group.key === ECategoryGroup.SHIP
                    case EOrderStep.CREW_SERVICES:
                        return category.group.key === ECategoryGroup.CREW
                    case EOrderStep.MATERIALS_SERVICES:
                        return category.group.key === ECategoryGroup.MATERIAL
                    default:
                        return false
                }
            })
            .map(category => {
                return {
                    ...category,
                    services: category.services.map(service => {
                        return {
                            ...service,
                            rate:
                                Math.round(service.rate * companyMargin * 100) /
                                100,
                        }
                    }),
                }
            })
    }, [currentStep, loadedCategories, companyMargin])

    const mappedServices: Record<string, IService> = useMemo(() => {
        const response: Record<string, IService> = {}
        if (categories) {
            categories.forEach(category => {
                category.services.forEach(service => {
                    response[service.id] = service
                })
            })
        }
        return response
    }, [categories])

    const formOrderServices: Array<IOrderServiceFormValues> = useMemo(() => {
        let response: Array<IOrderServiceFormValues> = []
        const copiedServices = { ...mappedServices }
        let orderFormValues: IOrderFormValues = {}
        let newValues: Record<string, string> = {}
        if (order) {
            orderFormValues = getValuesFromOrder(order)
            newValues = getChangedValues(
                orderFormValues as unknown as Record<string, string>
            )
            const existingServices: Array<IOrderService> = [
                ...(order.services?.filter(
                    service => !!copiedServices[service.id]
                ) || []),
            ]

            response = existingServices.map(service => {
                const orderService: IOrderServiceFormValues = {
                    serviceId: service.id,
                    quantity: service.quantity,
                    rate: mappedServices[service.id].rate,
                    amount:
                        calculateServiceRate(
                            service.id,
                            orderFormValues || {},
                            mappedServices
                        ) * service.quantity,
                    required: mappedServices[service.id]?.required || false,
                    isSkipped: false,
                }
                delete copiedServices[service.id]
                return orderService
            })
        }
        Object.values(copiedServices).forEach(service => {
            if (service.required) {
                response.push({
                    serviceId: service.id,
                    quantity: 1,
                    rate: service.rate,
                    amount: calculateServiceRate(
                        service.id,
                        orderFormValues || {},
                        mappedServices
                    ),
                    required: service.required || false,
                    isSkipped: false,
                } as IOrderServiceFormValues)
            }
        })
        response = setServicesSkipped(
            orderFormValues,
            Object.keys(newValues),
            response,
            mappedServices
        )
        return response
    }, [mappedServices, order])
    useEffect(() => {
        if (order) {
            let orderValues = { ...initialValues, orderId: order.id }
            switch (currentStep) {
                case EOrderStep.SHIP_SERVICES:
                    orderValues = {
                        ...orderValues,
                        vesselBodyType: order.vesselBodyType?.key,
                        vesselName: order.vesselName,
                        vesselNetRegisterTonnage:
                            order.vesselNetRegisterTonnage,
                        vesselGrossRegisterTonnage:
                            order.vesselGrossRegisterTonnage,
                        vesselLengthOverall: order.vesselLengthOverall,
                        vesselFlag: order.vesselFlag?.key,
                        vesselDailyHirePerTc: order.vesselDailyHirePerTc,
                        vesselCifValue: order.vesselCifValue,
                        movementType: order.movementType?.key,
                        origin: order.origin?.key,
                        destination: order.destination?.key,
                        temporaryImportationNeeded:
                            order.temporaryImportationNeeded,
                        towageNeeded: order.towageNeeded,
                        etd: order.etd,
                        eta: order.eta,
                        portComments: order.portComments || '',
                    }
                    break
                case EOrderStep.CREW_SERVICES:
                    orderValues = {
                        ...orderValues,
                        crewEtd: order.crewEtd || order.etd || '',
                        crewEta: order.crewEta || order.eta || '',
                        hsCode: order.hsCode || '',
                        crewComments: order.crewComments,
                    }
                    break
                case EOrderStep.MATERIALS_SERVICES:
                    orderValues = {
                        ...orderValues,
                        materialsCifValue: order.materialsCifValue,
                        materialsComments: order.materialsComments,
                    }
                    break
                default:
                    break
            }
            setFormValues({
                ...orderValues,
                clientId: order.client.id,
                serviceList: formOrderServices,
            } as unknown as IOrderFormValues)
        } else {
            setFormValues({
                ...initialValues,
                crewServicesPresent: config.CREW_SERVICES || false,
                portServicesPresent: config.SHIP_SERVICES || false,
                materialsServicesPresent: config.MATERIAL_SERVICES || false,
                clientId: user?.role.key === UserRole.CLIENT ? user.id : '',
                serviceList: formOrderServices,
            })
        }
    }, [order, formOrderServices, user, config, currentStep])

    const getNextStep = (): string | null => {
        const steps = Object.keys(orderSteps)
        const currentStepIndex = steps.indexOf(currentStep.toString())
        const nextStep = steps[currentStepIndex + 1]
        if (nextStep) {
            return nextStep
        }
        return null
    }
    const onProceed = (): void => {
        const nextStep = getNextStep()
        if (nextStep) {
            setCurrentStep(parseFloat(nextStep))
        }
    }

    const onBack = (): void => {
        const steps = Object.keys(orderSteps)
        const currentStepIndex = steps.indexOf(currentStep.toString())
        const prevStep = steps[currentStepIndex - 1]
        if (prevStep) {
            setCurrentStep(parseFloat(prevStep))
        } else {
            const backPath =
                user?.role.key === UserRole.ADMIN
                    ? paths.admin.home
                    : paths.client.home
            navigate(backPath)
        }
    }

    const onSavePdf = (): void => {
        if (orderId) {
            getOrderPdf(orderId).then(response => {
                if (response.success) {
                    window.open(response.text, '_blank')
                }
            })
        }
    }

    const onSubmit = (
        data: IOrderFormValues,
        formik: FormikHelpers<IOrderFormValues>
    ): void => {
        const { serviceList, ...rest } = data
        const values: IOrderFormValues = {
            ...rest,
            serviceList: serviceList?.map(service => ({
                serviceId: service.serviceId,
                quantity: service.isSkipped ? 0 : service.quantity || 0,
                amount: service.isSkipped ? 0 : service.amount || 0,
                rate:
                    service.rate !== undefined
                        ? service.rate
                        : mappedServices[service.serviceId]?.rate,
            })),
        }
        if (currentStep === EOrderStep.CALCULATION) {
            formik.validateForm().then(errors => {
                if (Object.keys(errors).length === 0) {
                    onProceed()
                }
            })
        } else if (currentStep === EOrderStep.DOCUMENTS) {
            enqueueSnackbar('Order successfully updated', {
                variant: 'success',
            })
            onFinish()
        } else if (values.orderId) {
            updateOrder(values)
                .then(response => {
                    if (response.success) {
                        /*
                        enqueueSnackbar('Order saved', {
                            variant: 'success',
                        })
*/
                        onProceed()
                    } else {
                        enqueueSnackbar('Order not saved', {
                            variant: 'error',
                        })
                    }
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' })
                })
        } else {
            addOrder(values)
                .then(response => {
                    if (response.success) {
                        enqueueSnackbar('Order created')
                        const editOrderPath =
                            user?.role.key === UserRole.ADMIN
                                ? paths.admin.editOrder
                                : paths.client.editOrder
                        navigate(
                            `${prepareUrl(editOrderPath, {
                                orderId: response.order?.id,
                            })}?step=${getNextStep()}`
                        )
                        onProceed()
                    } else {
                        enqueueSnackbar('Order not saved', {
                            variant: 'error',
                        })
                    }
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' })
                })
        }
    }

    const stepButtons = useMemo(() => {
        const getSaveOrderBtnTitle = (): string => {
            if (currentStep === EOrderStep.DOCUMENTS) {
                return order?.id ? 'Save order' : 'Place order'
            }
            return 'Proceed'
        }
        return (
            <Stack className={classes.buttons}>
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={(): void => onBack()}
                >
                    {currentStep === EOrderStep.INFO ? 'Cancel' : 'Back'}
                </Button>
                {currentStep === EOrderStep.CALCULATION && (
                    <Button
                        variant="contained"
                        color="secondary"
                        type="button"
                        disabled={isLoading}
                        onClick={(): void => onSavePdf()}
                    >
                        Save pdf
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    type="submit"
                >
                    {getSaveOrderBtnTitle()}
                </Button>
            </Stack>
        )
    }, [currentStep, isLoading, classes, order])

    const getStepContent = useCallback((): JSX.Element => {
        switch (currentStep) {
            case EOrderStep.SHIP_SERVICES:
                return (
                    <Fragment key={EOrderStep.SHIP_SERVICES}>
                        <Paper className={classes.formPaper}>
                            <OrderHeader order={order} />
                            <OrderCustomer />
                        </Paper>
                        <ShipServices
                            isLoading={isLoading}
                            categories={categories}
                        />
                        <Paper className={classes.formPaper}>
                            {stepButtons}
                        </Paper>
                    </Fragment>
                )
            case EOrderStep.CREW_SERVICES:
                return (
                    <Fragment key={EOrderStep.CREW_SERVICES}>
                        <Paper className={classes.formPaper}>
                            <OrderHeader order={order} />
                            <OrderCustomer />
                        </Paper>
                        <CrewServices
                            isLoading={isLoading}
                            categories={categories}
                        />
                        <Paper className={classes.formPaper}>
                            {stepButtons}
                        </Paper>
                    </Fragment>
                )
            case EOrderStep.MATERIALS_SERVICES:
                return (
                    <Fragment key={EOrderStep.MATERIALS_SERVICES}>
                        <Paper className={classes.formPaper}>
                            <OrderHeader order={order} />
                            <OrderCustomer />
                        </Paper>
                        <MaterialsServices
                            isLoading={isLoading}
                            categories={categories}
                        />
                        <Paper className={classes.formPaper}>
                            {stepButtons}
                        </Paper>
                    </Fragment>
                )
            case EOrderStep.CALCULATION:
                return (
                    <Fragment key={EOrderStep.CALCULATION}>
                        <Paper className={classes.formPaper}>
                            <OrderHeader order={order} />
                            <OrderCustomer />
                            {/* <OrderSummary order={order} /> */}
                        </Paper>
                        <Paper className={classes.formPaper}>
                            <OrderCalculation order={order} />
                        </Paper>
                        <Paper className={classes.formPaper}>
                            {stepButtons}
                        </Paper>
                    </Fragment>
                )
            case EOrderStep.DOCUMENTS:
                return (
                    <Fragment key={EOrderStep.DOCUMENTS}>
                        <Paper className={classes.formPaper}>
                            <Stack className={classes.orderTitle}>
                                <OrderDocument />
                                {`Documents for order № ${order?.number || ''}`}
                            </Stack>
                            <OrderSummary order={order} />
                        </Paper>
                        <Paper className={classes.formPaper}>
                            <OrderDocuments orderId={order?.id || ''} />
                        </Paper>
                        <Paper className={classes.formPaper}>
                            {stepButtons}
                        </Paper>
                    </Fragment>
                )
            default:
                return (
                    <Paper className={classes.formPaper}>
                        <OrderHeader order={order} />
                        <OrderCustomer isEditable />

                        {stepButtons}
                    </Paper>
                )
        }
    }, [isLoading, currentStep, order, categories, classes, stepButtons])

    return (
        <Stack className={classes.container}>
            <Stack className={classes.stepper}>
                <FormStepper steps={orderSteps} currentStep={currentStep} />
            </Stack>

            <Formik
                enableReinitialize
                initialValues={formValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                <Form className={classes.form}>{getStepContent()}</Form>
            </Formik>
        </Stack>
    )
}
export default OrderForm

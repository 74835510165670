import { ITableColumn, ITableData, ITableRowValue } from 'types/table.types'
import TableButtons from 'components/common/Table/fields/TableButtons'
import { IUser } from 'models/user.model'
import { UserInfo } from 'components/common/Table/fields'

export const columns: Array<ITableColumn> = [
    {
        field: 'nickname',
        headerName: 'Name',
        fr: 1, // 7
        renderCell: (data: ITableData<IUser>) => <UserInfo data={data} />,
    },
    {
        field: 'company',
        headerName: 'Company',
        fr: 1, // 7
        renderCell: (data: ITableData<IUser>) => (
            <span style={{ textTransform: 'uppercase' }}>
                {data.row.company?.title || ''}
            </span>
        ),
    },

    {
        field: 'email',
        headerName: 'Email',
        fr: 0.3, // 7
    },
] as Array<ITableColumn>

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '16px',
        borderBottom: `1px solid ${theme.colors.grey30}`,
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: '8px',
        },
    },
    LeftBlock: {
        flexDirection: 'column',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
        },
    },
    titleBlock: {
        flexDirection: 'column',
    },
    orderDate: {
        ...theme.typography.regular16,
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '12px',
        },
    },
    buttons: {
        flexDirection: 'row',
        gap: '8px',
    },
    title: {
        ...theme.typography.trajanBold32,
        flexDirection: 'row',
        alignItems: 'center',
        gap: '16px',
    },
}))

export default useStyles

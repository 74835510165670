import useStyles from './index.styles'

import React from 'react'

import { Stack } from '@mui/material'

import { TRender } from 'types/common.types'

interface IVesselCell {
    title: string
    value: TRender
}
const VesselCell = ({ title, value }: IVesselCell): JSX.Element => {
    const classes = useStyles()

    return (
        <Stack className={classes.container}>
            <Stack className={classes.title}>{title}</Stack>
            <Stack className={classes.value}>{value}</Stack>
        </Stack>
    )
}

export default VesselCell

import useStyles from './index.styles'

import React from 'react'

import { IconButton, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { paths } from 'config/routes'
import ArrowLeft from 'assets/images/icons/ArrowLeft'
import OrderForm from 'components/form/OrderForm'

const CreateOrder = (): JSX.Element => {
    const classes = useStyles()
    const params = useParams()
    const orderId = params.orderId || ''
    const navigate = useNavigate()
    const onFinish = (): void => {
        navigate(paths.admin.home)
    }
    return (
        <Stack className={classes.container}>
            <Stack className={classes.crumbs}>
                <IconButton onClick={(): void => navigate(paths.admin.home)}>
                    <ArrowLeft />
                </IconButton>
                back to order list
            </Stack>
            <Stack className={classes.page}>
                <OrderForm id={orderId} onFinish={onFinish} />
            </Stack>
        </Stack>
    )
}

export default CreateOrder

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    container: {
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '450px',
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '12px',
    },
    content: {
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
    },
    text: {
        textAlign: 'justify',
        textAlignLast: 'start',
    },
    icon: {
        '& svg': {
            width: '40px',
            height: '40px',
            fill: '#25ae88',
        },
    },
})

export default useStyles

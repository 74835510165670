import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        padding: '0 32px',
    },
    box: {
        borderBottom: `1px solid #22374E14`,
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: '24px 0',
        gap: '16px',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'stretch',
        gap: '16px',
    },
    icon: {
        flexBasis: '24px',
        flexGrow: '0',
    },
    titleHead: {
        ...theme.typography.medium20,
    },
    title: {
        ...theme.typography.regular16,
        flexDirection: 'row',
        gap: '4px',
    },
    content: {
        flexGrow: 1,
    },
    button: {
        display: 'flex',
        flexGrow: 1,
    },
    columnBlock: {
        flexDirection: 'column',
    },
}))

export default useStyles

import React from 'react'

import { Radio as MuiRadio, RadioProps } from '@mui/material'

import RadioUnchecked from 'assets/images/forms/RadioUnchecked'
import RadioChecked from 'assets/images/forms/RadioChecked'

const Radio: React.FC<RadioProps> = (props: RadioProps): React.ReactElement => {
    return (
        <MuiRadio
            {...props}
            checkedIcon={<RadioChecked />}
            icon={<RadioUnchecked />}
        />
    )
}
export default Radio

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.colors.grey10,
    },
    wrapper: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'row',
        width: `min(${theme.breakpoints.values.lg}px, 100%)`,
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            flexDirection: 'column',
        },
    },
    pageWrapper: {
        display: 'flex',
        flexGrow: 1,
        overflow: 'hidden',
    },
}))

export default useStyles

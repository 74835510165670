import React from 'react'

const ContactCompany = (): React.ReactElement => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_468_1370)">
                <path
                    d="M12 7.00006V5.00006C12 3.90006 11.1 3.00006 10 3.00006H4C2.9 3.00006 2 3.90006 2 5.00006V19.0001C2 20.1001 2.9 21.0001 4 21.0001H20C21.1 21.0001 22 20.1001 22 19.0001V9.00006C22 7.90006 21.1 7.00006 20 7.00006H12ZM6 19.0001H4V17.0001H6V19.0001ZM6 15.0001H4V13.0001H6V15.0001ZM6 11.0001H4V9.00006H6V11.0001ZM6 7.00006H4V5.00006H6V7.00006ZM10 19.0001H8V17.0001H10V19.0001ZM10 15.0001H8V13.0001H10V15.0001ZM10 11.0001H8V9.00006H10V11.0001ZM10 7.00006H8V5.00006H10V7.00006ZM19 19.0001H12V17.0001H14V15.0001H12V13.0001H14V11.0001H12V9.00006H19C19.55 9.00006 20 9.45006 20 10.0001V18.0001C20 18.5501 19.55 19.0001 19 19.0001ZM18 11.0001H16V13.0001H18V11.0001ZM18 15.0001H16V17.0001H18V15.0001Z"
                    fill="#E1AB10"
                />
            </g>
            <defs>
                <clipPath id="clip0_468_1370">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
export default ContactCompany

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { alpha } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        display: 'inline-block',
        maxWidth: '100%',
        overflow: 'auto',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            width: '100%',
        },
    },
    container: {
        display: 'grid',
        padding: '0',
        gridTemplateColumns: '1fr 4fr 3fr 3fr 6fr 6fr 1fr',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            width: '100%',
        },
    },
    header: {
        ...theme.typography.medium14,
        color: theme.colors.systemBlack,
        backgroundColor: theme.colors.white,
        zIndex: '100',
        minHeight: '20px',
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '12px 14px',
        position: 'sticky',
        top: 0,
        textTransform: 'uppercase',
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
    wordWrap: {
        whiteSpace: 'break-spaces',
    },
    bodyCell: {
        ...theme.typography.regular14,
        color: theme.colors.grey100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'start',
        justifyContent: 'center',
        padding: '4px 14px',
        background: theme.colors.white,
        minHeight: '48px',
    },
    bodyCellMobile: {
        justifyContent: 'flex-start',
    },
    mobileHead: {
        color: theme.colors.grey70,
    },
    cellPaddingTop: {
        paddingTop: '12px',
    },
    cellPaddingBottom: {
        paddingBottom: '12px',
    },
    bottomBorder: {
        borderBottom: `1px solid ${theme.colors.grey20}`,
    },
    topBorder: {
        borderTop: `1px solid ${theme.colors.grey20}`,
    },
    stickyStart: {
        position: 'sticky',
        left: 0,
    },
    stickyEnd: {
        position: 'sticky',
        right: 0,
    },
    shadowStart: {
        filter: `drop-shadow(8px 0px 6px rgba(237, 238, 245, 0.6))`,
    },
    shadowEnd: {
        filter: `drop-shadow(-8px 0px 6px rgba(237, 238, 245, 0.6))`,
    },
    alignCenter: {
        alignItems: 'center',
    },
    alignEnd: {
        alignItems: 'flex-end',
        textAlign: 'end',
    },
    tableBodyCellText: {
        ...theme.typography.regular14,
        color: theme.colors.grey100,
        minHeight: '20px',
    },
    loader: {
        position: 'relative',
        minHeight: '60px',
        '& .MuiCircularProgress-root': {
            width: '30px !important',
            height: '30px !important',
        },
    },
    rowWrapper: {
        display: 'contents',
        '&:hover > div': {
            backgroundColor: alpha(theme.colors.grey20 || '#fff', 0.4),
            cursor: 'pointer',
        },
    },
}))

export default useStyles

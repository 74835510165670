import { DocumentNode, gql } from '@apollo/client'

import { USER_INFO } from 'api/fragments/user.fragment'
import { PAGE_INFO } from 'api/fragments/pagination.fragment'
import { COMPANY_INFO } from 'api/fragments/company.fragment'

class CompanyAPI {
    static companyDetails(): DocumentNode {
        return gql`
            query companyDetails($id: ID!) {
                companyDetails(id: $id) {
                    ...CompanyFields
                    totalOrders
                    activeOrders                    
                    clients {
                        ...UserFields
                    }
                }
            }
            ${COMPANY_INFO}, ${USER_INFO}
        `
    }

    static addCompany(): DocumentNode {
        return gql`
            ${COMPANY_INFO}
            mutation addCompany($input: AddCompanyInput!) {
                addCompany(input: $input) {
                    success
                    errors
                    company {
                        ...CompanyFields
                    }
                }
            }
        `
    }

    static updateCompany(): DocumentNode {
        return gql`
            ${COMPANY_INFO}
            mutation updateCompany($input: UpdateCompanyInput!) {
                updateCompany(input: $input) {
                    success
                    errors
                    company {
                        ...CompanyFields
                    }
                }
            }
        `
    }

    static companyList(): DocumentNode {
        return gql`
            ${COMPANY_INFO}, ${USER_INFO}, ${PAGE_INFO}
            query companyList(
                $offset: Int
                $before: String
                $after: String
                $first: Int
                $last: Int
                $search: String
            ) {
                companyList(
                    offset: $offset
                    before: $before
                    after: $after
                    first: $first
                    last: $last
                    search: $search
                ) {
                    pageInfo {
                        ...PageInfoFields
                    }
                    edges {
                        node {
                            ...CompanyFields
                            clients {
                                ...UserFields
                            }
                        }
                    }
                }
            }
        `
    }
}

export default CompanyAPI

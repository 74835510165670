import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    container: {
        flexDirection: 'row',
        gap: '48px',
        '&>div': {
            display: 'flex',
            flexBasis: '50%',
        },
    },
})

export default useStyles

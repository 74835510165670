import React from 'react'

import { useNavigate } from 'react-router-dom'

import Table from 'components/common/Table'
import { columns } from './config'
import { ITableRowValue } from 'types/table.types'
import { useCompanyList } from 'hooks/useCompany'
import { prepareUrl } from 'utils/route.utils'
import { paths } from 'config/routes'

interface ICompaniesList {
    onAction: (id: string, action?: string) => void
}
const CompaniesList = ({ onAction }: ICompaniesList): JSX.Element => {
    const { companies, isLoading, loadMore, hasNextPage } = useCompanyList()
    const navigate = useNavigate()

    const onCellClicked = (row: ITableRowValue, action?: string): void => {
        if (onAction) onAction(row.id, action)
    }
    const onSelectCompany = (id: string): void => {
        navigate(prepareUrl(paths.admin.contact, { contactId: id }))
    }

    return (
        <Table
            columns={columns}
            rows={companies as unknown as Array<ITableRowValue>}
            isLoading={isLoading}
            onRowClick={onSelectCompany}
            onCellClicked={onCellClicked}
            loadMore={loadMore}
            hasNextPage={hasNextPage}
        />
    )
}

export default CompaniesList

import { DocumentNode, gql } from '@apollo/client'

class ConfigAPI {
    static getConfig(): DocumentNode {
        return gql`
            query {
                config {
                    statuses {
                        key
                        val
                    }
                    categoryGroups {
                        key
                        val
                    }
                    originTypes {
                        key
                        val
                    }
                    destinationTypes {
                        key
                        val
                    }
                    movementTypes {
                        key
                        val
                    }
                    presentFlags {
                        key
                        val
                    }
                    vesselBodyTypes {
                        key
                        val
                    }
                    roles {
                        key
                        val
                    }
                    serviceConditions {
                        key
                        val
                    }
                    serviceDependsOn {
                        key
                        val
                    }
                }
            }
        `
    }
}

export default ConfigAPI

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useLoginFormStyles = makeStyles((theme: Theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        width: '560px',
    },
    formInput: {},
    rightButtons: {
        justifySelf: 'end',
        flexDirection: 'row',
        gap: '12px',
        justifyContent: 'flex-end',
    },
}))

export default useLoginFormStyles

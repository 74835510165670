import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        gap: '40px',
        position: 'relative',
    },
    previousOrder: {
        ...theme.typography.regular16,
        color: theme.colors.white,
        marginTop: '-40px',
        marginLeft: '-60px',
        marginRight: '-60px',
        height: '54px',
        flexDirection: 'row',
        backgroundColor: theme.colors.primary,
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '40px',
        paddingRight: '8px',
    },
    previousOrderTitle: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: '12px',
        '& button': {
            ...theme.typography.regular16,
            border: `1px solid ${theme.colors.white}`,
            padding: '6px 12px',
        },
    },
    closeBtn: {
        display: 'flex',
        justifySelf: 'flex-end',
        '& svg': {
            stroke: theme.colors.white,
            fill: theme.colors.white,
        },
    },
    checklistDownload: {
        flexDirection: 'column',
        gap: '8px',
        '& button': {
            color: theme.colors.secondary,
        },
    },
    checklistDownloadTitle: {
        ...theme.typography.regular16,
    },
    checklistDownloadButtons: {
        flexDirection: 'row',
        gap: '8px',
    },
    list: {
        ...theme.typography.regular16,
        listStyleType: 'disc',
        listStylePosition: 'inside',
    },
    filesSelection: {
        flexDirection: 'row',
        gap: '56px',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            flexDirection: 'column-reverse',
            alignItems: 'stretch',
        },
    },
    dropzone: {
        flexBasis: '370px',
        height: '160px',
        backgroundColor: theme.colors.grey10,
        border: `1px dashed ${theme.colors.grey50}`,
        borderRadius: '4px',
        maxWidth: theme.breakpoints.values.sm,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: theme.colors.grey20,
        },
    },
    uploadText: {
        ...theme.typography.regular16,
    },
    uploadButton: {
        ...theme.typography.regular16,
        color: theme.colors.secondary,
        margin: 0,
        padding: 0,
        background: 'none',
    },
    uploadDescription: {
        flexDirection: 'column',
        ...theme.typography.regular16,
    },
    attachments: {
        gap: '16px',
        alignItems: 'stretch',
    },
    attachment: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    filename: {
        ...theme.typography.regular16,
        flexDirection: 'row',
        alignItems: 'center',
        gap: '16px',
    },
    removeAttachment: {
        closeBtn: {
            display: 'flex',
            justifySelf: 'flex-end',
            '& svg': {
                stroke: theme.colors.systemBlack,
                fill: theme.colors.systemBlack,
            },
        },
    },
    attachmentLink: {
        ...theme.typography.regular16,
        color: theme.colors.systemBlack,
    },
}))

export default useStyles

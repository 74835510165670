import useStyles from './index.styles'

import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import classNames from 'classnames/bind'
import Stack from '@mui/material/Stack'
import { MenuItem, useTheme } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'

import { IMenuItem, TRender } from 'types/common.types'

interface INavigationMenuItem {
    isActive: boolean
    item: IMenuItem
    onMenuClick?: () => void
}

const NavigationMenuItem: React.FC<INavigationMenuItem> = ({
    item,
    isActive,
    onMenuClick,
}: INavigationMenuItem): React.ReactElement => {
    const classes = useStyles()
    const theme = useTheme()
    const { title, icon: Icon, url } = item as IMenuItem

    const [isHovered, setIsHovered] = useState<boolean>(false)
    const cx = classNames.bind(classes)
    const navigate = useNavigate()

    const onClick = (): void => {
        if (url) {
            navigate(url)
        } else if (item.onClick) item.onClick()
        if (onMenuClick) onMenuClick()
    }

    const getSubtitle = (): TRender => {
        if (item.subtitle)
            return <Stack className={classes.subtitle}>{item.subtitle}</Stack>
        return null
    }

    return (
        <MenuItem
            onMouseEnter={(): void => setIsHovered(true)}
            onMouseLeave={(): void => setIsHovered(false)}
            className={cx(classes.sidebarItem, {
                selected: isActive || isHovered,
            })}
            onClick={onClick}
        >
            <ListItemIcon className={classes.sidebarItemIcon}>
                <Icon
                    color={
                        isActive || isHovered
                            ? theme.colors.secondary
                            : theme.colors.primary
                    }
                />
            </ListItemIcon>
            <Stack className={classes.title}>
                {title}
                {getSubtitle()}
            </Stack>
        </MenuItem>
    )
}
export default NavigationMenuItem

import useStyles from './index.styles'

import React from 'react'

import { Outlet } from 'react-router-dom'

import Page from './Page'

const Auth = (): React.ReactElement => {
    const classes = useStyles()

    return (
        <Page>
            <div className={classes.container}>
                <Outlet />
            </div>
        </Page>
    )
}

export default Auth

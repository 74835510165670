import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        justifyContent: 'stretch',
        backgroundColor: 'transparent',
        overflow: 'hidden',
        gap: '16px',
    },
    crumbs: {
        flexDirection: 'row',
        ...theme.typography.medium16,
        textTransform: 'uppercase',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    page: {
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        overflowY: 'auto',
        overflowX: 'hidden',
        gap: '16px',
    },
    profile: {
        display: 'flex',
        flexBasis: '320px',
        flexGrow: 0,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        maxHeight: '100%',
        padding: '48px 56px',
        gap: '32px',
    },
    head: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        ...theme.typography.medium20,
    },
    buttons: {
        flexDirection: 'row',
        gap: '16px',
    },
    tabContent: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: 0,
    },
}))

export default useStyles

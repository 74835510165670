import useStyles from '../index.styles'

import React from 'react'

import { Stack } from '@mui/material'

import { IUser } from 'models/user.model'
import ContactName from 'assets/images/icons/ContactName'
import { formatUserName } from 'utils/user.utils'
import ContactPhone from 'assets/images/icons/ContactPhone'
import ContactEmail from 'assets/images/icons/ContactEmail'
import ContactCompany from 'assets/images/icons/ContactCompany'

interface IOrderClient {
    client: IUser
}
const OrderClient = ({ client }: IOrderClient): JSX.Element => {
    const classes = useStyles()

    return (
        <Stack className={classes.orderGroup}>
            <Stack className={classes.groupTitle}>Customer details</Stack>

            <Stack className={classes.groupRow}>
                <Stack className={classes.customerInfoBlock}>
                    <Stack className={classes.icon}>
                        <ContactCompany />
                    </Stack>
                    <Stack className={classes.title}>
                        {client?.company?.title || ''}
                    </Stack>
                </Stack>

                <Stack className={classes.customerInfoBlock}>
                    <Stack className={classes.icon}>
                        <ContactName />
                    </Stack>
                    <Stack className={classes.title}>
                        {formatUserName(client || undefined)}
                    </Stack>
                </Stack>
                <Stack className={classes.customerInfoBlock}>
                    <Stack className={classes.icon}>
                        <ContactPhone />
                    </Stack>
                    <Stack className={classes.title}>
                        {client?.phone || 'Not set'}
                    </Stack>
                </Stack>
                <Stack className={classes.customerInfoBlock}>
                    <Stack className={classes.icon}>
                        <ContactEmail />
                    </Stack>
                    <Stack className={classes.title}>
                        {client?.email || ''}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}
export default OrderClient

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { alpha } from '@mui/material'

import { adminNavbarWidth } from 'theme'

const useStyles = makeStyles((theme: Theme) => ({
    sidebarItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        width: adminNavbarWidth,
        height: '48px',
        padding: '0 16px',
        color: theme.colors.systemSteel,
        '& .MuiListItemIcon-root': {
            minWidth: '28px',
        },
    },
    sidebarItemText: {
        ...theme.typography.regular16,
    },
    sidebarItemIcon: {
        display: 'flex',
        marginRight: '8px',
        justifyContent: 'center',
        color: theme.colors.white,
    },
    selected: {
        backgroundColor: alpha(theme.colors.secondary || '#fff', 0.08),
        color: theme.colors.secondary,
    },
    title: {
        ...theme.typography.regular16,
        textTransform: 'uppercase',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 1,
    },
    subtitle: {
        ...theme.typography.regular12,
        width: '18px',
        height: '18px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        backgroundColor: theme.colors.primaryGreen,
        color: theme.colors.systemWhite,
    },
}))

export default useStyles

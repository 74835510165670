import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        width: '660px',
    },
    group: {
        flexDirection: 'row',
        gap: '48px',
    },
    group3: {
        flexDirection: 'row',
        gap: '48px',
        '&>div': {
            flexBasis: '33.3%',
            flexGrow: 0,
        },
    },

    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '12px',
    },
})

export default useStyles

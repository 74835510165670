import useStyles from './index.styles'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, IconButton, Link, Stack, Typography } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import { useDropzone } from 'react-dropzone'
import { useSnackbar } from 'notistack'

import { ECategoryGroup, IOrder } from 'models/order.model'
import { useChecklist, useLazyOrderList, useOrderApi } from 'hooks/useOrder'
import { formatUserName } from 'utils/user.utils'
import Loader from 'components/common/Loader'
import AttachmentCheck from 'assets/images/icons/AttachmentCheck'
import { useOnboarding } from 'hooks/useOnboarding'

interface IOrderDocuments {
    orderId: string
}
const OrderDocuments = ({ orderId }: IOrderDocuments): JSX.Element => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const { getOrders, orders } = useLazyOrderList(true)
    const { url, isLoading: checklistLoading } = useChecklist()
    const onboardingItems = useOnboarding()
    const {
        addOrderAttachments,
        deleteOrderAttachments,
        copyOrderAttachments,
        isLoading,
    } = useOrderApi()

    const { order, getOrder } = useOrderApi()
    useEffect(() => {
        if (orderId) {
            getOrder(orderId)
        }
    }, [orderId])

    useEffect(() => {
        if (url) {
            window.open(url, '_blank')
        }
    }, [url])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
        },
        maxSize: 1073741824,
        maxFiles: 15,
    })
    const downloadChecklist = (group: ECategoryGroup): void => {
        const documentUrl =
            onboardingItems.find(item => item.group.key === group)?.url || ''
        if (documentUrl) {
            window.open(documentUrl, '_blank')
        } else {
            enqueueSnackbar('File not found', { variant: 'warning' })
            // getChecklist()
        }
    }
    useEffect(() => {
        if (orderId && acceptedFiles.length > 0) {
            addOrderAttachments(orderId, acceptedFiles)
                .then(() => {
                    enqueueSnackbar('Files added')
                })
                .catch(() => {
                    enqueueSnackbar('Problem while uploading files', {
                        variant: 'error',
                    })
                })
        }
    }, [acceptedFiles, orderId])
    const inputFileRef = React.useRef<HTMLInputElement | null>(null)

    const handleUpload = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>): void => {
            inputFileRef?.current?.click()
            event.stopPropagation()
        },
        []
    )

    const [previousOrdersVisible, setPreviousOrdersVisible] =
        useState<boolean>(true)

    useEffect(() => {
        if (order?.vesselId && order.client.id) {
            getOrders({
                vesselId: order.vesselId,
                clientId: order.client.id,
                ordering: '-created_at',
            })
        }
    }, [order?.vesselId, order?.client.id])

    const previousOrder: IOrder | undefined = useMemo(() => {
        if (order && orders) {
            return orders.find(
                item =>
                    item.id !== order.id &&
                    item?.attachments &&
                    item.attachments.length > 0
            )
        }
        return undefined
    }, [orders, order])

    const deleteAttachment = (id: string): void => {
        deleteOrderAttachments([id]).then(() => {
            enqueueSnackbar('File deleted')
        })
    }
    const copyAttachments = (): void => {
        if (previousOrder) {
            copyOrderAttachments(previousOrder.id, orderId).then(() => {
                enqueueSnackbar('Attachments copied')
            })
        }
    }

    return (
        <>
            {previousOrder && previousOrdersVisible && (
                <Stack className={classes.previousOrder}>
                    <Stack className={classes.previousOrderTitle}>
                        {`Would you like to copy previous documents for ${formatUserName(
                            order?.client
                        )}, vessel ${order?.vesselName || ''}`}
                        <Button
                            size="small"
                            color="primary"
                            onClick={copyAttachments}
                        >
                            Copy Uploads
                        </Button>
                    </Stack>
                    <IconButton
                        className={classes.closeBtn}
                        onClick={(): void => setPreviousOrdersVisible(false)}
                    >
                        <CloseOutlined />
                    </IconButton>
                </Stack>
            )}
            <Stack className={classes.container}>
                <Stack className={classes.checklistDownload}>
                    <Stack className={classes.checklistDownloadTitle}>
                        Download and see required documents checklists:
                    </Stack>
                    {order && (
                        <Stack className={classes.checklistDownloadButtons}>
                            {order.portServicesPresent && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    disabled={checklistLoading}
                                    onClick={(): void =>
                                        downloadChecklist(ECategoryGroup.SHIP)
                                    }
                                >
                                    Ships agency
                                </Button>
                            )}
                            {order.crewServicesPresent && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    disabled={checklistLoading}
                                    onClick={(): void =>
                                        downloadChecklist(ECategoryGroup.CREW)
                                    }
                                >
                                    Crew management
                                </Button>
                            )}
                            {order.materialsServicesPresent && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    disabled={checklistLoading}
                                    onClick={(): void =>
                                        downloadChecklist(
                                            ECategoryGroup.MATERIAL
                                        )
                                    }
                                >
                                    Materials management
                                </Button>
                            )}
                        </Stack>
                    )}
                </Stack>
                <Stack className={classes.filesSelection}>
                    {isLoading && <Loader />}
                    <Stack {...getRootProps({ className: classes.dropzone })}>
                        <input {...getInputProps()} ref={inputFileRef} />
                        <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                alignItems="center"
                                justifyContent="center"
                                className={classes.uploadText}
                            >
                                Drag files here or
                            </Typography>
                            <Button
                                size="small"
                                style={{ marginLeft: 0 }}
                                variant="text"
                                className={classes.uploadButton}
                                title="browse"
                                onClick={handleUpload}
                            >
                                browse
                            </Button>
                            files
                        </Stack>
                    </Stack>
                    <Stack className={classes.uploadDescription}>
                        Please only upload:
                        <ul className={classes.list}>
                            <li>PDF, Excel</li>
                            <li>Files no larger than 100 Mb</li>
                            <li>Maximum 15 files at once</li>
                        </ul>
                    </Stack>
                </Stack>
                <Stack className={classes.attachments}>
                    {order?.attachments?.map(attachment => (
                        <Stack
                            key={attachment.id}
                            className={classes.attachment}
                        >
                            <Stack className={classes.filename}>
                                <AttachmentCheck />
                                <Link
                                    className={classes.attachmentLink}
                                    href={attachment.url}
                                    target="_blank"
                                >
                                    {attachment.filename}
                                </Link>
                            </Stack>
                            <IconButton
                                onClick={(): void =>
                                    deleteAttachment(attachment.id)
                                }
                            >
                                <CloseOutlined />
                            </IconButton>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </>
    )
}
export default OrderDocuments

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    vessel: {
        padding: '0 40px',
        ...theme.typography.regular16,
        '&.Mui-expanded': {
            backgroundColor: theme.colors.grayBackground,
        },
        '&:before': {
            content: 'none',
        },
    },
    name: {
        ...theme.typography.medium16,
        display: 'flex',
        alignItems: 'flex-start',
        flexBasis: '150px',
    },
    summary: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        gap: '16px',
    },
    vesselDetails: {
        padding: '0 0 12px 0',
        display: 'flex',
        flexDirection: 'column',
    },
    vesselRow: {
        flexDirection: 'row',
        maxWidth: '610px',
        flexBasis: '72px',
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'stretch',
        backgroundColor: 'transparent',
        overflow: 'hidden',
        gap: '24px',
        borderTop: `1px dashed ${theme.colors.grey30}`,
    },
    buttons: {
        flexDirection: 'row',
        gap: '12px',
    },
}))

export default useStyles

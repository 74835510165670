import useStyles from './index.styles'

import React, { useState } from 'react'

import Stack from '@mui/material/Stack'
import classNames from 'classnames/bind'

import useScreenSize from 'hooks/useScreenSize'
import { ITableColumn, ITableData, ITableRowValue } from 'types/table.types'
import { RowSelector } from './fields'
import { TRender } from 'types/common.types'

interface ITableRow {
    columns: Array<ITableColumn>
    row: ITableRowValue
    onCellClicked?: (row: ITableRowValue, action?: string) => void
    onSelect?: (id: string) => void
    onClick?: (id: string) => void
    selectedItems?: Array<string>
    isMultiSelect?: boolean
    isLeftSideFixed: boolean
    isRightSideFixed: boolean
}

const TableRow = ({
    columns,
    row,
    onCellClicked,
    onSelect,
    onClick,
    selectedItems,
    isMultiSelect,
    isLeftSideFixed,
    isRightSideFixed,
}: ITableRow): JSX.Element => {
    const classes = useStyles()
    const cx = classNames.bind(classes)
    const { isMobile } = useScreenSize()

    const [isHovered, setIsHovered] = useState<boolean>(true)
    const startColumnIndex = isMobile && onSelect ? 1 : 0
    const endColumnIndex =
        isMobile && columns[columns.length - 1].field === 'actions'
            ? columns.length - 2
            : columns.length - 1

    const getClassName = (
        column: ITableColumn,
        key: number,
        isHead?: boolean,
        isFirstRow?: boolean,
        isLastRow?: boolean
    ): string => {
        const cellIsSelected = !!(
            selectedItems && selectedItems.indexOf(row.id) !== -1
        )
        return cx(classes.bodyCell, {
            isSelected: !isHead && cellIsSelected,
            isHovered: !!isHovered,
            isSelectedHead: isHead && cellIsSelected,
            tableHead: isHead,
            noWrap: !!column.noWrap,
            bodyCellMobile: isMobile,
            bottomBorder: !isMobile || isLastRow,
            cellPaddingTop: !isMobile || isFirstRow,
            cellPaddingBottom: !isMobile || isLastRow,
            mobileHeaderSelected: isMobile && isHead,
            mobileHead: isMobile && isHead,
            alignStart: column.align === 'start',
            alignEnd: column.align === 'end',
            stickyStart: key === 0 && column.isSticky,
            stickyEnd: key === columns.length - 1 && column.isSticky,
            shadowStart: key === 0 && column.isSticky && isLeftSideFixed,
            shadowEnd:
                key === columns.length - 1 &&
                column.isSticky &&
                isRightSideFixed,
        })
    }

    const renderCell = (column: ITableColumn): TRender => {
        if (column.field === 'selectable') {
            return (
                <RowSelector
                    data={{
                        row,
                        onCellClicked: (tableRow: ITableRowValue): void => {
                            if (onSelect) onSelect(tableRow.id)
                        },
                    }}
                    isMultiSelect={!!isMultiSelect}
                    isSelected={!!selectedItems?.includes(row.id)}
                />
            )
        }
        if (typeof column.renderCell === 'function') {
            return column.renderCell({
                row,
                onCellClicked,
            } as ITableData<unknown>)
        }
        if (
            column.field &&
            Object.prototype.hasOwnProperty.call(row, column.field)
        ) {
            const value = row[column.field]

            if (typeof value === 'string' || typeof value === 'number') {
                return value
            }
        }
        return ''
    }
    const renderRow = (): Array<TRender> => {
        const response: Array<TRender> = []

        if (isMobile) {
            let firstColumn: TRender = null
            let secondColumn: TRender = null
            if (onSelect) {
                const column = columns[0]
                if (column) {
                    firstColumn = (
                        <Stack
                            key={`row-${row.id}-col-${column.field}`}
                            className={getClassName(
                                { ...column, align: 'start' },
                                0,
                                false,
                                true
                            )}
                        >
                            {renderCell(column)}
                        </Stack>
                    )
                }
                const last = columns[columns.length - 1]
                if (last && last.field === 'actions') {
                    secondColumn = (
                        <Stack
                            key={`row-${row.id}-col-${last.field}`}
                            className={getClassName(
                                { ...last, align: 'end' },
                                columns.length - 1,
                                false,
                                true
                            )}
                        >
                            {renderCell(last)}
                        </Stack>
                    )
                }
            }
            if (firstColumn || secondColumn) {
                response.push(firstColumn || '', secondColumn || '')
            }
        }
        for (
            let index = startColumnIndex;
            index <= endColumnIndex;
            index += 1
        ) {
            const column: ITableColumn | undefined = columns[index]
            if (column) {
                if (isMobile) {
                    response.push(
                        <Stack
                            key={`row-${row.id}-head-${column.field}`}
                            className={getClassName(
                                { ...column, align: 'start' },
                                index,
                                true,
                                index === 0 &&
                                    endColumnIndex !== columns.length - 1,
                                index === endColumnIndex
                            )}
                        >
                            {column.headerName}
                        </Stack>,
                        <Stack
                            key={`row-${row.id}-col-${column.field}`}
                            className={getClassName(
                                { ...column, align: 'end' },
                                index,
                                false,
                                index === 0 &&
                                    endColumnIndex !== columns.length - 1,
                                index === endColumnIndex
                            )}
                        >
                            {renderCell(column)}
                        </Stack>
                    )
                } else {
                    response.push(
                        <Stack
                            key={`row-${row.id}-col-${column.field}`}
                            className={getClassName(column, index)}
                        >
                            {renderCell(column)}
                        </Stack>
                    )
                }
            }
        }
        return response
    }

    const onRowClick = (): void => {
        if (onClick) onClick(row.id)
    }

    return (
        <Stack className={classes.rowWrapper} onClick={onRowClick}>
            {renderRow()}{' '}
        </Stack>
    )
}
export default TableRow

import {
    IOrderFormValues,
    IOrderServiceFormValues,
} from 'components/form/OrderForm/index.schema'
import {
    EServiceCondition,
    EServiceDepends,
    EServiceUnit,
    IService,
} from 'models/service.model'
import { daysDiff } from 'utils/date.utils'
import { IOrder } from 'models/order.model'

export const dependencyFields = [
    'etd',
    'eta',
    'movementType',
    'vesselLengthOverall',
    'vesselCifValue',
    'vesselNetRegisterTonnage',
    'vesselGrossRegisterTonnage',
    'temporaryImportationNeeded',
    'vesselDailyHirePerTc',
    'crewEta',
    'crewEtd',
    'materialsCifValue',
]
export type TOrderValue = string | number | boolean
export const getValuesFromOrder = (order: IOrder): IOrderFormValues => {
    return {
        etd: order.etd,
        eta: order.eta,
        movementType: order.movementType?.key,
        vesselLengthOverall: order.vesselLengthOverall,
        vesselCifValue: order.vesselCifValue,
        vesselNetRegisterTonnage: order.vesselNetRegisterTonnage,
        vesselGrossRegisterTonnage: order.vesselGrossRegisterTonnage,
        temporaryImportationNeeded: order.temporaryImportationNeeded,
        vesselDailyHirePerTc: order.vesselDailyHirePerTc,
        vesselFlag: order.vesselFlag?.key,
        materialsCifValue: order.materialsCifValue,
        crewEtd: order.crewEtd,
        crewEta: order.crewEta,
    }
}

export const getChangedValues = (
    currentValues: Record<string, string>,
    previousValues: Record<string, string> = {}
): Record<string, string> => {
    const changedFields: Record<string, string> = {}
    const getCurrentValue = (fieldName: string): string => {
        return Object.hasOwn(currentValues, fieldName)
            ? currentValues[fieldName]
            : ''
    }
    const getPreviousValue = (fieldName: string): string => {
        return Object.hasOwn(previousValues, fieldName)
            ? previousValues[fieldName]
            : ''
    }
    dependencyFields.forEach(dependency => {
        const currentValue = getCurrentValue(dependency)
        if (currentValue !== getPreviousValue(dependency)) {
            changedFields[dependency] = currentValue
        }
    })
    return changedFields
}
const isConditionPassed = (
    service: IService,
    formValues: IOrderFormValues
): boolean => {
    if (service.condition.key === EServiceCondition.GRT) {
        const value = formValues.vesselGrossRegisterTonnage || 0
        if (service.minCondition && service.minCondition >= value) {
            return false
        }
        return !(service.maxCondition && service.maxCondition < value)
    }
    return true
}

const getServiceRate = (service: IService): number => {
    return service.unit.key === EServiceUnit.FACTOR
        ? service.rate
        : service.rate / 100
}

export const formatServiceRate = (service: IService): string => {
    if (service.unit.key === EServiceUnit.PERCENT) {
        return `${service.rate}%`
    }
    return service.rate.toString()
}

export const calculateServiceRate = (
    serviceId: string,
    formValues: IOrderFormValues,
    groupedServices: Record<string, IService>
): number => {
    const service = groupedServices[serviceId]
    if (!service) return 0
    const serviceFormula = service.dependsOn?.key
    let serviceRate = 0
    let formAmount = 0
    let crewTotalDays = 0
    switch (serviceFormula) {
        case EServiceDepends.SERVICE_FEE:
            if (service.averageCost) {
                serviceRate =
                    service.averageCost * (1 + getServiceRate(service))
            } else {
                serviceRate = 1 + getServiceRate(service)
            }
            break
        case EServiceDepends.NRT:
            formAmount = formValues.vesselNetRegisterTonnage || 0
            if (formAmount) {
                serviceRate = formAmount * getServiceRate(service)
            }
            break
        case EServiceDepends.MATERIALS_CIF_VALUE:
            formAmount = formValues.materialsCifValue || 0
            if (formAmount) {
                serviceRate = formAmount * getServiceRate(service)
            }
            break
        case EServiceDepends.GRT:
            formAmount = formValues.vesselGrossRegisterTonnage || 0
            if (formAmount) {
                serviceRate = formAmount * getServiceRate(service)
            }
            break
        case EServiceDepends.CIF_VALUE:
            formAmount = (formValues.vesselCifValue as unknown as number) || 0
            if (formAmount) {
                serviceRate = formAmount * getServiceRate(service)
            }
            break
        case EServiceDepends.DAYS_LOA:
            serviceRate =
                (formValues.vesselLengthOverall || 0) * getServiceRate(service)
            break
        case EServiceDepends.DAYS_GRT:
            serviceRate =
                (formValues.vesselGrossRegisterTonnage || 0) *
                getServiceRate(service)
            break
        case EServiceDepends.DAYS_HDTC:
            serviceRate =
                (formValues.vesselDailyHirePerTc || 0) * getServiceRate(service)
            break
        case EServiceDepends.CREW_TOTAL_DAYS:
            if (formValues.crewEtd && formValues.crewEta) {
                crewTotalDays = daysDiff(formValues.crewEta, formValues.crewEtd)
            }
            serviceRate = crewTotalDays * getServiceRate(service)
            break

        default:
            serviceRate = getServiceRate(service)
            break
    }
    return service.maxValueCondition
        ? Math.min(service.maxValueCondition, serviceRate)
        : serviceRate
}

export const filterBoolean = (
    formServices: Array<IOrderServiceFormValues>,
    groupedServices: Record<string, IService>,
    filterKey: number,
    formValues: IOrderFormValues
): Array<IOrderServiceFormValues> => {
    const copiedServices = { ...groupedServices }
    const isEnabled = formValues.temporaryImportationNeeded
    return formServices.map(service => {
        let response = service
        if (
            groupedServices[service.serviceId] &&
            groupedServices[service.serviceId].condition.key &&
            groupedServices[service.serviceId].condition.key === filterKey
        ) {
            if (isEnabled && groupedServices[service.serviceId].required) {
                if (!response.quantity) {
                    response = { ...response, quantity: 1 }
                }
                if (!response.amount) {
                    response = {
                        ...response,
                        amount: calculateServiceRate(
                            response.serviceId,
                            formValues,
                            groupedServices
                        ),
                    }
                }
            }
            response = isEnabled
                ? { ...response, isSkipped: false }
                : { ...response, isSkipped: true }
        }
        delete copiedServices[service.serviceId]
        return response
    })
}

export const filterServices = (
    formServices: Array<IOrderServiceFormValues>,
    groupedServices: Record<string, IService>,
    formValues: IOrderFormValues
): Array<IOrderServiceFormValues> => {
    const copiedServices = { ...groupedServices }
    const {
        movementType,
        vesselGrossRegisterTonnage,
        temporaryImportationNeeded,
    } = formValues
    return formServices.map(service => {
        let response = service
        if (groupedServices[service.serviceId]) {
            if (movementType) {
                if (groupedServices[service.serviceId].movementType.key) {
                    if (
                        groupedServices[
                            service.serviceId
                        ].movementType.key.toString() ===
                        movementType.toString()
                    ) {
                        if (!response.quantity) {
                            response = { ...response, quantity: 1 }
                        }
                        if (!response.amount) {
                            response = {
                                ...response,
                                amount: calculateServiceRate(
                                    response.serviceId,
                                    formValues,
                                    groupedServices
                                ),
                            }
                        }
                        response = { ...response, isSkipped: false }
                    } else {
                        response = { ...response, isSkipped: true }
                    }
                }
            }
            if (
                vesselGrossRegisterTonnage &&
                groupedServices[service.serviceId].condition.key ===
                    EServiceCondition.GRT
            ) {
                if (
                    isConditionPassed(
                        groupedServices[service.serviceId],
                        formValues
                    )
                ) {
                    if (!response.quantity) {
                        response = { ...response, quantity: 1 }
                    }
                    if (!response.amount) {
                        response = {
                            ...response,
                            amount: calculateServiceRate(
                                response.serviceId,
                                formValues,
                                groupedServices
                            ),
                        }
                    }
                    response = {
                        ...response,
                        isSkipped: false,
                    }
                } else {
                    response = {
                        ...response,
                        quantity: 0,
                        amount: 0,
                        isSkipped: true,
                    }
                }
            }
            if (
                groupedServices[service.serviceId].condition.key ===
                EServiceCondition.TEMPORARY_IMPORTATION
            ) {
                if (temporaryImportationNeeded) {
                    if (!response.quantity) {
                        response = { ...response, quantity: 1 }
                    }
                    if (!response.amount) {
                        response = {
                            ...response,
                            amount: calculateServiceRate(
                                response.serviceId,
                                formValues,
                                groupedServices
                            ),
                        }
                    }
                    response = {
                        ...response,
                        isSkipped: false,
                    }
                } else {
                    response = {
                        ...response,
                        quantity: 0,
                        amount: 0,
                        isSkipped: true,
                    }
                }
            }
        }
        delete copiedServices[response.serviceId]
        return response
    })
}

export const updateServiceValue = (
    serviceId: string,
    services: Array<IOrderServiceFormValues>,
    rate: string | number,
    skipValidation?: boolean
): Array<IOrderServiceFormValues> => {
    const existing = services.find(service => service.serviceId === serviceId)
    if (existing) {
        return [
            ...services.filter(service => service.serviceId !== serviceId),
            {
                ...existing,
                amount:
                    parseFloat(rate as string) *
                    parseFloat(existing.quantity as string),
                skipValidation: !!skipValidation,
            },
        ]
    }
    return services
}

export const updateServiceQuantity = (
    serviceId: string,
    services: Array<IOrderServiceFormValues>,
    quantity: number
): Array<IOrderServiceFormValues> => {
    const existing = services.find(service => service.serviceId === serviceId)
    if (existing) {
        return [
            ...services.filter(service => service.serviceId !== serviceId),
            {
                ...existing,
                quantity,
            },
        ]
    }
    return services
}

export const updateServicesByCondition = (
    condition: number,
    formValues: IOrderFormValues,
    formServices: Array<IOrderServiceFormValues>,
    groupedServices: Record<string, IService>
): Array<IOrderServiceFormValues> => {
    let services = [...formServices]
    Object.values(groupedServices)
        .filter(service => service.dependsOn?.key === condition)
        .forEach(service => {
            if (
                !service.condition.key ||
                isConditionPassed(service, formValues)
            ) {
                services = updateServiceValue(
                    service.id,
                    services,
                    calculateServiceRate(
                        service.id,
                        formValues,
                        groupedServices
                    )
                )
            } else {
                services = updateServiceValue(service.id, services, 0, true)
            }
        })
    return services
}

export const updateQuantityByCondition = (
    condition: Array<number>,
    quantity: number,
    formServices: Array<IOrderServiceFormValues>,
    groupedServices: Record<string, IService>
): Array<IOrderServiceFormValues> => {
    let services = [...formServices]
    Object.values(groupedServices)
        .filter(service => condition.includes(service.dependsOn?.key))
        .forEach(service => {
            services = updateServiceQuantity(service.id, services, quantity)
        })
    return services
}

export const setServicesSkipped = (
    formValues: IOrderFormValues,
    changedFields: Array<string>,
    inputServices: Array<IOrderServiceFormValues>,
    groupedServices: Record<string, IService>
): Array<IOrderServiceFormValues> => {
    let services = [...inputServices]
    if (
        changedFields.includes('movementType') ||
        changedFields.includes('vesselGrossRegisterTonnage')
    ) {
        services = filterServices(services, groupedServices, formValues)
    }
    if (changedFields.includes('temporaryImportationNeeded')) {
        services = filterBoolean(
            services,
            groupedServices,
            EServiceCondition.TEMPORARY_IMPORTATION,
            formValues
        )
    }
    return services
}
export const updateServices = (
    formValues: IOrderFormValues,
    changedFields: Array<string>,
    formServices: Array<IOrderServiceFormValues>,
    groupedServices: Record<string, IService>
): Array<IOrderServiceFormValues> => {
    let services = [...formServices]
    let totalDays = 0
    if (formValues.etd && formValues.eta) {
        totalDays = daysDiff(formValues.eta, formValues.etd)
    }

    services = setServicesSkipped(
        formValues,
        changedFields,
        services,
        groupedServices
    )
    if (changedFields.includes('etd') || changedFields.includes('eta')) {
        services = updateQuantityByCondition(
            [
                EServiceDepends.TOTAL_DAYS,
                EServiceDepends.DAYS_GRT,
                EServiceDepends.DAYS_HDTC,
                EServiceDepends.DAYS_LOA,
            ],
            totalDays,
            services,
            groupedServices
        )
        services = updateServicesByCondition(
            EServiceDepends.TOTAL_DAYS,
            formValues,
            services,
            groupedServices
        )
    }
    if (
        changedFields.includes('crewEtd') ||
        changedFields.includes('crewEta')
    ) {
        services = updateServicesByCondition(
            EServiceDepends.CREW_TOTAL_DAYS,
            formValues,
            services,
            groupedServices
        )
    }

    if (changedFields.includes('vesselGrossRegisterTonnage')) {
        services = updateServicesByCondition(
            EServiceDepends.GRT,
            formValues,
            services,
            groupedServices
        )
    }
    if (changedFields.includes('vesselNetRegisterTonnage')) {
        services = updateServicesByCondition(
            EServiceDepends.NRT,
            formValues,
            services,
            groupedServices
        )
    }
    if (changedFields.includes('vesselCifValue')) {
        services = updateServicesByCondition(
            EServiceDepends.CIF_VALUE,
            formValues,
            services,
            groupedServices
        )
    }

    if (
        changedFields.includes('vesselGrossRegisterTonnage') ||
        changedFields.includes('etd') ||
        changedFields.includes('eta')
    ) {
        services = updateServicesByCondition(
            EServiceDepends.DAYS_GRT,
            formValues,
            services,
            groupedServices
        )
    }
    if (
        changedFields.includes('vesselDailyHirePerTc') ||
        changedFields.includes('etd') ||
        changedFields.includes('eta')
    ) {
        services = updateServicesByCondition(
            EServiceDepends.DAYS_HDTC,
            formValues,
            services,
            groupedServices
        )
    }
    if (
        changedFields.includes('vesselLengthOverall') ||
        changedFields.includes('etd') ||
        changedFields.includes('eta')
    ) {
        services = updateServicesByCondition(
            EServiceDepends.DAYS_LOA,
            formValues,
            services,
            groupedServices
        )
    }
    if (
        changedFields.includes('crewEtd') ||
        changedFields.includes('crewEta')
    ) {
        services = updateServicesByCondition(
            EServiceDepends.CREW_TOTAL_DAYS,
            formValues,
            services,
            groupedServices
        )
    }
    if (changedFields.includes('materialsCifValue')) {
        services = updateServicesByCondition(
            EServiceDepends.MATERIALS_CIF_VALUE,
            formValues,
            services,
            groupedServices
        )
    }
    return services
}

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        width: '560px',
    },
    group: {
        flexDirection: 'row',
        gap: '48px',
    },

    buttons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rightButtons: {
        justifySelf: 'end',
        flexDirection: 'row',
        gap: '12px',
        justifyContent: 'flex-end',
    },
})

export default useStyles

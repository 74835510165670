import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    categories: {
        flexDirection: 'column',
        gap: '40px',
    },
    category: {
        flexDirection: 'column',
        gap: '32px',
    },
    categoryTitle: {
        ...theme.typography.medium16,
    },
    service: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'stretch',
        gap: '16px',
    },
    serviceTitle: {
        flexGrow: 1,
        ...theme.typography.regular16,
    },
    serviceRate: {
        flex: '0 0 136px',
    },
    serviceDescription: {
        ...theme.typography.regular16,
        flex: '0 0 136px',
    },
    serviceHolder: {
        flexDirection: 'column',
    },
    error: {
        ...theme.typography.medium12,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: theme.colors.red,
    },
}))

export default useStyles

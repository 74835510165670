import useStyles from '../index.styles'

import React from 'react'

import { InputAdornment, Paper, Stack } from '@mui/material'
import { useFormikContext } from 'formik'

import { daysDiff } from 'utils/date.utils'
import OrderServices from 'components/form/OrderForm/OrderServices'
import { DatePicker, LabeledTextField, TextareaField } from 'components/inputs'
import { IOrderFormValues } from 'components/form/OrderForm/index.schema'
import { ICategory } from 'models/category.model'

interface IMaterialsServices {
    isLoading: boolean
    categories?: Array<ICategory>
}

const MaterialsServices = ({
    isLoading,
    categories,
}: IMaterialsServices): JSX.Element => {
    const classes = useStyles()
    const formik = useFormikContext<IOrderFormValues>()
    return (
        <Stack className={classes.container}>
            <Paper className={classes.formPaper}>
                <Stack className={classes.group}>
                    <LabeledTextField
                        id="cifValue-input"
                        name="materialsCifValue"
                        label="Cif Value (USD)"
                        placeholder="Cif Value (USD)"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            ),
                        }}
                        value={formik.values.materialsCifValue || ''}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.materialsCifValue &&
                            Boolean(formik.errors.materialsCifValue)
                        }
                        helperText={
                            formik.touched.materialsCifValue &&
                            formik.errors.materialsCifValue
                        }
                    />
                </Stack>
                <OrderServices
                    key="group-3"
                    isLoading={isLoading}
                    categories={categories}
                />
                <Stack className={classes.divider} />
                <TextareaField
                    id="comments-input"
                    name="materialsComments"
                    label="Comments (optional)"
                    placeholder="Enter your suggestions here"
                    rows={1}
                    value={formik.values.materialsComments || ''}
                    onChange={formik.handleChange}
                />
            </Paper>
        </Stack>
    )
}
export default MaterialsServices

import useStyles from '../index.styles'

import React from 'react'

import { Stack } from '@mui/material'
import { useFormikContext } from 'formik'

import { IOrderFormValues } from 'components/form/OrderForm/index.schema'
import CheckboxField from 'components/inputs/CheckboxField'
import { DatePicker, LabeledTextField } from 'components/inputs'
import { daysDiff } from 'utils/date.utils'

const OrderTowage = (): JSX.Element => {
    const classes = useStyles()
    const formik = useFormikContext<IOrderFormValues>()
    return (
        <Stack className={classes.formGroup}>
            <Stack className={classes.divider} />
            <CheckboxField
                name="towageNeeded"
                label="Towage needed"
                checked={formik.values.towageNeeded || false}
                onChange={(): void =>
                    formik.setFieldValue(
                        'towageNeeded',
                        !formik.values.towageNeeded
                    )
                }
            />
            <Stack className={classes.group3}>
                <DatePicker
                    id="ETA-input"
                    name="eta"
                    label="ETA"
                    placeholder="ETA"
                    value={formik.values.eta || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.eta && Boolean(formik.errors.eta)}
                    helperText={formik.touched.eta && formik.errors.eta}
                />
                <DatePicker
                    id="EDT-input"
                    name="etd"
                    label="ETD"
                    placeholder="ETD"
                    value={formik.values.etd || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.etd && Boolean(formik.errors.etd)}
                    helperText={formik.touched.etd && formik.errors.etd}
                />
                <LabeledTextField
                    id="days-input"
                    className={classes.totalDays}
                    name="days-towage"
                    label="Total Days"
                    placeholder="Total Days"
                    value={daysDiff(formik.values.eta, formik.values.etd)}
                />
            </Stack>
        </Stack>
    )
}
export default OrderTowage

import useStyles from './index.styles'

import React from 'react'

import { Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { Edit } from '@mui/icons-material'

import { ITableData, ITableRowValue } from 'types/table.types'

type ITableButtons = {
    data: ITableData<ITableRowValue>
}
const TableButtons = ({
    data: { row, onCellClicked },
}: ITableButtons): JSX.Element => {
    const classes = useStyles()
    const onClick = (action: string): void => {
        if (onCellClicked) onCellClicked(row, action)
    }
    return (
        <Stack className={classes.container}>
            <IconButton onClick={(): void => onClick('edit')}>
                <Edit />
            </IconButton>
        </Stack>
    )
}
export default TableButtons

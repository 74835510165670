import { gql } from '@apollo/client'

import { USER_DATA } from 'api/fragments/user.fragment'

export const ORDER_INFO = gql`
    ${USER_DATA}
    fragment OrderFields on OrderType {
        id
        number
        createdAt
        movementType {
            key
            val
        }
        client {
            ...UserInfo
        }
        administrator {
            ...UserFields
        }
        origin {
            key
            val
        }
        destination {
            key
            val
        }
        status {
            key
            val
        }
        temporaryImportationNeeded
        towageNeeded
        etd
        eta
        vesselId
        vesselName
        vesselFlag {
            key
            val
        }
        vesselBodyType {
            key
            val
        }
        vesselNetRegisterTonnage
        vesselGrossRegisterTonnage
        vesselLengthOverall
        vesselDailyHirePerTc
        vesselCifValue
        materialsCifValue
        attachments {
            id
            filename
            url
        }
        services {
            id
            title
            description
            rate
            quantity
            amount
        }
        crewEta
        crewEtd
        crewServicesPresent
        portServicesPresent
        materialsServicesPresent
        hsCode
        portComments
        crewComments
        materialsComments
    }
`

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { alpha } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        maxHeight: '100%',
        padding: '48px 40px',
        gap: '32px',
        overflow: 'hidden',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '48px 0',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'stretch',
            width: '100%',
        },
    },
}))

export default useStyles

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useLoginFormStyles = makeStyles((theme: Theme) => ({
    fieldsBlock: {
        gap: '24px',
    },
    forgotPasswordLink: {
        display: 'flex',
        alignSelf: 'flex-end',
        ...theme.typography.regular16,
        color: theme.colors.secondary,
        lineHeight: '24px',
    },
    facebookBtn: {
        display: 'flex',
        justifyContent: 'flex-start',
        '& svg': {
            width: '36px',
            height: '36px',
        },
    },
    googleBtn: {
        backgroundColor: theme.colors.white,
    },
}))

export default useLoginFormStyles

import React, { useCallback, useMemo } from 'react'

import { Outlet, useNavigate } from 'react-router-dom'

import Page from 'components/layout/Page'
import People from 'assets/images/icons/People'
import { paths } from 'config/routes'
import { IMenuItem } from 'types/common.types'
import { useAuth } from 'hooks/useAuth'
import Logout from 'assets/images/icons/Logout'
import Orders from 'assets/images/icons/Orders'
import { IReactiveDialog, useDialog } from 'hooks/useDialog'
import ConfirmationDialog, {
    IConfirmationDialog,
} from 'components/common/ConfirmationDialog'
import Profile from 'assets/images/icons/Profile'
import Settings from 'assets/images/icons/Settings'

const AdminLayout = (): React.ReactElement => {
    const { logout } = useAuth()
    const { open, close } = useDialog()
    const navigate = useNavigate()

    const onLogout = useCallback(() => {
        close()
        logout().then(() => {})
        navigate(paths.login)
    }, [logout, navigate])

    const openConfirmationDialog = useCallback((): void => {
        open({
            component: ConfirmationDialog,
            props: {
                title: 'Are you sure you want to Log Out from the African Harbour?',
                onConfirm: onLogout,
                onCancel: close,
            },
            options: {
                title: 'Logout',
                onClose: close,
            },
        } as IReactiveDialog<IConfirmationDialog>)
    }, [open, close, onLogout])

    const menuList: IMenuItem[] = useMemo((): IMenuItem[] => {
        return [
            {
                id: '001',
                title: 'Orders',
                icon: Orders,
                url: paths.admin.home,
            },
            {
                id: '002',
                title: 'Contacts',
                icon: People,
                url: paths.admin.contacts,
            },
            {
                id: '003',
                title: 'Settings',
                icon: Settings,
                url: paths.admin.settings,
            },
        ]
    }, [])

    const bottomMenuList: IMenuItem[] = useMemo((): IMenuItem[] => {
        return [
            {
                id: '001',
                title: 'Profile',
                icon: Profile,
                url: paths.admin.profile,
            },
            {
                id: '003',
                title: 'Logout',
                onClick: openConfirmationDialog,
                icon: Logout,
            },
        ]
    }, [onLogout])

    return (
        <Page menuItems={menuList} bottomMenu={bottomMenuList}>
            <Outlet />
        </Page>
    )
}

export default AdminLayout

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useLoginFormStyles = makeStyles((theme: Theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        minWidth: '300px',
    },
    subtitle: {
        ...theme.typography.regular16,
    },
    formInput: {},
}))

export default useLoginFormStyles

export const API_ROOT =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_ROOT
        : (window as any).REACT_APP_API_ROOT // eslint-disable-line @typescript-eslint/no-explicit-any
export const SUBSCRIPTIONS_ROOT =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_SUBSCRIPTIONS_ROOT
        : (window as any).REACT_APP_SUBSCRIPTIONS_ROOT // eslint-disable-line @typescript-eslint/no-explicit-any
export const GRAPHQL_PATH =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_GRAPHQL_PATH
        : (window as any).REACT_APP_GRAPHQL_PATH // eslint-disable-line @typescript-eslint/no-explicit-any

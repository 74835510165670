import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    container: {
        width: '100%',
        justifyContent: 'center',
    },
    contentCompensation: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },
})

export default useStyles

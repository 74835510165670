import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '560px',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        '& svg.MuiSvgIcon-root': {
            width: '24px',
            height: '24px',
        },
    },
    titleText: {
        ...theme.typography.medium16,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    group: {
        flexDirection: 'row',
        gap: '48px',
    },
    buttons: {
        flexDirection: 'row',
        gap: '12px',
        justifyContent: 'flex-end',
    },
    users: {
        gap: '24px',
    },
    userList: {
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    user: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    email: {
        flexBasis: '50%',
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
    },
    status: {
        flexBasis: '35%',
    },
    actions: {
        flexDirection: 'row',
        '& button': {
            width: '48px',
            height: '48px',
            borderRadius: '8px',
            '& path': {},
        },
    },
    companyTitle: {
        ...theme.typography.regular16,
        textTransform: 'uppercase',
    },
    addedClient: {
        color: theme.colors.primaryGreen,
    },
    pendingClient: {
        color: theme.colors.grayscaleStone,
    },
}))

export default useStyles

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    head: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '28px',
    },
    title: {
        ...theme.typography.medium20,
    },
    categories: {
        flexDirection: 'column',
        position: 'relative',
    },
    category: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        gap: '8px',
        paddingLeft: 0,
        paddingRight: 0,
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    serviceHolder: {
        flexDirection: 'column',
    },
    error: {
        ...theme.typography.medium12,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: theme.colors.red,
        marginRight: '104px',
    },
    categorySummary: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        justifyContent: 'space-between',
        '& .MuiAccordionSummary-content': {
            overflow: 'hidden',
        },
        '&.Mui-expanded': {
            minHeight: 'auto',
        },
    },
    categoryTitle: {
        ...theme.typography.bold16,
    },
    service: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'stretch',
        gap: '16px',
    },
    titleHeader: {
        flexGrow: 1,
        ...theme.typography.bold14,
        textTransform: 'uppercase',
    },
    rateHeader: {
        flex: '0 0 176px',
        ...theme.typography.bold14,
        textTransform: 'uppercase',
        alignItems: 'flex-end',
    },
    quantityHeader: {
        flex: '0 0 88px',
        ...theme.typography.bold14,
        textTransform: 'uppercase',
    },
    totalHeader: {
        flex: '0 0 88px',
        ...theme.typography.bold14,
        textTransform: 'uppercase',
    },
    serviceTitle: {
        flexGrow: 1,
        ...theme.typography.regular16,
        gap: '16px',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    averageCost: {
        flexDirection: 'row',
        flex: '180px 0 0',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    serviceRate: {
        ...theme.typography.regular16,
        flex: '0 0 176px',
        alignItems: 'flex-end',
        textAlign: 'end',
    },
    serviceQuantity: {
        flex: '0 0 88px',
        gap: '8px',
        flexDirection: 'row',
    },
    serviceTotal: {
        flex: '0 0 88px',
        gap: '8px',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
}))

export default useStyles

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'row',
        padding: '4px',
        gap: '16px',
    },
    stepOuter: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: '16px',
    },
    connector: {
        alignSelf: 'flex-start',
        borderBottom: `1px solid ${theme.colors.grey30}`,
        width: '50px',
        height: '50%',
    },
    step: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
    },
    stepOuterActive: {},
    clickable: {
        cursor: 'pointer',
    },
    stepNumber: {
        flexShrink: 0,
        border: `1px solid ${theme.colors.grey80}`,
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        color: theme.colors.grey80,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        color: theme.colors.primary,
        margin: '0 4px',
    },
    stepTitle: {
        textAlign: 'center',
    },
    active: {
        color: theme.colors.secondary,
        borderColor: theme.colors.secondary,
    },
}))

export default useStyles

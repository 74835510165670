import useLoginFormStyles from './index.styles'

import React from 'react'

import { useFormik } from 'formik'
import { Button, Link, Stack } from '@mui/material'

import { PasswordField, TextField } from 'components/inputs'
import {
    ILoginFormValues,
    LoginFormInitialValues,
    LoginFormScheme,
} from './index.schema'
import { IAuthResponse, useAuth } from 'hooks/useAuth'
import Loader from 'components/common/Loader'

interface LoginProps {
    email?: string
    onLogin: (response: IAuthResponse) => void
    onForgotPassword: () => void
}

const LoginForm = (props: LoginProps): JSX.Element => {
    const { email, onLogin, onForgotPassword } = props
    const { signIn, isLoading } = useAuth()
    const formik = useFormik({
        initialValues: email
            ? { ...LoginFormInitialValues, email }
            : LoginFormInitialValues,
        validationSchema: LoginFormScheme,
        onSubmit: (values: ILoginFormValues) => {
            signIn(values.email, values.password)
                .then((response: IAuthResponse) => {
                    if (response?.success) {
                        onLogin(response)
                    } else if (response?.errors) {
                        formik.setFieldError('email', response.errors[0])
                    }
                })
                .catch(error => {
                    formik.setFieldError('email', error.message)
                })
        },
    })

    const classes = useLoginFormStyles()
    const onForgotPasswordClicked = (): void => onForgotPassword()
    return (
        <div>
            {isLoading && <Loader />}
            <form onSubmit={formik.handleSubmit}>
                <Stack className={classes.fieldsBlock}>
                    <TextField
                        autoFocus
                        id="email-input"
                        fullWidth
                        name="email"
                        label="Email"
                        placeholder="Enter your email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <PasswordField
                        autoFocus
                        id="password-input"
                        name="password"
                        label="Password"
                        placeholder="Enter your password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                        }
                        helperText={
                            formik.touched.password && formik.errors.password
                        }
                        fullWidth
                    />
                    <Stack>
                        <Link
                            className={classes.forgotPasswordLink}
                            onClick={onForgotPasswordClicked}
                        >
                            Forgot password?
                        </Link>
                    </Stack>

                    <Button
                        type="submit"
                        color="primary"
                        disabled={isLoading}
                        fullWidth
                    >
                        Sign In
                    </Button>
                </Stack>
            </form>
        </div>
    )
}

export default LoginForm

import useStyles from './index.styles'

import React, { useState } from 'react'

import { useFormik } from 'formik'
import { Button, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'

import { TextField } from 'components/inputs'
import ForgotPasswordFormScheme from './index.schema'
import { useAuth } from 'hooks/useAuth'
import Loader from 'components/common/Loader'
import SuccessIcon from 'assets/images/icons/SuccessIcon'

const ForgotPasswordForm = (): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar()
    const { resetPassword, isLoading } = useAuth()

    const [emailIsSent, setEmailIsSent] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordFormScheme,
        onSubmit: values => {
            resetPassword(values.email).then(response => {
                const { success } = response
                if (success) {
                    enqueueSnackbar('Link sent, check Your email', {
                        variant: 'success',
                    })
                    setEmailIsSent(true)
                } else {
                    enqueueSnackbar('Email not found', { variant: 'error' })
                }
            })
        },
    })

    const classes = useStyles()

    return (
        <div>
            {isLoading && <Loader />}
            <form onSubmit={formik.handleSubmit} className={classes.form}>
                {emailIsSent ? (
                    <Stack className={classes.title}>
                        <SuccessIcon />
                        The reset password link has been sent.
                    </Stack>
                ) : (
                    <Stack className={classes.title}>
                        Having trouble signing in?
                    </Stack>
                )}
                {emailIsSent ? (
                    <>
                        <Stack className={classes.subtitle}>
                            Please check your e-mail. Link expires in 24 hours.
                            If you don’t see it, you may need to check your spam
                            folder.
                        </Stack>
                        <Stack className={classes.subtitleBold}>
                            Still can’t find an e-mail?
                        </Stack>
                    </>
                ) : (
                    <Stack className={classes.subtitle}>
                        We will send a password reset link to the email address
                        provided. Be sure to check your Spam folder.
                    </Stack>
                )}
                <Stack className={classes.formInput}>
                    <TextField
                        id="email-input"
                        name="email"
                        label="Email"
                        placeholder="Enter your email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </Stack>
                <Button
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                    fullWidth
                >
                    {emailIsSent ? 'Resend Link' : 'Send recovery link'}
                </Button>
            </form>
        </div>
    )
}

export default ForgotPasswordForm

import React from 'react'

const FilterIcon = (): React.ReactElement => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.5722 12.3333V20.3333C13.5722 20.7111 13.4444 21.0278 13.1889 21.2833C12.9333 21.5389 12.6167 21.6667 12.2389 21.6667H9.57222C9.19444 21.6667 8.87778 21.5389 8.62222 21.2833C8.36667 21.0278 8.23889 20.7111 8.23889 20.3333V12.3333L0.272222 2.16666C-0.0388888 1.78888 -0.0833334 1.38888 0.138889 0.966661C0.361111 0.544439 0.705555 0.333328 1.17222 0.333328H20.6389C21.1056 0.333328 21.45 0.544439 21.6722 0.966661C21.8944 1.38888 21.85 1.78888 21.5389 2.16666L13.5722 12.3333ZM10.9056 12.4667L18.9056 2.33333H2.90556L10.9056 12.4667Z"
                fill="white"
            />
        </svg>
    )
}
export default FilterIcon

import React from 'react'

import { TextField } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

import { IKeyVal } from 'types/common.types'

interface SelectFieldProps {
    id?: string
    name?: string
    label: string
    value?: number | string
    size?: 'small' | 'medium'
    variant?: 'standard' | 'filled' | 'outlined'
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    error?: boolean | undefined
    helperText?: string | false | undefined
    options: Array<IKeyVal<number | string>>
    disabled?: boolean
    className?: string
}

const SelectShrinkField = ({
    options,
    onChange,
    ...rest
}: SelectFieldProps): JSX.Element => {
    const onSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(e)
        e.preventDefault()
    }
    return (
        <TextField
            select
            fullWidth
            autoComplete="off"
            FormHelperTextProps={{
                variant: 'standard',
            }}
            type="text"
            variant="outlined"
            onChange={onSelect}
            {...rest}
        >
            {options.map(option => (
                <MenuItem key={option.key} value={option.key}>
                    {option.val}
                </MenuItem>
            ))}
        </TextField>
    )
}

export default SelectShrinkField

import useStyles from '../index.styles'

import React, { useEffect, useMemo, useState } from 'react'

import { Stack } from '@mui/material'
import { useFormikContext } from 'formik'

import { LabeledTextField, TextareaField } from 'components/inputs'
import { IUser } from 'models/user.model'
import { useUser, useUserApi } from 'hooks/useUser'
import { IOrderFormValues } from 'components/form/OrderForm/index.schema'
import ContactName from 'assets/images/icons/ContactName'
import { formatUserName } from 'utils/user.utils'
import ContactPhone from 'assets/images/icons/ContactPhone'
import ContactEmail from 'assets/images/icons/ContactEmail'
import ContactCompany from 'assets/images/icons/ContactCompany'
import { UserRole } from 'config/user'
import CompanyAutocomplete from 'components/common/CompanyAutocomplete'
import { ICompany } from 'models/companyUser.model'
import SelectField from 'components/inputs/SelectField'
import { useCompanyApi } from 'hooks/useCompany'
import { IKeyVal } from 'types/common.types'
import Loader from 'components/common/Loader'

interface IOrderCustomer {
    isEditable?: boolean
}
const OrderCustomer = ({ isEditable }: IOrderCustomer): JSX.Element => {
    const classes = useStyles()
    const formik = useFormikContext<IOrderFormValues>()
    const { user: authUser } = useUser()
    const { user, getUser, isLoading: userLoading } = useUserApi()
    const { getCompany, isLoading: companyLoading } = useCompanyApi()
    const isLoading: boolean = useMemo(() => {
        return userLoading || companyLoading
    }, [userLoading, companyLoading])
    const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(
        null
    )
    const [selectedClient, setSelectedClient] = useState<IUser | null>(null)
    useEffect(() => {
        if (selectedClient?.id !== formik.values.clientId)
            if (formik.values.clientId) {
                getUser(formik.values.clientId).then(response => {
                    setSelectedClient(response)
                })
            } else {
                setSelectedClient(null)
            }
    }, [formik.values.clientId, selectedClient])

    useEffect(() => {
        if (selectedClient && !selectedCompany && selectedClient.company?.id) {
            getCompany(selectedClient.company.id).then(response => {
                setSelectedCompany(response)
            })
        }
    }, [selectedCompany, selectedClient])

    useEffect(() => {
        if (selectedCompany) {
            if (
                selectedCompany.clients &&
                selectedCompany.clients.length === 1
            ) {
                if (formik.values.clientId !== selectedCompany.clients[0].id) {
                    setSelectedClient(selectedCompany.clients[0])
                    formik.setFieldValue(
                        'clientId',
                        selectedCompany.clients[0].id
                    )
                }
            } else if (formik.values.clientId) {
                const orderClient = selectedCompany.clients.find(
                    client => client.id === formik.values.clientId
                )
                if (!orderClient) {
                    setSelectedClient(null)
                    formik.setFieldValue('clientId', null)
                }
            }
        }
    }, [selectedCompany, formik.values.clientId])

    const clientsKeyVal: Array<IKeyVal<string>> = useMemo(() => {
        if (selectedCompany && selectedCompany.clients) {
            return selectedCompany.clients.map(client => {
                return {
                    key: client.id,
                    val: formatUserName(client),
                } as IKeyVal<string>
            })
        }
        return []
    }, [selectedCompany])

    const onSelectCompany = (selected: ICompany | null): void => {
        if (!selected) {
            setSelectedClient(null)
            setSelectedCompany(null)
            formik.setFieldValue('clientId', null)
        } else {
            getCompany(selected.id).then(companyResponse =>
                setSelectedCompany(companyResponse)
            )
        }
    }

    const isCustomerEditable = useMemo(() => {
        return isEditable && authUser?.role.key === UserRole.ADMIN
    }, [authUser, isEditable])

    const isCompanyEditable = useMemo(() => {
        if (authUser?.role.key === UserRole.CLIENT) return false
        return authUser?.role.key === UserRole.ADMIN && !formik.values.orderId
    }, [formik.values.orderId, authUser, isEditable])

    useEffect(() => {}, [user])
    return (
        <Stack className={classes.formGroup}>
            {isLoading && <Loader />}
            <Stack className={classes.groupTitle}>Customer details</Stack>
            {isCustomerEditable ? (
                <>
                    <Stack className={classes.group}>
                        <CompanyAutocomplete
                            label="Company name"
                            disabled={!isCompanyEditable}
                            value={selectedCompany?.id || ''}
                            onSelect={onSelectCompany}
                            error={formik.touched.clientId && !selectedCompany}
                            helperText={
                                formik.touched.clientId &&
                                !selectedCompany &&
                                'Select company'
                            }
                        />
                        <SelectField
                            id="clientId-input"
                            name="clientId"
                            label="Customer"
                            options={clientsKeyVal}
                            value={formik.values.clientId}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.clientId &&
                                Boolean(formik.errors.clientId)
                            }
                            helperText={
                                formik.touched.clientId &&
                                formik.errors.clientId
                            }
                        />
                        {/*
                        <ClientAutocomplete
                            label="Customer"
                            client={selectedClient}
                            onSelect={onSelectClient}
                            error={
                                formik.touched.clientId &&
                                Boolean(formik.errors.clientId)
                            }
                            helperText={
                                formik.touched.clientId &&
                                formik.errors.clientId
                            }
                        />
*/}
                        {/*

                        <LabeledTextField
                            disabled
                            id="order-company-name-input"
                            name="order-company-name-input"
                            label="Company name"
                            placeholder="Company name"
                            value={selectedClient?.company?.title || ''}
                        />
*/}
                    </Stack>
                    <Stack className={classes.group}>
                        <LabeledTextField
                            disabled
                            id="order-phone-input"
                            name="order-phone-input"
                            label="Phone number"
                            placeholder="Phone number"
                            value={selectedClient?.phone || ''}
                        />
                        <LabeledTextField
                            disabled
                            id="order-email-input"
                            name="order-email-input"
                            label="E-mail address"
                            placeholder="E-mail address"
                            value={selectedClient?.email || ''}
                        />
                    </Stack>
                </>
            ) : (
                <Stack className={classes.customerInfo}>
                    <Stack className={classes.customerInfoBlock}>
                        <Stack className={classes.icon}>
                            <ContactCompany />
                        </Stack>
                        <Stack className={classes.title}>
                            {selectedClient?.company?.title || ''}
                        </Stack>
                    </Stack>

                    <Stack className={classes.customerInfoBlock}>
                        <Stack className={classes.icon}>
                            <ContactName />
                        </Stack>
                        <Stack className={classes.title}>
                            {formatUserName(selectedClient || undefined)}
                        </Stack>
                    </Stack>
                    <Stack className={classes.customerInfoBlock}>
                        <Stack className={classes.icon}>
                            <ContactPhone />
                        </Stack>
                        <Stack className={classes.title}>
                            {selectedClient?.phone || 'Not set'}
                        </Stack>
                    </Stack>
                    <Stack className={classes.customerInfoBlock}>
                        <Stack className={classes.icon}>
                            <ContactEmail />
                        </Stack>
                        <Stack className={classes.title}>
                            {selectedClient?.email || ''}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
}
export default OrderCustomer

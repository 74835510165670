import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    checkboxHelper: {
        marginTop: '4px',
    },
    alignHelperText: {
        marginLeft: '28px',
    },
})

export default useStyles

import useStyles from './index.styles'

import React from 'react'

import AuthorizationPage from 'components/common/AuthorizationPage'
import CreatePasswordForm from 'components/form/CreatePasswordForm'

const CreatePassword = (): JSX.Element => {
    const classes = useStyles()

    return (
        <AuthorizationPage title="Create Password">
            <div className={classes.container}>
                <CreatePasswordForm />
            </div>
        </AuthorizationPage>
    )
}

export default CreatePassword

import useStyles from './index.styles'

import React from 'react'

import { TextFieldProps } from '@mui/material'

import { LabeledTextField } from 'components/inputs'

const TextareaField: React.FC<TextFieldProps> = (
    props: TextFieldProps
): JSX.Element => {
    const { error, rows = 6, ...rest } = props
    const classes = useStyles()

    return (
        <LabeledTextField
            className={classes.textarea}
            multiline
            rows={rows}
            error={!!error}
            {...rest}
        />
    )
}
export default TextareaField

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        '& label': {
            ...theme.typography.medium15,
            position: 'unset',
            transform: 'none',
            '&.MuiInputLabel-shrink': {
                display: 'block',
            },
        },
    },
}))

export default useStyles

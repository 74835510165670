import useStyles from './index.styles'

import React, { useCallback } from 'react'

import { Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { paths } from 'config/routes'
import Plus from 'assets/images/icons/Plus'
import Orders from 'components/common/Orders'
import { IOrder, IOrderConfig } from 'models/order.model'
import { prepareUrl } from 'utils/route.utils'
import { IReactiveDialog, useDialog } from 'hooks/useDialog'
import OrderConfigDialog, {
    IOrderConfigDialog,
} from 'components/common/OrderConfigDialog'
import { useCheckOrders } from 'hooks/useOrder'
import Loader from 'components/common/Loader'
import Onboarding from 'components/pages/Onboarding'

const Home = (): JSX.Element => {
    const navigate = useNavigate()
    const classes = useStyles()
    const { hasOrders, isLoading } = useCheckOrders()
    const onOrderClick = (order: IOrder): void => {
        navigate(prepareUrl(paths.admin.viewOrder, { orderId: order.id }))
    }
    const { open, close } = useDialog()

    const openOrderConfigDialog = useCallback((): void => {
        const createNewOrder = (config: IOrderConfig): void => {
            close()
            navigate(paths.admin.addOrder, {
                state: {
                    orderConfig: config,
                },
            })
        }

        open({
            component: OrderConfigDialog,
            props: {
                onConfirm: createNewOrder,
                onCancel: close,
            },
            options: {
                title: 'Choose services for a new order',
                onClose: close,
            },
        } as IReactiveDialog<IOrderConfigDialog>)
    }, [open, close])

    if (isLoading) {
        return (
            <Stack className={classes.container}>
                <Loader />
            </Stack>
        )
    }
    return (
        <Stack className={classes.container}>
            {hasOrders ? (
                <>
                    <Stack className={classes.crumbs}>
                        <Stack className={classes.title}>Orders</Stack>
                        <Button
                            onClick={openOrderConfigDialog}
                            color="primary"
                            startIcon={<Plus />}
                        >
                            New Order
                        </Button>
                    </Stack>
                    <Stack className={classes.page}>
                        <Orders onOrderClick={onOrderClick} />
                    </Stack>
                </>
            ) : (
                <Onboarding />
            )}
        </Stack>
    )
}

export default Home

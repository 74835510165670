import React, { useState } from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material'

import { TextField } from '../index'

const PasswordField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleToggleVisibility = (): void => {
        setShowPassword(prevState => !prevState)
    }

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        event.preventDefault()
    }

    return (
        <div className="password-field">
            <TextField
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleToggleVisibility}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                {...props}
            />
        </div>
    )
}

export default PasswordField

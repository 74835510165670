import useStyles from './index.styles'

import React from 'react'

import { TextField as MaterialTextField, TextFieldProps } from '@mui/material'
import cx from 'classnames'

const LabeledTextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
    const classes = useStyles()
    const { className, ...rest } = props
    const containerClasses = cx(classes.container, className)
    return (
        <MaterialTextField
            className={containerClasses}
            FormHelperTextProps={{
                variant: 'standard',
            }}
            type="text"
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: false }}
            autoComplete="off"
            {...rest}
        />
    )
}

export default LabeledTextField

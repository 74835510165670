import useStyles from './index.styles'

import React from 'react'

import AuthorizationPage from 'components/common/AuthorizationPage'
import ResetPasswordForm from 'components/form/ResetPasswordForm'

const ResetPassword = (): JSX.Element => {
    const classes = useStyles()

    return (
        <AuthorizationPage title="Reset Password">
            <div className={classes.container}>
                <ResetPasswordForm />
            </div>
        </AuthorizationPage>
    )
}

export default ResetPassword

import { IKeyVal } from 'types/common.types'
import { IUser } from 'models/user.model'

export enum EOrderStep {
    INFO = 1,
    SHIP_SERVICES = 2,
    CREW_SERVICES = 3,
    MATERIALS_SERVICES = 4,
    CALCULATION = 5,
    DOCUMENTS = 6,
}
export const defaultOrderSteps: Record<number, string> = {
    [EOrderStep.INFO]: 'Client Info',
    [EOrderStep.SHIP_SERVICES]: 'Ships Agency',
    [EOrderStep.CREW_SERVICES]: 'Crew Management',
    [EOrderStep.MATERIALS_SERVICES]: 'Materials Management',
    [EOrderStep.CALCULATION]: 'Calculations',
    [EOrderStep.DOCUMENTS]: 'Documents',
}

export interface IOrderConfig {
    SHIP_SERVICES?: boolean
    CREW_SERVICES?: boolean
    MATERIAL_SERVICES?: boolean
}

export enum ECategoryGroup {
    SHIP = 2,
    CREW = 1,
    MATERIAL = 3,
}
export enum EOrderStatus {
    DRAFT = 1,
    IN_PROGRESS = 2,
    FINISHED = 3,
    CANCELLED = 4,
}
export enum EMovementType {
    FOREIGN = 1,
    LOCAL = 2,
}

export interface IOrderAttachment {
    id: string
    filename: string
    url: string
}
export interface IOrderService {
    id: string
    title: string
    description: string
    rate: number
    quantity: number
    amount: number
}

export interface IOrder {
    id: string
    number: string
    createdAt: string
    movementType?: IKeyVal
    origin?: IKeyVal
    destination?: IKeyVal
    status: IKeyVal
    temporaryImportationNeeded?: boolean
    towageNeeded?: boolean
    etd?: string
    eta?: string
    crewEta?: string
    crewEtd?: string
    client: IUser
    administrator?: IUser
    vesselId?: string
    vesselName?: string
    vesselFlag?: IKeyVal
    vesselBodyType?: IKeyVal
    vesselNetRegisterTonnage?: number
    vesselGrossRegisterTonnage?: number
    vesselLengthOverall?: number
    vesselDailyHirePerTc?: number
    vesselCifValue?: number
    materialsCifValue?: number
    services?: Array<IOrderService>
    attachments?: Array<IOrderAttachment>
    crewServicesPresent: boolean
    portServicesPresent: boolean
    materialsServicesPresent: boolean
    portComments: string
    crewComments: string
    hsCode: string
    materialsComments: string
}

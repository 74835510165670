import useStyles from './index.styles'

import React, { useCallback, useMemo, useState } from 'react'

import { Button, Paper, Stack, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { IReactiveDialog, useDialog } from 'hooks/useDialog'
import CompanyForm, { ICompanyForm } from 'components/form/CompanyForm'
import ConfirmationDialog, {
    IConfirmationDialog,
} from 'components/common/ConfirmationDialog'
import { useUser } from 'hooks/useUser'
import Plus from 'assets/images/icons/Plus'
import CompaniesList from 'components/pages/admin/Contacts/CompaniesList'
import AdminList from 'components/pages/admin/Contacts/AdminList'
import AdminForm, { IAdminForm } from 'components/form/AdminForm'

enum PageTabs {
    COMPANIES = 'Companies',
    ADMINS = 'Administrators',
}

const Contacts = (): JSX.Element => {
    const classes = useStyles()
    const { user } = useUser()
    const { open, close } = useDialog()
    const [tabValue, setTabValue] = useState<PageTabs>(PageTabs.COMPANIES)

    const pageTitle: string = useMemo(() => {
        return tabValue === PageTabs.COMPANIES ? 'Companies' : 'Administrators'
    }, [tabValue])
    const openConfirmationDialog = useCallback((): void => {
        open({
            component: ConfirmationDialog,
            props: {
                onConfirm: close,
                onCancel: close,
            },
            options: {
                title: 'Confirm action',
                onClose: close,
            },
        } as IReactiveDialog<IConfirmationDialog>)
    }, [open, close])

    const openAdminForm = useCallback(
        (userId?: string): void => {
            open({
                component: AdminForm,
                props: {
                    onSuccess: close,
                    onClose: close,
                    id: userId,
                },
                options: {
                    title: userId ? 'Manage Admin’s Profile' : 'Add admin',
                    onClose: close,
                },
            } as IReactiveDialog<IAdminForm>)
        },
        [open, close]
    )

    const onAdminAction = (id: string, action = 'edit'): void => {
        switch (action) {
            case 'delete':
                openConfirmationDialog()
                break
            case 'edit':
                openAdminForm(id)
                break
            default:
                break
        }
    }

    const openCompanyForm = useCallback(
        (companyId?: string): void => {
            open({
                component: CompanyForm,
                props: {
                    onSuccess: close,
                    onClose: close,
                    id: companyId,
                },
                options: {
                    onClose: close,
                },
            } as IReactiveDialog<ICompanyForm>)
        },
        [open, close]
    )

    const onCompanyAction = (id: string, action = 'edit'): void => {
        switch (action) {
            case 'delete':
                openConfirmationDialog()
                break
            case 'edit':
                openCompanyForm(id)
                break
            default:
                break
        }
    }

    const onTabChange = (
        event: React.SyntheticEvent,
        activeTab: PageTabs
    ): void => {
        setTabValue(activeTab)
    }

    return (
        <Stack className={classes.container}>
            <Paper className={classes.content}>
                <Stack className={classes.head}>
                    <Stack className={classes.title}>{pageTitle}</Stack>
                    <Stack className={classes.buttons}>
                        {tabValue === PageTabs.COMPANIES ? (
                            <Button
                                onClick={(): void => openCompanyForm()}
                                color="primary"
                                startIcon={<Plus />}
                            >
                                Add New Company
                            </Button>
                        ) : (
                            <Button
                                onClick={(): void => openAdminForm()}
                                color="primary"
                                startIcon={<Plus />}
                            >
                                Add New Admin
                            </Button>
                        )}
                    </Stack>
                </Stack>
                <TabContext value={tabValue}>
                    <TabList onChange={onTabChange}>
                        <Tab
                            label={PageTabs.COMPANIES}
                            value={PageTabs.COMPANIES}
                        />
                        <Tab label={PageTabs.ADMINS} value={PageTabs.ADMINS} />
                    </TabList>
                    <TabPanel
                        className={classes.tabContent}
                        value={PageTabs.COMPANIES}
                    >
                        <CompaniesList onAction={onCompanyAction} />
                    </TabPanel>
                    <TabPanel
                        className={classes.tabContent}
                        value={PageTabs.ADMINS}
                    >
                        <AdminList onAction={onAdminAction} />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Stack>
    )
}

export default Contacts

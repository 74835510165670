import useStyles from './index.styles'

import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import AuthorizationPage from 'components/common/AuthorizationPage'
import LoginForm from 'components/form/LoginForm'
import { IAuthResponse } from 'hooks/useAuth'
import { paths, resolveHomepageByRole } from 'config/routes'
import { useUser } from 'hooks/useUser'
import { TokenStorage } from 'services'

const Login = (): JSX.Element => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { user } = useUser()

    useEffect(() => {
        if (TokenStorage.getToken() && user) {
            navigate(resolveHomepageByRole(user?.role.key))
        }
    }, [user])

    const onLogin = (response: IAuthResponse): void => {
        navigate(resolveHomepageByRole(response?.user?.role.key))
    }
    const onForgotPassword = (): void => {
        navigate(paths.forgotPassword)
    }

    return (
        <AuthorizationPage title="Sign in">
            <div className={classes.container}>
                <LoginForm
                    onLogin={onLogin}
                    onForgotPassword={onForgotPassword}
                />
            </div>
        </AuthorizationPage>
    )
}

export default Login

import React from 'react'

const SuccessIcon = (): React.ReactElement => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="18" cy="18" r="18" fill="#153B64" />
            <g clipPath="url(#clip0_622_3386)">
                <path
                    d="M15.0001 22.2001L10.8001 18.0001L9.40005 19.4001L15.0001 25.0001L27.0001 13.0001L25.6001 11.6001L15.0001 22.2001Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_622_3386">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(6.00006 6)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default SuccessIcon

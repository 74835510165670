import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    container: {
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        justifyContent: 'stretch',
        backgroundColor: 'transparent',
        overflow: 'hidden',
        gap: '16px',
    },
})

export default useStyles

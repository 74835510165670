import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

import { draftColor, inProgressColor } from 'theme'

const useStyles = makeStyles((theme: Theme) => ({
    status: {
        ...theme.typography.regular16,
        flexDirection: 'row',
        minHeight: '48px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px 16px',
        borderRadius: '4px',
        flexGrow: '1',
    },
    small: {
        padding: '4px 8px',
        minHeight: '32px',
    },
    statusDraft: {
        backgroundColor: draftColor,
        color: theme.colors.white,
    },
    statusInProgress: {
        backgroundColor: inProgressColor,
        color: theme.colors.white,
    },
    statusFinished: {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
    },
    statusCancelled: {
        backgroundColor: theme.colors.grey20,
    },
}))

export default useStyles

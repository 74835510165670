import React from 'react'

import { useTheme } from '@mui/material'

interface IPlus {
    color?: string
}

const Plus = (props: IPlus): React.ReactElement => {
    const theme = useTheme()
    const { color = theme.colors.white } = props
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                fill={color}
            />
        </svg>
    )
}
export default Plus

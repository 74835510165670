import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '450px',
    },
    title: {
        ...theme.typography.bold14,
        textTransform: 'uppercase',
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '12px',
    },
    content: {
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'flex-start',
    },
    checkboxItem: {
        '& label': {
            alignItems: 'center',
        },
        '& .MuiFormControlLabel-label': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
        },
    },
}))

export default useStyles

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexGrow: 0,
        width: '128px',
        height: '128px',
        position: 'relative',
        marginBottom: '12px',
    },
    preview: {
        width: '128px',
        height: '128px',
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        border: `1px solid ${theme.colors.grey20}`,
        overflow: 'hidden',
    },
    image: {
        minWidth: '100%',
        minHeight: '100%',
        objectFit: 'cover',
    },
    closeIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        width: '24px',
        height: '24px',
        zIndex: 999,
    },
    dropzone: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: `1px dashed ${theme.colors.grey20}`,
        cursor: 'pointer',
        borderRadius: '3px',
        width: '100%',
        height: '100%',
        '& :hover': {
            borderColor: theme.colors.grey60,
        },
        '& svg': {
            width: '80px',
            height: '80px',
        },
    },
    dropzoneTitle: {
        ...theme.typography.bold16,
        marginBottom: '8px',
    },
    dropzoneCaption: {
        ...theme.typography.regular12,
        color: theme.colors.grey60,
    },
    dropzoneLink: {
        color: theme.colors.grey100,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}))

export default useStyles

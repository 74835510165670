import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        justifyContent: 'stretch',
        backgroundColor: 'transparent',
        overflow: 'hidden',
        gap: '16px',
    },
    crumbs: {
        ...theme.typography.medium16,
        flexDirection: 'row',
        flexBasis: '48px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: theme.colors.primary,
        textTransform: 'uppercase',
    },
    page: {
        flexDirection: 'row',
        flexGrow: 1,
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'stretch',
        backgroundColor: 'transparent',
        overflow: 'hidden',
        gap: '16px',
    },
    profile: {
        display: 'flex',
        flexBasis: '320px',
        flexGrow: 0,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        maxHeight: '100%',
        padding: '40px 52px',
    },
    head: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '32px',
    },
    title: {
        ...theme.typography.medium20,
    },
    buttons: {
        flexDirection: 'row',
        gap: '16px',
    },
    tabs: {
        marginBottom: '32px',
    },
    tabContent: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        padding: 0,
    },
}))

export default useStyles

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: 'flex',
        overflow: 'hidden',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '& svg.MuiSvgIcon-root': {
            width: '24px',
            height: '24px',
        },
    },
    titleText: {
        ...theme.typography.medium16,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

export default useStyles

import useStyles from './index.styles'

import React, { useCallback, useMemo } from 'react'

import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

import { IUser } from 'models/user.model'
import ContactName from 'assets/images/icons/ContactName'
import ContactPhone from 'assets/images/icons/ContactPhone'
import ContactEmail from 'assets/images/icons/ContactEmail'
import { formatUserName } from 'utils/user.utils'
import { IReactiveDialog, useDialog } from 'hooks/useDialog'
import { ICompany } from 'models/companyUser.model'
import CompanyForm, { ICompanyForm } from 'components/form/CompanyForm'
import ContactCompany from 'assets/images/icons/ContactCompany'
import { useUser } from 'hooks/useUser'
import { UserRole } from 'config/user'
import { paths } from 'config/routes'
import { EOrderStatus } from 'models/order.model'

interface ICompanyAside {
    company?: ICompany
}
const CompanyAside = ({ company }: ICompanyAside): JSX.Element => {
    const classes = useStyles()
    const { user } = useUser()
    const { open, close } = useDialog()

    const companyUser: IUser | undefined = useMemo(() => {
        if (company?.clients) return company.clients[0]
        return undefined
    }, [company])
    const openCompanyForm = useCallback(
        (companyId?: string): void => {
            open({
                component: CompanyForm,
                props: {
                    onSuccess: close,
                    onClose: close,
                    id: companyId,
                },
                options: {
                    onClose: close,
                },
            } as IReactiveDialog<ICompanyForm>)
        },
        [open, close]
    )
    const ordersLink = (): string =>
        user?.role.key === UserRole.ADMIN ? paths.admin.home : paths.client.home

    const activeOrdersLink = (): string => {
        return `${ordersLink()}?company_id=${company?.id || ''}&status=${
            EOrderStatus.DRAFT
        },${EOrderStatus.IN_PROGRESS}`
    }
    const totalOrdersLink = (): string => {
        return `${ordersLink()}?company_id=${company?.id || ''}`
    }
    return (
        <Stack className={classes.container}>
            <Stack className={classes.box}>
                <Stack className={classes.row}>
                    <Stack className={classes.icon}>
                        <ContactCompany />
                    </Stack>
                    <Stack className={classes.titleHead}>
                        {company?.title || ''}
                    </Stack>
                </Stack>
                <Stack className={classes.row}>
                    <Stack className={classes.icon} />
                    <Stack className={classes.title}>
                        <Link to={activeOrdersLink()}>
                            {company?.activeOrders || 0} active orders,
                        </Link>
                        <Link to={totalOrdersLink()}>
                            {company?.totalOrders || 0} total
                        </Link>
                    </Stack>
                </Stack>
            </Stack>
            <Stack className={classes.box}>
                <Stack className={classes.row}>
                    <Stack className={classes.icon}>
                        <ContactName />
                    </Stack>
                    <Stack className={classes.title}>
                        {formatUserName(companyUser)}
                    </Stack>
                </Stack>
                <Stack className={classes.row}>
                    <Stack className={classes.icon}>
                        <ContactPhone />
                    </Stack>
                    <Stack className={classes.title}>
                        {companyUser?.phone || 'Not set'}
                    </Stack>
                </Stack>
                <Stack className={classes.row}>
                    <Stack className={classes.icon}>
                        <ContactEmail />
                    </Stack>
                    <Stack className={classes.title}>
                        {companyUser?.email || ''}
                    </Stack>
                </Stack>
            </Stack>
            {user?.role.key === UserRole.ADMIN && (
                <Stack className={classes.box}>
                    <Stack className={classes.columnBlock}>
                        <Stack className={classes.title}>Client margin</Stack>
                        <Stack className={classes.title}>
                            {company?.margin || '0'}%
                        </Stack>
                    </Stack>
                </Stack>
            )}
            {user?.role.key === UserRole.ADMIN && (
                <Stack className={classes.box}>
                    <Stack className={classes.row}>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.button}
                            onClick={(): void => openCompanyForm(company?.id)}
                        >
                            Manage Profile
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
}
export default CompanyAside

import useStyles from './index.styles'

import React from 'react'

import { Stack } from '@mui/material'

import { IOrder } from 'models/order.model'
import { formatDate } from 'utils/date.utils'
import { formatUserName } from 'utils/user.utils'
import ContactAvatar from 'assets/images/icons/ContactAvatar'
import { theme } from 'theme'

interface IOrderSummary {
    order?: IOrder
}

const OrderSummary = ({ order }: IOrderSummary): JSX.Element => {
    const classes = useStyles()

    return (
        <Stack className={classes.customerInfo}>
            <Stack className={classes.customerInfoBlock}>
                <Stack className={classes.label}>Customer:</Stack>
                <Stack className={classes.filledTitle}>
                    <ContactAvatar color={theme.colors.white} />
                    {formatUserName(order?.client)}
                </Stack>
            </Stack>
            {order?.portServicesPresent && (
                <>
                    <Stack className={classes.customerInfoBlock}>
                        <Stack className={classes.label}>Vessel:</Stack>
                        <Stack className={classes.filledTitle}>
                            {order?.vesselName || ''}
                        </Stack>
                    </Stack>
                    <Stack className={classes.customerInfoBlock}>
                        <Stack className={classes.label}>ETD:</Stack>
                        <Stack className={classes.title}>
                            {`${formatDate(order?.etd)} (${
                                order?.origin?.val || ''
                            })`}
                        </Stack>
                    </Stack>
                    <Stack className={classes.customerInfoBlock}>
                        <Stack className={classes.label}>ETA:</Stack>
                        <Stack className={classes.title}>
                            {`${formatDate(order?.eta)} (${
                                order?.destination?.val || ''
                            })`}
                        </Stack>
                    </Stack>
                </>
            )}
        </Stack>
    )
}
export default OrderSummary

import { useQuery } from '@apollo/client'

import { IKeyVal } from 'types/common.types'
import OnboardingAPI from 'api/onboarding.api'
import { IEdgeNode } from 'models/api.model'

interface IOnboarding {
    documentsDescription: string
    servicesDescription: string
    group: IKeyVal
    filename: string
    url: string
}

export const useOnboarding = (): Array<IOnboarding> => {
    const { data } = useQuery(OnboardingAPI.onboardingList(), {
        errorPolicy: 'all',
    })
    let items

    if (data?.onboardingList) {
        items = data.onboardingList.edges.map(
            (edge: IEdgeNode<IOnboarding>) => edge.node
        )
    }

    return items || []
}

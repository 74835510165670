import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
        overflowX: 'hidden',
        overflowY: 'auto',
        gap: '16px',
    },
    orderTitle: {
        ...theme.typography.regular16,
        alignItems: 'center',
    },
    orderSubtitle: {
        ...theme.typography.bold20,
        textTransform: 'uppercase',
        alignItems: 'center',
        marginBottom: '40px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            marginBottom: '16px',
        },
    },
    groupTitle: {
        ...theme.typography.bold20,
        textTransform: 'uppercase',
        alignItems: 'center',
    },
    categories: {
        flexDirection: 'column',
        gap: '40px',
        position: 'relative',
        minHeight: '40px',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            gap: '16px',
        },
    },
    category: {
        flexDirection: 'column',
        gap: '32px',
        borderBottom: `1px solid ${theme.colors.systemShadow}`,
        padding: '24px 0',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            padding: '12px 0',
        },
    },
    categoryTitle: {
        ...theme.typography.medium16,
    },
    head: {
        flexDirection: 'row',
        justifyContent: 'stretch',
        alignItems: 'center',
        gap: '16px',
        '&>div': {
            ...theme.typography.bold14,
            textTransform: 'uppercase',
        },
    },

    service: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'stretch',
        gap: '16px',
    },
    titleHead: {
        flexGrow: 1,
    },
    rateHead: {
        flex: '0 0 172px',
        alignItems: 'flex-end',
    },
    unitHead: {
        flex: '0 0 60px',
        alignItems: 'center',
    },
    amountHead: {
        flex: '0 0 120px',
        alignItems: 'flex-end',
    },
    title: {
        flexGrow: 1,
        ...theme.typography.regular16,
    },
    rate: {
        flex: '0 0 172px',
        textAlign: 'end',
        textAlignLast: 'end',
    },
    unit: {
        ...theme.typography.regular16,
        flex: '60px 0 0',
        alignItems: 'center',
    },
    amount: {
        ...theme.typography.bold14,
        flex: '120px 0 0',
        alignItems: 'flex-end',
    },
    summary: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        '&>div': {
            ...theme.typography.bold20,
        },
    },
    serviceMobile: {
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },
    amountMobile: {
        ...theme.typography.bold14,
    },
    titleHeadMobile: {
        flexGrow: 1,
    },
    amountHeadMobile: {
        flex: '120px 0 0',
        alignItems: 'flex-end',
    },
    titleMobile: {},
    infoMobile: {
        gap: '12px',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    rateMobile: {
        flexDirection: 'row',
        flexGrow: '1',
    },
    summaryTitle: {
        textTransform: 'uppercase',
    },
    summaryAmount: {},
}))

export default useStyles

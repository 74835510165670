import useStyles from './index.styles'

import React, { useCallback, useEffect, useMemo } from 'react'

import { useFormik } from 'formik'
import Stack from '@mui/material/Stack'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'

import { LabeledTextField } from 'components/inputs'
import { useUser, useUserApi } from 'hooks/useUser'
import validationSchema, {
    initialValues,
    IUserFormValues,
} from './index.schema'
import Loader from 'components/common/Loader'
import { IUser } from 'models/user.model'
import { UserRole } from 'config/user'
import { useAuth } from 'hooks/useAuth'
import GroupHorizontal from 'components/common/GroupHorizontal'
import { ICompany } from 'models/companyUser.model'

export interface IUserForm {
    id?: string
    company?: ICompany
    onClose?: () => void
    onCancel?: () => void
}

const UserForm = ({
    id,
    company,
    onClose,
    onCancel,
}: IUserForm): React.ReactElement => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const { user: loggedInUser } = useUser()
    const {
        user: loadedUser,
        getUser,
        addUser,
        updateUser,
        deactivateUser,
        isLoading,
    } = useUserApi()
    const { reinviteUser } = useAuth()
    const user: IUser | undefined = useMemo(() => {
        if (!id) {
            return undefined
        }
        return loadedUser
    }, [id, loadedUser])

    useEffect(() => {
        if (id) {
            getUser(id)
        }
    }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

    const onInviteUser = useCallback(() => {
        if (user) {
            reinviteUser(user.email).then(() => {
                enqueueSnackbar('Invitation Sent')
            })
        }
    }, [user, enqueueSnackbar, reinviteUser])

    const onDeactivateUser = useCallback(() => {
        if (user) {
            deactivateUser(user.id).then(response => {
                if (response.success) {
                    enqueueSnackbar('User deactivated')
                    if (onClose) onClose()
                } else {
                    enqueueSnackbar(response.error, { variant: 'error' })
                }
            })
        }
    }, [user, enqueueSnackbar])

    const formValues: IUserFormValues = useMemo(() => {
        if (user) {
            return {
                userId: user.id,
                companyId: user.company?.id || '',
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                email: user.email || '',
                phone: user.phone || '',
                margin: user.company?.margin || 0,
            } as IUserFormValues
        }
        return {
            ...initialValues,
            companyId: company?.id || '',
            margin: company?.margin || 0,
        } as IUserFormValues
    }, [user, company])

    const onUserSaved = (): void => {
        if (onClose) onClose()
    }
    const formik = useFormik({
        initialValues: formValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values: IUserFormValues) => {
            if (values.userId) {
                updateUser({ ...values })
                    .then(response => {
                        if (response.success) {
                            enqueueSnackbar('User saved')
                            onUserSaved()
                        } else {
                            enqueueSnackbar('User not saved', {
                                variant: 'error',
                            })
                        }
                    })
                    .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' })
                    })
            } else {
                addUser({ ...values })
                    .then(response => {
                        if (response.success) {
                            enqueueSnackbar('User created')
                            onUserSaved()
                        } else {
                            enqueueSnackbar('User not saved', {
                                variant: 'error',
                            })
                        }
                    })
                    .catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' })
                    })
            }
        },
    })

    const changeUserPhone = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { value } = event.target
        formik.setFieldValue(
            'phone',
            !value || value.indexOf('+') === 0 ? value : `+${value}`
        )
    }
    const isInviteBtnVisible: boolean = useMemo(() => {
        if (!user) return false
        if (loggedInUser?.role.key !== UserRole.ADMIN) return false
        return user.id !== loggedInUser.id
    }, [user, loggedInUser])
    return (
        <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Stack className={classes.group}>
                <LabeledTextField
                    id="first-name-input"
                    name="firstName"
                    label="First name"
                    placeholder="Enter first name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                    }
                    helperText={
                        formik.touched.firstName && formik.errors.firstName
                    }
                />
                <LabeledTextField
                    id="last-name-input"
                    name="lastName"
                    label="Last name"
                    placeholder="Enter last name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                    }
                    helperText={
                        formik.touched.lastName && formik.errors.lastName
                    }
                />
            </Stack>
            <Stack className={classes.group}>
                <LabeledTextField
                    id="email-input"
                    name="email"
                    label="E-mail Address"
                    placeholder="E-mail Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <LabeledTextField
                    id="phone-input"
                    name="phone"
                    label="Phone Number"
                    placeholder="Phone Number"
                    value={formik.values.phone}
                    onChange={changeUserPhone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                />
            </Stack>
            <GroupHorizontal>
                {/*
                {loggedInUser?.role.key === UserRole.ADMIN ? (
                    <CompanyAutocomplete
                        value={formik.values.companyId}
                        onSelect={(value): void => {
                            formik.setFieldValue('margin', value?.margin || 0)
                            formik.setFieldValue('companyId', value?.id)
                        }}
                    />
                ) : (
*/}

                <LabeledTextField
                    id="company-name-input"
                    disabled
                    name="company"
                    label="Company"
                    placeholder="Company"
                    value={user?.company?.title || company?.title || ''}
                />
                {/*
                )}
*/}
                {loggedInUser?.role.key === UserRole.ADMIN && (
                    <LabeledTextField
                        id="margin-input"
                        name="margin"
                        disabled
                        label="Client margin (%)"
                        placeholder="Client margin (%)"
                        value={formik.values.margin}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.margin &&
                            Boolean(formik.errors.margin)
                        }
                        helperText={
                            formik.touched.margin && formik.errors.margin
                        }
                    />
                )}
            </GroupHorizontal>
            <Stack className={classes.buttons}>
                {isInviteBtnVisible ? (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={(): void => onDeactivateUser()}
                    >
                        Deactivate account
                    </Button>
                ) : (
                    <Stack />
                )}
                <Stack className={classes.rightButtons}>
                    {isInviteBtnVisible && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(): void => onInviteUser()}
                        >
                            Send invite
                        </Button>
                    )}

                    {onClose && (
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={(): void => onClose()}
                        >
                            Cancel
                        </Button>
                    )}
                    {onCancel && (
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={(): void => onCancel()}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        type="submit"
                    >
                        Apply
                    </Button>
                </Stack>
            </Stack>
            {isLoading && <Loader />}
        </form>
    )
}
export default UserForm

import { useCallback, useEffect, useMemo } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'

import { IQueryParams, useExtQuery } from 'hooks/useExtQuery'
import { ITableQueryInfo } from 'types/table.types'
import { IBooleanResponse, IEdgeNode } from 'models/api.model'
import { ICompany } from 'models/companyUser.model'
import { ICompanyFormValues } from 'components/form/CompanyForm/index.schema'
import CompanyAPI from 'api/company.api'

interface ICompanyMutationResponse extends IBooleanResponse {
    company?: ICompany
}

interface IUseCompanyApi {
    company?: ICompany
    getCompany: (id: string) => Promise<ICompany>
    refetchCompany: () => void
    addCompany: (
        values: ICompanyFormValues
    ) => Promise<ICompanyMutationResponse>
    updateCompany: (
        values: ICompanyFormValues
    ) => Promise<ICompanyMutationResponse>
    isLoading: boolean
}
export const useCompanyApi = (): IUseCompanyApi => {
    const [getData, { data, loading, refetch }] = useLazyQuery(
        CompanyAPI.companyDetails(),
        {
            notifyOnNetworkStatusChange: true,
        }
    )

    const [create, { loading: addCompanyLoading }] = useMutation(
        CompanyAPI.addCompany(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'companyList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    const [update, { loading: updateCompanyLoading }] = useMutation(
        CompanyAPI.updateCompany(),
        {
            update: cache => {
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'companyList' })
                cache.gc()
            },
            notifyOnNetworkStatusChange: true,
        }
    )

    let company: ICompany | undefined

    if (data?.companyDetails) {
        company = data.companyDetails as ICompany
    }
    const getCompany = (id: string): Promise<ICompany> => {
        return getData({ variables: { id } }).then(
            response => response.data.companyDetails
        )
    }

    const refetchCompany = (): void => {
        refetch()
    }

    const addCompany = (
        values: ICompanyFormValues
    ): Promise<ICompanyMutationResponse> => {
        return create({
            variables: { input: values },
        }).then(response => {
            return response.data.addCompany
        })
    }

    const updateCompany = (
        values: ICompanyFormValues
    ): Promise<ICompanyMutationResponse> => {
        return update({
            variables: { input: values },
        }).then(response => {
            return response.data.updateCompany
        })
    }

    return {
        addCompany,
        updateCompany,
        getCompany,
        refetchCompany,
        company,
        isLoading: loading || addCompanyLoading || updateCompanyLoading,
    }
}

interface IUseCompanyList {
    companies: Array<ICompany>
    isLoading: boolean
    loadMore: () => void
    hasNextPage: boolean
    setSearch: (search?: string) => void
}

export const useCompanyList = (params?: IQueryParams): IUseCompanyList => {
    const { rows, setParam, dataIsLoading, loadMore, hasNextPage } =
        useExtQuery(
            {
                dataApi: CompanyAPI.companyList,
                dataPath: 'companyList',
                itemsPerPage: 20,
            } as ITableQueryInfo,
            params
        )
    useEffect(() => {})

    const setSearch = useCallback(
        (str?: string): void => {
            setParam('search', str || undefined)
        },
        [setParam]
    )

    const companies: Array<ICompany> = useMemo(() => {
        return rows as Array<ICompany>
    }, [rows])

    return {
        companies,
        setSearch,
        loadMore,
        hasNextPage,
        isLoading: dataIsLoading,
    }
}

interface IUseLazyCompanyList {
    getCompanies: (params?: IQueryParams) => void
    fetchMoreCompanies: () => void
    companies: Array<ICompany>
    hasNextPage: boolean
    isLoading: boolean
}

export const useLazyCompanyList = (): IUseLazyCompanyList => {
    const [getData, { data, loading, fetchMore }] = useLazyQuery(
        CompanyAPI.companyList()
    )

    let companies: Array<ICompany> = []

    const pageInfo = data?.companyList.pageInfo

    if (data?.companyList) {
        companies = data.companyList.edges.map(
            (edge: IEdgeNode<ICompany>) => edge.node
        )
    }
    const getCompanies = (params?: IQueryParams): void => {
        getData({ variables: params })
    }

    const fetchMoreCompanies = (): void => {
        if (pageInfo.hasNextPage) {
            fetchMore({ variables: { offset: companies.length } })
        }
    }

    return {
        getCompanies,
        fetchMoreCompanies,
        companies,
        isLoading: loading,
        hasNextPage: pageInfo?.hasNextPage,
    }
}

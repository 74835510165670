import useStyles from './index.styles'

import * as React from 'react'

import { Stack } from '@mui/material'

import MainNav from '../MainNav'
import { IMenuItem } from 'types/common.types'

interface IPage {
    children: React.ReactElement
    menuItems: Array<IMenuItem>
    bottomMenu: Array<IMenuItem>
}

const Page = ({ children, menuItems, bottomMenu }: IPage): JSX.Element => {
    const classes = useStyles()
    return (
        <Stack className={classes.container}>
            <Stack className={classes.wrapper}>
                <Stack className={classes.mainMenuWrapper}>
                    <MainNav menuItems={menuItems} bottomMenu={bottomMenu} />
                </Stack>
                <Stack className={classes.pageWrapper}>{children}</Stack>
            </Stack>
        </Stack>
    )
}
export default Page

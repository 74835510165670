import { IUser } from 'models/user.model'

export const formatUserName = (user?: IUser, defaultName = ''): string => {
    if (!user) return defaultName
    const parts: Array<string> = [
        user.firstName || '',
        user.lastName || '',
    ].filter(val => !!val)
    return parts.length > 0 ? parts.join(' ') : defaultName
}

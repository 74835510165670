import useStyles from './index.styles'

import React, { useMemo } from 'react'

import { Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { EOrderStatus, IOrder } from 'models/order.model'
import { formatDateTime } from 'utils/date.utils'
import OrderStatus from 'components/common/OrderStatus'
import CopyIcon from 'assets/images/icons/CopyIcon'
import { useOrderApi } from 'hooks/useOrder'
import { useUser } from 'hooks/useUser'
import { UserRole } from 'config/user'
import { paths } from 'config/routes'
import { prepareUrl } from 'utils/route.utils'
import CloseIcon from 'assets/images/icons/CloseIcon'
import useScreenSize from 'hooks/useScreenSize'
import { theme } from 'theme'

interface IOrderHeader {
    order?: IOrder
}

const OrderHeader = ({ order }: IOrderHeader): JSX.Element => {
    const classes = useStyles()
    const { user } = useUser()
    const { isTablet } = useScreenSize()
    const { enqueueSnackbar } = useSnackbar()
    const { cloneOrder, changeStatus } = useOrderApi()
    const navigate = useNavigate()
    const orderTitle: string = useMemo(() => {
        if (order) {
            return `Order #${order.number}`
        }
        return 'New Order'
    }, [order])

    const isOrderCanCancel: boolean = useMemo(() => {
        if (order) {
            if (
                [EOrderStatus.DRAFT, EOrderStatus.IN_PROGRESS].includes(
                    order.status.key
                )
            )
                return true
        }
        return false
    }, [order])
    const onCancelOrder = (): void => {
        if (order) {
            changeStatus(order.id, EOrderStatus.CANCELLED.toString()).then(
                () => {
                    enqueueSnackbar('Order cancelled', { variant: 'success' })
                }
            )
        }
    }
    const onCloneOrder = (): void => {
        if (order) {
            cloneOrder(order.id).then(response => {
                const getOrderUrl = (): string => {
                    if (user?.role.key === UserRole.ADMIN) {
                        return paths.admin.editOrder
                    }
                    return paths.client.editOrder
                }
                if (response.order) {
                    enqueueSnackbar('Order cloned', { variant: 'success' })
                    navigate(
                        prepareUrl(getOrderUrl(), {
                            orderId: response.order.id,
                        })
                    )
                }
            })
        }
    }
    return (
        <Stack className={classes.container}>
            <Stack className={classes.LeftBlock}>
                {order && (
                    <Stack className={classes.orderDate}>
                        {isTablet && <OrderStatus order={order} isSmall />}
                        {formatDateTime(order.createdAt)}
                    </Stack>
                )}
                <Stack className={classes.title}>
                    {orderTitle}
                    {order && !isTablet && <OrderStatus order={order} />}
                </Stack>
            </Stack>
            <Stack className={classes.buttons}>
                {isOrderCanCancel && (
                    <Button
                        fullWidth={isTablet}
                        color="secondary"
                        variant="outlined"
                        onClick={onCancelOrder}
                        startIcon={<CloseIcon />}
                    >
                        Cancel
                    </Button>
                )}
                {order && (
                    <Button
                        fullWidth={isTablet}
                        color="secondary"
                        variant="outlined"
                        onClick={onCloneOrder}
                        startIcon={<CopyIcon color={theme.colors.secondary} />}
                    >
                        Copy Order
                    </Button>
                )}
            </Stack>
        </Stack>
    )
}
export default OrderHeader

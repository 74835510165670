import useStyles from './index.styles'

import React, { useMemo, useState } from 'react'

import { Button, Drawer, IconButton, Paper, Stack } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'

import TextField from 'components/inputs/TextField'
import { CheckboxField, SelectShrinkField } from 'components/inputs'
import { useUser, useUserList } from 'hooks/useUser'
import { UserRole } from 'config/user'
import { IKeyVal, TRender } from 'types/common.types'
import { formatUserName } from 'utils/user.utils'
import { useConfig } from 'hooks/useConfig'
import CompanyAutocomplete from 'components/common/CompanyAutocomplete'
import { ICompany } from 'models/company.model'
import { useVesselList } from 'hooks/useVessel'
import useScreenSize from 'hooks/useScreenSize'
import FilterIcon from 'assets/images/icons/FilterIcon'

export interface IOrderFilterValues {
    search?: string
    administratorId?: string
    vesselId?: string
    days?: string
    status?: string
    destination?: string
    companyId?: string
    clientId?: string
    sortBy?: string
    sortReverse?: boolean
}
interface IOrderFilters {
    filters?: IOrderFilterValues
    onChange?: (values: IOrderFilterValues) => void
}

const OrderFilters = ({ filters, onChange }: IOrderFilters): JSX.Element => {
    const classes = useStyles()
    const { user } = useUser()
    const { users: administrators } = useUserList({
        role: UserRole.ADMIN,
        itemsPerPage: 200,
    })
    const { vessels } = useVesselList({ itemsPerPage: 200 })
    const { statuses, destinationTypes } = useConfig()
    const { isTablet } = useScreenSize()
    const filterRef = React.useRef<HTMLDivElement | null>(null)
    const [anchorFilter, setAnchorFilter] = useState<null | HTMLDivElement>(
        null
    )
    const onOpenFilter = (): void => {
        setAnchorFilter(filterRef.current)
    }

    const toggleFilter = (): void => {
        setAnchorFilter(currentState =>
            currentState ? null : filterRef.current
        )
    }

    const statusOptions: Array<IKeyVal<string>> = useMemo(() => {
        return [{ key: '', val: 'Select All' } as IKeyVal<string>, ...statuses]
    }, [statuses])

    const destinationOptions: Array<IKeyVal<string>> = useMemo(() => {
        return [
            { key: '', val: 'Select All' } as IKeyVal<string>,
            ...destinationTypes,
        ]
    }, [destinationTypes])

    const administratorOption: Array<IKeyVal<string>> = useMemo(() => {
        const response: Array<IKeyVal<string>> = [
            { key: '', val: 'Select All' } as IKeyVal<string>,
        ]
        administrators.forEach(administrator => {
            response.push({
                key: administrator.id,
                val: formatUserName(administrator),
            } as IKeyVal<string>)
        })
        return response
    }, [administrators])

    const vesselOption: Array<IKeyVal<string>> = useMemo(() => {
        const response: Array<IKeyVal<string>> = [
            { key: '', val: 'Select All' } as IKeyVal<string>,
        ]
        vessels.forEach(vessel => {
            response.push({
                key: vessel.id,
                val: vessel.name,
            } as IKeyVal<string>)
        })
        return response
    }, [vessels])

    const sortOptions: Array<IKeyVal<string>> = useMemo(() => {
        const result: Array<IKeyVal<string>> = [
            {
                key: 'created_at',
                val: 'Created At',
            },
            {
                key: 'eta',
                val: 'ETA',
            },
            {
                key: 'number',
                val: 'Number',
            },
            {
                key: 'vessel_name',
                val: 'Vessel',
            },
            {
                key: 'status',
                val: 'Status',
            },
            {
                key: 'destination',
                val: 'Destination',
            },
        ]

        if (user?.role.key === UserRole.ADMIN) {
            result.push({
                key: 'company',
                val: 'Company',
            })
        }
        return result
    }, [user])
    const dateOptions = [
        { key: '', val: 'Select All' },
        { key: '7', val: '7 days' },
        { key: '15', val: '15 days' },
        { key: '30', val: '30 days' },
    ]
    const onSearchChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (onChange) onChange({ ...filters, search: event.target.value || '' })
    }

    const onStatusChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (onChange) onChange({ ...filters, status: event.target.value || '' })
    }

    const onCompanyChange = (value: ICompany | null): void => {
        if (onChange) onChange({ ...filters, companyId: value?.id || '' })
    }

    const onDestinationChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (onChange)
            onChange({ ...filters, destination: event.target.value || '' })
    }

    const onSortChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (onChange) onChange({ ...filters, sortBy: event.target.value || '' })
    }

    const onSortReverse = (): void => {
        if (onChange)
            onChange({ ...filters, sortReverse: !filters?.sortReverse })
    }

    const onDaysChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (onChange) onChange({ ...filters, days: event.target.value || '' })
    }

    const onAdministratorChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (onChange)
            onChange({ ...filters, administratorId: event.target.value || '' })
    }

    const onVesselChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (onChange)
            onChange({ ...filters, vesselId: event.target.value || '' })
    }
    const filtersContent: TRender = (
        <>
            <Stack className={classes.filterBlock}>
                <Stack className={classes.filterItem}>
                    <TextField
                        placeholder="Number"
                        type="search"
                        value={filters?.search || ''}
                        onChange={onSearchChange}
                    />
                </Stack>
                <Stack className={classes.filterItem}>
                    <SelectShrinkField
                        label="Administrator"
                        value={filters?.administratorId || ''}
                        onChange={onAdministratorChange}
                        options={administratorOption}
                    />
                </Stack>
                {user?.role.key === UserRole.ADMIN && (
                    <Stack className={classes.filterItem}>
                        <CompanyAutocomplete
                            label="Company"
                            value={filters?.companyId || ''}
                            onSelect={onCompanyChange}
                            shrink
                        />
                    </Stack>
                )}

                <Stack className={classes.filterItem}>
                    <SelectShrinkField
                        label="Date"
                        value={filters?.days || ''}
                        onChange={onDaysChange}
                        options={dateOptions}
                    />
                </Stack>
                <Stack className={classes.filterItem}>
                    <SelectShrinkField
                        label="Status"
                        value={filters?.status || ''}
                        onChange={onStatusChange}
                        options={statusOptions}
                    />
                </Stack>
                <Stack className={classes.filterItem}>
                    <SelectShrinkField
                        label="Vessel name"
                        value={filters?.vesselId || ''}
                        onChange={onVesselChange}
                        options={vesselOption}
                    />
                </Stack>

                <Stack className={classes.filterItem}>
                    <SelectShrinkField
                        label="Destination"
                        value={filters?.destination || ''}
                        onChange={onDestinationChange}
                        options={destinationOptions}
                    />
                </Stack>
            </Stack>
            <Stack className={classes.filterBlock}>
                <Stack className={classes.filterItem}>
                    <SelectShrinkField
                        label="Sort By"
                        value={filters?.sortBy || ''}
                        onChange={onSortChange}
                        options={sortOptions}
                    />
                </Stack>
                <Stack className={classes.filterItem}>
                    <CheckboxField
                        label="Reverse"
                        checked={filters?.sortReverse || false}
                        onChange={onSortReverse}
                    />
                </Stack>
            </Stack>
        </>
    )

    return isTablet ? (
        <>
            <Stack className={classes.mobileContainer} ref={filterRef}>
                <TextField
                    className={classes.numberSearch}
                    placeholder="Number"
                    type="search"
                    value={filters?.search || ''}
                    onChange={onSearchChange}
                />
                <Button
                    onClick={(): void => onOpenFilter()}
                    color="secondary"
                    startIcon={<FilterIcon />}
                >
                    Filter Search
                </Button>
            </Stack>
            <Drawer
                anchor="top"
                open={!!anchorFilter}
                onClose={toggleFilter}
                className={classes.filterPopup}
            >
                <Stack className={classes.mobileFilter}>
                    <Stack className={classes.filterTitle}>
                        <IconButton onClick={toggleFilter}>
                            <CloseOutlined />
                        </IconButton>
                        Filter Search
                    </Stack>
                    {filtersContent}
                    <Stack className={classes.filterBlock}>
                        <Button onClick={toggleFilter} color="secondary">
                            Search
                        </Button>
                    </Stack>
                </Stack>
            </Drawer>
        </>
    ) : (
        <Paper className={classes.filters}>
            <Stack className={classes.container}>{filtersContent}</Stack>
        </Paper>
    )
}
export default OrderFilters

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
    },
})

export default useStyles

const RadioChecked = (): JSX.Element => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="8" cy="8" r="7" fill="none" stroke="#2E2E8E" />
        <circle
            cx="8"
            cy="8"
            r="1"
            fill="none"
            stroke="#2E2E8E"
            strokeWidth="4"
        />
    </svg>
)

export default RadioChecked

import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    customerInfo: {
        flexDirection: 'row',
        gap: '32px',
    },
    customerInfoBlock: {
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
    },
    label: {
        ...theme.typography.regular16,
    },
    filledTitle: {
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
        ...theme.typography.regular16,
        padding: '8px 10px',
        backgroundColor: theme.colors.secondary,
        borderRadius: '4px',
        color: theme.colors.white,
    },
    title: {
        ...theme.typography.regular16,
    },
}))

export default useStyles

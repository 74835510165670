import useStyles from './index.styles'

import React, { SyntheticEvent, useEffect, useMemo } from 'react'

import { Autocomplete, TextField, TextFieldProps } from '@mui/material'

import { LabeledTextField } from 'components/inputs'
import { ICompany } from 'models/companyUser.model'
import { useCompanyList } from 'hooks/useCompany'

interface ICompanyAutocomplete {
    label?: string
    value?: string
    onSelect: (value: ICompany | null) => void
    shrink?: boolean
    disabled?: boolean
    error?: boolean
    helperText?: string | boolean
}

const CompanyAutocomplete = (props: ICompanyAutocomplete): JSX.Element => {
    const {
        value = '',
        onSelect,
        shrink,
        label = 'Select company',
        disabled,
        error,
        helperText,
    } = props
    const classes = useStyles()
    const { companies, setSearch } = useCompanyList({ itemsPerPage: 1000 })
    const [inputValue, setInputValue] = React.useState('')
    const company: ICompany | null = useMemo(() => {
        return companies.find(item => item.id === value) || null
    }, [value, companies])

    useEffect(() => {
        setSearch(inputValue || '')
    }, [inputValue])

    if (disabled) {
        return shrink ? (
            <TextField
                fullWidth
                disabled
                value={company?.title || ''}
                FormHelperTextProps={{
                    variant: 'standard',
                }}
                label={label}
            />
        ) : (
            <LabeledTextField
                fullWidth
                disabled
                value={company?.title || ''}
                error={error}
                helperText={helperText}
                label={label}
            />
        )
    }

    return (
        <Autocomplete
            value={company}
            inputValue={inputValue}
            fullWidth
            className={classes.container}
            onInputChange={(event, newInputValue): void => {
                setInputValue(newInputValue)
            }}
            renderInput={(params: TextFieldProps): JSX.Element =>
                shrink ? (
                    <TextField
                        {...params}
                        fullWidth
                        FormHelperTextProps={{
                            variant: 'standard',
                        }}
                        label={label}
                    />
                ) : (
                    <LabeledTextField
                        {...params}
                        error={error}
                        helperText={helperText}
                        label={label}
                    />
                )
            }
            getOptionLabel={(item: ICompany): string => item.title || ''}
            filterOptions={(
                options: Array<ICompany>,
                params
            ): Array<ICompany> => {
                return options.filter(option =>
                    option.title
                        .toLocaleLowerCase()
                        .includes(params.inputValue.toLocaleLowerCase())
                )
            }}
            onChange={(
                event: SyntheticEvent<Element, Event>,
                item: ICompany | null
            ): void => onSelect(item)}
            options={companies}
        />
    )
}

export default CompanyAutocomplete

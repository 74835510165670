import { IKeyVal } from 'types/common.types'

export enum EServiceDepends {
    NRT = 1,
    TOTAL_DAYS = 2,
    DAYS_GRT = 3,
    DAYS_LOA = 4,
    GRT = 5,
    DAYS_HDTC = 6,
    CIF_VALUE = 7,
    SERVICE_FEE = 8,
    CREW_TOTAL_DAYS = 9,
    MATERIALS_CIF_VALUE = 10,
}
export enum EServiceUnit {
    FACTOR = 1,
    PERCENT = 2,
}

export enum EServiceCondition {
    GRT = 1,
    TEMPORARY_IMPORTATION = 2,
}

export interface IService {
    id: string
    title: string
    description: string
    rate: number
    required: boolean
    minCondition?: number
    maxCondition?: number
    maxValueCondition?: number
    averageCost?: number
    dependsOn: IKeyVal
    condition: IKeyVal
    movementType: IKeyVal
    unit: IKeyVal
}

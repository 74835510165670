const RadioUnchecked = (): JSX.Element => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="7.5"
            fill="white"
            stroke="#DBDCE4"
        />
    </svg>
)

export default RadioUnchecked

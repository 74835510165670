import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        '& .MuiOutlinedInput-root': {
            padding: '5px',
        },
        '& legend': {
            display: 'none',
        },
        '& fieldset': {
            top: 0,
        },
    },
}))

export default useStyles

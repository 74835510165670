import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        '& .MuiOutlinedInput-root': {
            padding: '5px',
        },
        '& legend': {
            display: 'none',
        },
        '& fieldset': {
            top: 0,
        },
        '& .MuiInputLabel-shrink': {
            display: 'none',
        },
    },
    option: {
        ...theme.typography.regular16,
        color: theme.colors.grey70,
    },
    optionEmail: {
        color: theme.colors.grey100,
    },
}))

export default useStyles

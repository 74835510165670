import useStyles from './index.styles'

import React from 'react'

import Stack from '@mui/material/Stack'
import { CheckCircle } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'

export interface IConfirmationDialog {
    title?: string
    confirmButtonText?: string
    cancelButtonText?: string
    onConfirm: () => void
    onCancel: () => void
}

const ConfirmationDialog = ({
    title = 'Are You sure?',
    confirmButtonText = 'Yes',
    cancelButtonText = 'No',
    onConfirm,
    onCancel,
}: IConfirmationDialog): JSX.Element => {
    const classes = useStyles()
    return (
        <Stack className={classes.container} gap={3}>
            <Stack className={classes.content}>
                <Stack className={classes.icon}>
                    <CheckCircle />
                </Stack>
                <Typography className={classes.text}>{title}</Typography>
            </Stack>
            <Stack className={classes.buttons}>
                <Button onClick={onCancel}>{cancelButtonText}</Button>
                <Button color="primary" onClick={onConfirm}>
                    {confirmButtonText}
                </Button>
            </Stack>
        </Stack>
    )
}

export default ConfirmationDialog

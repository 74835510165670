import React from 'react'

import { useUserList } from 'hooks/useUser'
import Table from 'components/common/Table'
import { columns } from './config'
import { ITableRowValue } from 'types/table.types'
import { UserRole } from 'config/user'

interface IClientList {
    onAction: (id: string, action?: string) => void
    companyId?: string
}
const ClientList = ({ onAction, companyId }: IClientList): JSX.Element => {
    const { users, isLoading, loadMore, hasNextPage } = useUserList({
        role: UserRole.CLIENT,
        companyId: companyId || '',
    })

    const onCellClicked = (row: ITableRowValue, action?: string): void => {
        if (onAction) onAction(row.id, action)
    }

    const onEditContact = (id: string): void => {
        if (onAction) onAction(id, 'edit')
    }

    return (
        <Table
            columns={columns}
            rows={users as unknown as Array<ITableRowValue>}
            onRowClick={onEditContact}
            isLoading={isLoading}
            onCellClicked={onCellClicked}
            loadMore={loadMore}
            hasNextPage={hasNextPage}
        />
    )
}

export default ClientList

import React, { Suspense, useMemo } from 'react'

import { useRoutes } from 'react-router-dom'

import { useUser } from 'hooks/useUser'
import Loader from 'components/common/Loader'
import { TRoute } from 'types/common.types'
import { LoadingRoute, NotFoundRoute, routes } from 'config/routes'
import { UserRole } from 'config/user'

const Routes = (): JSX.Element => {
    const { user, userIsLoading } = useUser()

    const userRole = useMemo((): UserRole | null => {
        if (!user?.role?.key) return null
        return user.role.key as UserRole
    }, [user])

    const activeRoutes = useMemo((): Array<TRoute> => {
        const filterRoutes = (
            routesArray: Array<TRoute>
        ): Array<TRoute> | undefined => {
            if (!routesArray) return undefined
            const reduced: Array<TRoute> = routesArray.reduce(
                (filtered: Array<TRoute>, layoutRoute) => {
                    const filterRoute = {
                        ...layoutRoute,
                        children: layoutRoute.children
                            ? filterRoutes(layoutRoute.children)
                            : undefined,
                    }
                    if (!filterRoute.restrict) filtered.push(filterRoute)
                    else if (
                        userRole &&
                        filterRoute.restrict?.includes(userRole)
                    )
                        filtered.push(filterRoute)
                    return filtered
                },
                []
            )
            return reduced.length ? reduced : undefined
        }
        const response: Array<TRoute> = filterRoutes(routes) || []
        if (userIsLoading) {
            response.push(LoadingRoute)
        } else {
            response.push(NotFoundRoute)
        }
        return response
    }, [userRole, userIsLoading])
    const routing = useRoutes(activeRoutes)

    return <Suspense fallback={<Loader />}>{routing}</Suspense>
}

export default Routes

import { Checkbox } from '@mui/material'

import { Radio } from 'components/inputs'
import { ITableData, ITableRowValue } from 'types/table.types'
import { TRender } from 'types/common.types'

type IRowSelector = {
    data: ITableData<ITableRowValue>
    isMultiSelect: boolean
    isSelected: boolean
    isHeader?: boolean
}

const RowSelector = ({
    data: { row, onCellClicked },
    isMultiSelect,
    isSelected,
    isHeader,
}: IRowSelector): JSX.Element => {
    const handleChange = (): void => {
        if (onCellClicked) onCellClicked(row)
    }

    const getCellValue = (): string => {
        return row.value?.toString() || ''
    }

    const renderRadioField = (): TRender => {
        if (isHeader) {
            return getCellValue()
        }
        return <Radio checked={isSelected} onChange={handleChange} />
    }

    return isMultiSelect ? (
        <Checkbox checked={isSelected} onChange={handleChange} />
    ) : (
        <>{renderRadioField()}</>
    )
}
export default RowSelector

import useStyles from './index.styles'

import * as React from 'react'

import {
    Dialog as MuiDialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
} from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'

import { TRender } from 'types/common.types'
import { DialogButtons } from 'components/common/index'

export interface IDialog {
    title?: TRender
    onClose: () => void
    onSubmit?: () => void
    children?: JSX.Element
}

const Dialog = (props: IDialog): JSX.Element => {
    const classes = useStyles()
    const { children, title, onSubmit, onClose } = props
    return (
        <MuiDialog
            maxWidth={false}
            open={true as boolean}
            onBackdropClick={onClose}
            onClose={onClose}
        >
            {title && (
                <DialogTitle className={classes.title}>
                    <Stack className={classes.titleText}>
                        <span>{title}</span>
                    </Stack>
                    <IconButton onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
            )}

            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
            {onSubmit && (
                <DialogButtons
                    removePaddings={false}
                    onSubmit={onSubmit}
                    onCancel={onClose}
                />
            )}
        </MuiDialog>
    )
}

export default Dialog

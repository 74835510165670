import useStyles from './index.styles'

import React, { useCallback } from 'react'

import classNames from 'classnames/bind'
import { Stack } from '@mui/material'

import { TRender } from 'types/common.types'

interface IFormStepper {
    steps: Record<number, string>
    currentStep: number
    onStepChange?: (index: number) => void
}
const FormStepper = ({
    steps,
    currentStep,
    onStepChange,
}: IFormStepper): JSX.Element => {
    const classes = useStyles()
    const cx = classNames.bind(classes)

    const handleClick = (index: number): void => {
        if (onStepChange) onStepChange(index)
    }
    const stepClassNames = useCallback(
        (isActive: boolean, isHighlighted: boolean) => {
            return cx({
                step: true,
                highlighted: isHighlighted,
                active: isActive,
                clickable: onStepChange && !isActive,
            })
        },
        [cx]
    )
    const numberClassNames = useCallback(
        (isActive: boolean, isHighlighted: boolean) => {
            return cx({
                stepNumber: true,
                highlighted: isHighlighted,
                active: isActive,
            })
        },
        [cx]
    )
    const titleClassNames = useCallback(
        (isActive: boolean, isHighlighted: boolean) => {
            return cx({
                stepTitle: true,
                highlighted: isHighlighted,
                active: isActive,
            })
        },
        [cx]
    )

    const renderStep = (title: string, key: number, index: number): TRender => {
        const isHighlighted: boolean = index < currentStep || !!onStepChange
        const isActive: boolean = key === currentStep
        const completed = onStepChange || (isHighlighted && !isActive)

        return (
            <Stack key={`form-step-${index}`} className={classes.stepOuter}>
                {index !== 0 ? <Stack className={classes.connector} /> : null}
                <Stack key={index}>
                    <Stack
                        className={stepClassNames(isActive, isHighlighted)}
                        onClick={(): void => handleClick(key)}
                    >
                        <Stack
                            className={numberClassNames(
                                isActive,
                                isHighlighted
                            )}
                        >
                            {index + 1}
                        </Stack>
                        <Stack
                            className={titleClassNames(isActive, isHighlighted)}
                        >
                            {title}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack className={classes.container}>
            {Object.entries(steps).map(([key, value], index) =>
                renderStep(value, parseFloat(key), index)
            )}
        </Stack>
    )
}

export default FormStepper

import useStyles from './index.styles'

import React from 'react'

import { Stack } from '@mui/material'

import { ITableData } from 'types/table.types'
import { IUser } from 'models/user.model'
import { formatUserName } from 'utils/user.utils'

type IUserInfo = {
    data: ITableData<IUser>
}
const UserInfo = ({ data: { row } }: IUserInfo): JSX.Element => {
    const classes = useStyles()
    return <Stack className={classes.container}>{formatUserName(row)}</Stack>
}
export default UserInfo

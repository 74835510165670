import useStyles from './index.styles'

import * as React from 'react'

import { Box } from '@mui/material'

interface IPage {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>
}

const Page = ({ children }: IPage): JSX.Element => {
    const classes = useStyles()
    return (
        <Box className={classes.container}>
            <Box className={classes.wrapper}>
                <Box className={classes.pageWrapper}>{children}</Box>
            </Box>
        </Box>
    )
}
export default Page

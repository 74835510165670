import { DocumentNode, gql } from '@apollo/client'

import { PAGE_INFO } from 'api/fragments/pagination.fragment'
import { ORDER_INFO } from 'api/fragments/order.fragment'
import { USER_INFO } from 'api/fragments/user.fragment'
import { COMPANY_INFO } from 'api/fragments/company.fragment'

class OrderAPI {
    static orderDetails(): DocumentNode {
        return gql`
            query orderDetails($id: ID!) {
                orderDetails(id: $id) {
                    ...OrderFields
                }
            }
            ${ORDER_INFO}
        `
    }

    static checklist(): DocumentNode {
        return gql`
            query checklist {
                checklist {
                    filename
                    url
                }
            }
        `
    }

    static addOrder(): DocumentNode {
        return gql`
            ${ORDER_INFO}
            mutation addOrder($input: AddOrderInput!) {
                addOrder(input: $input) {
                    success
                    errors
                    order {
                        ...OrderFields
                    }
                }
            }
        `
    }

    static updateOrder(): DocumentNode {
        return gql`
            ${ORDER_INFO}
            mutation updateOrder($input: UpdateOrderInput!) {
                updateOrder(input: $input) {
                    success
                    errors
                    order {
                        ...OrderFields
                    }
                }
            }
        `
    }

    static cloneOrder(): DocumentNode {
        return gql`
            ${ORDER_INFO}
            mutation cloneOrder($input: CloneOrderInput!) {
                cloneOrder(input: $input) {
                    success
                    errors
                    order {
                        ...OrderFields
                    }
                }
            }
        `
    }

    static getOrderPdf(): DocumentNode {
        return gql`
            mutation getOrderPdf($input: GetOrderPdfInput!) {
                getOrderPdf(input: $input) {
                    success
                    errors
                    text
                }
            }
        `
    }

    static changeOrderStatus(): DocumentNode {
        return gql`
            ${ORDER_INFO}
            mutation changeOrderStatus($input: ChangeOrderStatusInput!) {
                changeOrderStatus(input: $input) {
                    success
                    errors
                    order {
                        ...OrderFields
                    }
                }
            }
        `
    }

    static deleteOrder(): DocumentNode {
        return gql`
            mutation deleteOrder($input: DeleteOrderInput!) {
                deleteOrder(input: $input) {
                    success
                    errors
                }
            }
        `
    }

    static orderList(showAttachments: boolean): DocumentNode {
        return gql`
            ${USER_INFO}, ${COMPANY_INFO}, ${PAGE_INFO}
            query orderList(
                $offset: Int
                $before: String
                $after: String
                $first: Int
                $last: Int
                $dateFrom: Date
                $dateTo: Date
                $destination: String
                $companyId: String
                $search: String
                $status: String
                $vesselId: String
                $clientId: String
                $administratorId: String
                $ordering: String
                
            ) {
                orderList(
                    offset: $offset
                    before: $before
                    after: $after
                    first: $first
                    last: $last
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    destination: $destination
                    companyId: $companyId
                    search: $search
                    status: $status
                    vesselId: $vesselId
                    clientId: $clientId
                    administratorId: $administratorId
                    ordering: $ordering
                ) {
                    pageInfo {
                        ...PageInfoFields
                    }
                    edges {
                        node {
                            id
                            number
                            eta
                            status {
                                key
                                val
                            }
                            destination {
                                key
                                val
                            }
                            administrator {
                                id
                                firstName
                                lastName
                            }
                            ${
                                showAttachments
                                    ? 'attachments {\n            id\n            filename\n            url\n        }'
                                    : ''
                            }
                            vesselName
                            client {
                                ...UserFields
                                company {
                                    ...CompanyFields
                                }
                            }
                        }
                    }
                }
            }
        `
    }

    static addOrderAttachments(): DocumentNode {
        return gql`
            mutation addOrderAttachments($input: AddOrderAttachmentsInput!) {
                addOrderAttachments(input: $input) {
                    order {
                        id
                        attachments {
                            id
                            filename
                            url
                        }
                    }
                    success
                    errors
                }
            }
        `
    }

    static deleteOrderAttachments(): DocumentNode {
        return gql`
            mutation deleteOrderAttachments(
                $input: DeleteOrderAttachmentsInput!
            ) {
                deleteOrderAttachments(input: $input) {
                    order {
                        id
                        attachments {
                            id
                            filename
                            url
                        }
                    }
                    success
                    errors
                }
            }
        `
    }

    static copyOrderDocs(): DocumentNode {
        return gql`
            mutation copyOrderDocs($input: CopyOrderDocsInput!) {
                copyOrderDocs(input: $input) {
                    order {
                        id
                        attachments {
                            id
                            filename
                            url
                        }
                    }
                    success
                    errors
                }
            }
        `
    }
}

export default OrderAPI

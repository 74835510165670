import React from 'react'

import { TextFieldProps } from '@mui/material'

import { LabeledTextField } from 'components/inputs/index'

const DatePicker = (props: TextFieldProps): JSX.Element => {
    return <LabeledTextField type="date" autoComplete="off" {...props} />
}

export default DatePicker

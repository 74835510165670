import { object, string } from 'yup'

import { emailValidator } from '../index.validators'

export interface ILoginFormValues {
    email: string
    password: string
}

export const LoginFormInitialValues = {
    email: '',
    password: '',
}

export const LoginFormScheme = object({
    email: string().test(emailValidator).required('Email must be valid'),
    password: string().required('Password is required'),
})

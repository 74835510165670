import useStyles from './index.styles'

import React from 'react'

import { Paper, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useUser } from 'hooks/useUser'
import AdminForm from 'components/form/AdminForm'
import ChangePasswordForm from 'components/form/ChangePasswordForm'
import { paths } from 'config/routes'

const ProfileEdit = (): JSX.Element => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { user } = useUser()
    const onCancel = (): void => {
        navigate(paths.admin.profile)
    }
    return (
        <Stack className={classes.container}>
            <Paper className={classes.content}>
                <Stack className={classes.header}>Profile Editing</Stack>
                <AdminForm id={user?.id} onCancel={onCancel} />
                <ChangePasswordForm onCancel={onCancel} />
            </Paper>
        </Stack>
    )
}

export default ProfileEdit

import useStyles from './index.styles'

import React, { SyntheticEvent, useEffect, useMemo } from 'react'

import { Autocomplete, TextFieldProps } from '@mui/material'

import { LabeledTextField } from 'components/inputs'
import { useLazyVesselList } from 'hooks/useVessel'
import { IVessel } from 'models/vessel.model'

interface IVesselAutocomplete {
    companyId?: string
    value?: string
    vesselName?: string
    onSelect: (value: IVessel | null, newName?: string) => void
    error?: boolean
    helperText?: string | boolean
}

const VesselAutocomplete = (props: IVesselAutocomplete): JSX.Element => {
    const {
        value = '',
        onSelect,
        companyId,
        vesselName = '',
        error,
        helperText,
    } = props
    const classes = useStyles()
    const { vessels, getVessels } = useLazyVesselList()
    const [inputValue, setInputValue] = React.useState(vesselName)
    const onSelectVessel = (
        event: SyntheticEvent<Element, Event>,
        item: IVessel | null | string
    ): void => {
        if (typeof item === 'string') {
            setInputValue(item || '')
            onSelect(null, item)
        } else {
            setInputValue(item?.name || '')
            onSelect(item)
        }
    }

    const vessel: IVessel | null = useMemo(() => {
        return (
            vessels.find(
                item => item.id === value || item.name === vesselName
            ) || null
        )
    }, [value, vessels, vesselName])
    useEffect(() => {
        if (companyId) {
            if (value || !inputValue) {
                getVessels({ companyId })
            } else {
                getVessels({ companyId, search: inputValue })
            }
        }
    }, [companyId, inputValue, value])

    return (
        <Autocomplete
            freeSolo
            autoSelect
            value={vessel}
            inputValue={inputValue}
            className={classes.container}
            onInputChange={(event, newInputValue, reason): void => {
                if (reason !== 'reset' || newInputValue) {
                    setInputValue(newInputValue)
                }
            }}
            noOptionsText="Vessels not found"
            renderInput={(params: TextFieldProps): JSX.Element => (
                <LabeledTextField
                    {...params}
                    label="Select vessel"
                    error={error}
                    helperText={helperText}
                />
            )}
            getOptionLabel={(item: IVessel | string): string => {
                if (typeof item === 'object') return item.name || ''
                return item
            }}
            filterOptions={(
                options: Array<IVessel>,
                params
            ): Array<IVessel> => {
                return options.filter(option =>
                    option.name
                        .toLocaleLowerCase()
                        .includes(params.inputValue.toLocaleLowerCase())
                )
            }}
            onChange={onSelectVessel}
            options={vessels}
        />
    )
}

export default VesselAutocomplete

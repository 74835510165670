import { useCallback, useEffect, useState } from 'react'

import { ApolloError, useLazyQuery } from '@apollo/client'
import { debounce, get, omit } from 'lodash'

import { IQueryParam, ITableQueryInfo, ITableRowValue } from 'types/table.types'
import { IPagination } from 'models/api.model'

type TQueryParamVal = string | number | null | boolean

export interface IQueryParams {
    [key: string]: TQueryParamVal
}

interface IPaginationParams {
    after: string | null
    hasNextPage: boolean
    isLoading: boolean
    shouldLoadMore: boolean
}

interface IResultEdge {
    node: ITableRowValue
}

interface IResponseData {
    rowsCount: number
    rows: Array<ITableRowValue>
}

interface IUseExtQuery {
    rows: Array<unknown>
    hasNextPage: boolean
    rowsCount: number
    itemsPerPage: number | null
    loadMore: () => void
    setSearch: (search: string) => void
    searchStr: string | undefined
    debounceSetParam: (name: string, search: string) => void
    setParam: (key: string | IQueryParams, value?: TQueryParamVal) => void
    getParam: (key: string) => TQueryParamVal
    dataIsLoading: boolean
    dataError?: ApolloError
}

const defaultLazyLoadParams: IPaginationParams = {
    after: null,
    hasNextPage: false,
    isLoading: false,
    shouldLoadMore: false,
}
export const useExtQuery = (
    tableConfig: ITableQueryInfo,
    defaultParams: IQueryParams = {}
): IUseExtQuery => {
    const itemsPerPage = (): number | null =>
        (defaultParams?.itemsPerPage as unknown as number) ||
        tableConfig.itemsPerPage ||
        null
    const [params, setParams] = useState<IQueryParams>({
        first: itemsPerPage(),
    })

    const [lazyLoadParams, setLazyLoadParams] = useState<IPaginationParams>(
        defaultLazyLoadParams
    )

    const [searchStr, setSearchStr] = useState<string | undefined>()

    const [responseData, setResponseData] = useState<IResponseData>({
        rowsCount: 0,
        rows: [],
    })

    const buildQuery = useCallback(
        (queryParams: IQueryParams): Record<string, IQueryParam> => {
            const response = defaultParams
            if (tableConfig && tableConfig.defaultSort) {
                response.ordering = tableConfig.defaultSort
            }
            const excludeValues: Array<TQueryParamVal> = [null]
            return {
                ...response,
                ...omit(
                    queryParams,
                    Object.keys(queryParams).filter(
                        (key: string) =>
                            excludeValues.indexOf(queryParams[key]) !== -1
                    )
                ),
            }
        },
        [tableConfig, defaultParams]
    )

    const [getData, { data, loading, error, fetchMore }] = useLazyQuery(
        tableConfig?.dataApi() || '',
        {
            errorPolicy: 'all',
            fetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true,
        }
    )

    useEffect(() => {
        setLazyLoadParams(defaultLazyLoadParams)
        getData({
            variables: buildQuery(params),
        }).then(response => response)
    }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

    const debounceUpdateParams = useCallback(
        (newParams: IQueryParams) => {
            debounce(() => {
                setParams(newParams)
            }, 1000)
        },
        [setParams]
    ) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!loading) {
            if (data) {
                const edges: Array<IResultEdge> = get(
                    data,
                    `${tableConfig.dataPath}.edges`,
                    []
                )
                const pageInfo = get(
                    data,
                    `${tableConfig.dataPath}.pageInfo`,
                    {}
                ) as IPagination
                setResponseData({
                    rowsCount: get(
                        data,
                        `${tableConfig.dataPath}.totalCount`,
                        0
                    ),
                    rows: edges.map(edge => edge.node),
                })
                if (lazyLoadParams.after !== pageInfo.endCursor) {
                    setLazyLoadParams({
                        after: pageInfo.endCursor || null,
                        hasNextPage: pageInfo.hasNextPage || false,
                        isLoading: false,
                        shouldLoadMore: false,
                    })
                }
            } else {
                setResponseData({
                    rowsCount: 0,
                    rows: [],
                })
            }
        }
    }, [data, tableConfig.dataPath, loading]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (lazyLoadParams.shouldLoadMore && !lazyLoadParams.isLoading) {
            setLazyLoadParams(previousParams => {
                return { ...previousParams, isLoading: true }
            })
            fetchMore({
                variables: { after: lazyLoadParams.after },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return previousResult
                    }
                    const result = { ...fetchMoreResult }
                    const previousEdges: Array<IResultEdge> = get(
                        previousResult,
                        `${tableConfig.dataPath}.edges`,
                        []
                    )
                    const fetchMoreEdges: Array<IResultEdge> = get(
                        result,
                        `${tableConfig.dataPath}.edges`,
                        []
                    )

                    result[tableConfig.dataPath].edges = [
                        ...previousEdges,
                        ...fetchMoreEdges,
                    ]
                    return result
                },
            }).then(response => response)
        }
    }, [lazyLoadParams, tableConfig]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = (): void => {
        if (!lazyLoadParams.isLoading) {
            setLazyLoadParams(previousState => {
                return { ...previousState, shouldLoadMore: true }
            })
        }
    }

    const setSearch = (search: string): void => {
        if (search !== searchStr) {
            debounceUpdateParams({
                ...params,
                search,
            })
            setSearchStr(search)
        }
    }

    const debounceSetParam = (name: string, search: string): void => {
        if (search !== searchStr) {
            debounceUpdateParams({
                ...params,
                [name]: search,
            })
            setSearchStr(search)
        }
    }

    const setParam = (
        key: string | IQueryParams,
        value?: TQueryParamVal
    ): void => {
        if (typeof key === 'object') {
            setParams(currParams => {
                return { ...currParams, ...key }
            })
        } else {
            setParams(currParams => {
                return { ...currParams, [key]: value || null }
            })
        }
    }

    const getParam = (key: string): TQueryParamVal => {
        return params[key] || ''
    }

    return {
        rows: responseData.rows,
        hasNextPage: lazyLoadParams.hasNextPage,
        rowsCount: responseData.rowsCount,
        itemsPerPage: itemsPerPage(),
        loadMore,
        setSearch,
        debounceSetParam,
        setParam,
        getParam,
        searchStr,
        dataIsLoading: loading,
        dataError: error,
    }
}

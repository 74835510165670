import useStyles from './index.styles'

import React from 'react'

import { Stack } from '@mui/material'

import Onboarding from 'components/pages/Onboarding'

const Services = (): JSX.Element => {
    const classes = useStyles()

    return (
        <Stack className={classes.container}>
            <Onboarding />
        </Stack>
    )
}

export default Services

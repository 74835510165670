import React from 'react'

import { createTheme } from '@mui/material/styles'

export const topBarHeight = '56px'
export const bottomBarHeight = '48px'
export const adminNavbarWidth = '172px'
const systemBlack = '#2F2F31'
const systemSteel = '#6F6F77'
const systemNickel = '#B6B6BB'
const systemShadow = '#D3D3D5'
const systemMercury = '#EAEAEA'
const systemRain = '#F8F8F8'
const systemWhite = '#FFFFFF'

const themeBackground = '#F6F8FB'
const grayscaleBlack = '#373540'
const grayscaleGraphite = '#5B5962'
const grayscaleRain = '#88868D'
const grayscaleIvory = '#FCFCFC'
const grayscaleStone = '#A9A8AD'
const grayBackground = '#F6F8FB'
const primaryGreen = '#5FA232'
const primaryBlue = '#1587F2'
const secondaryNeon = '#516FAF'
const secondaryBlueLight = '#D6EEFC'
const secondaryRed = '#F84923'
const primary = '#153B64'
const secondary = '#2394A2'
const secondaryHover = '#469D4A'
const white = '#FFFFFF'
const red = '#D32828'
const yellow = '#FF8F00'
export const draftColor = '#E1AB10'
export const inProgressColor = '#1AA449'

const grey10 = '#FAFAFD'
const grey20 = '#EDEEF5'
const grey30 = '#CAD2D4'
const grey50 = '#C4C6D2'
const grey60 = '#8B8D9E'
const grey70 = '#696B77'
const grey80 = '#4D5257'
const grey90 = '#373540'
const grey100 = '#222124'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        tiny24: React.CSSProperties
        regular8: React.CSSProperties
        regular10: React.CSSProperties
        regular12: React.CSSProperties
        regular14: React.CSSProperties
        regular16: React.CSSProperties
        regular18: React.CSSProperties
        medium10: React.CSSProperties
        medium12: React.CSSProperties
        medium14: React.CSSProperties
        medium15: React.CSSProperties
        medium16: React.CSSProperties
        medium18: React.CSSProperties
        medium20: React.CSSProperties
        medium24: React.CSSProperties
        bold10: React.CSSProperties
        bold12: React.CSSProperties
        bold14: React.CSSProperties
        bold16: React.CSSProperties
        bold18: React.CSSProperties
        bold20: React.CSSProperties
        bold24: React.CSSProperties
        bold28: React.CSSProperties
        trajanRegular16: React.CSSProperties
        trajanBold22: React.CSSProperties
        trajanBold28: React.CSSProperties
        trajanBold32: React.CSSProperties
    }
    interface TypographyVariantsOptions {
        tiny24: React.CSSProperties
        regular8: React.CSSProperties
        regular10: React.CSSProperties
        regular12: React.CSSProperties
        regular14: React.CSSProperties
        regular16: React.CSSProperties
        regular18: React.CSSProperties
        medium10: React.CSSProperties
        medium12: React.CSSProperties
        medium14: React.CSSProperties
        medium15: React.CSSProperties
        medium16: React.CSSProperties
        medium18: React.CSSProperties
        medium20: React.CSSProperties
        medium24: React.CSSProperties
        bold10: React.CSSProperties
        bold12: React.CSSProperties
        bold14: React.CSSProperties
        bold16: React.CSSProperties
        bold18: React.CSSProperties
        bold20: React.CSSProperties
        bold24: React.CSSProperties
        bold28: React.CSSProperties
        trajanRegular16: React.CSSProperties
        trajanBold22: React.CSSProperties
        trajanBold28: React.CSSProperties
        trajanBold32: React.CSSProperties
    }
    interface Theme {
        colors: {
            themeBackground: React.CSSProperties['color']
            grayBackground: React.CSSProperties['color']
            systemBlack: React.CSSProperties['color']
            systemSteel: React.CSSProperties['color']
            systemNickel: React.CSSProperties['color']
            systemShadow: React.CSSProperties['color']
            systemMercury: React.CSSProperties['color']
            systemRain: React.CSSProperties['color']
            systemWhite: React.CSSProperties['color']
            grayscaleBlack: React.CSSProperties['color']
            grayscaleGraphite: React.CSSProperties['color']
            grayscaleRain: React.CSSProperties['color']
            grayscaleStone: React.CSSProperties['color']
            grayscaleIvory: React.CSSProperties['color']
            primaryGreen: React.CSSProperties['color']
            primaryBlue: React.CSSProperties['color']
            secondaryNeon: React.CSSProperties['color']
            secondaryRed: React.CSSProperties['color']
            secondaryBlueLight: React.CSSProperties['color']
            primary: React.CSSProperties['color']
            primaryMain: React.CSSProperties['color']
            secondary: React.CSSProperties['color']
            secondaryHover: React.CSSProperties['color']
            white: React.CSSProperties['color']
            red: React.CSSProperties['color']
            yellow: React.CSSProperties['color']
            grey10: React.CSSProperties['color']
            grey20: React.CSSProperties['color']
            grey30: React.CSSProperties['color']
            grey50: React.CSSProperties['color']
            grey60: React.CSSProperties['color']
            grey70: React.CSSProperties['color']
            grey80: React.CSSProperties['color']
            grey90: React.CSSProperties['color']
            grey100: React.CSSProperties['color']
        }
    }
    interface ThemeOptions {
        colors: {
            themeBackground: React.CSSProperties['color']
            grayBackground: React.CSSProperties['color']
            systemBlack: React.CSSProperties['color']
            systemSteel: React.CSSProperties['color']
            systemNickel: React.CSSProperties['color']
            systemShadow: React.CSSProperties['color']
            systemMercury: React.CSSProperties['color']
            systemRain: React.CSSProperties['color']
            systemWhite: React.CSSProperties['color']
            grayscaleBlack: React.CSSProperties['color']
            grayscaleGraphite: React.CSSProperties['color']
            grayscaleRain: React.CSSProperties['color']
            grayscaleStone: React.CSSProperties['color']
            grayscaleIvory: React.CSSProperties['color']
            primaryGreen: React.CSSProperties['color']
            primaryBlue: React.CSSProperties['color']
            secondaryNeon: React.CSSProperties['color']
            secondaryRed: React.CSSProperties['color']
            secondaryBlueLight: React.CSSProperties['color']
            primary: React.CSSProperties['color']
            secondary: React.CSSProperties['color']
            secondaryHover: React.CSSProperties['color']
            white: React.CSSProperties['color']
            red: React.CSSProperties['color']
            yellow: React.CSSProperties['color']
            grey10: React.CSSProperties['color']
            grey20: React.CSSProperties['color']
            grey30: React.CSSProperties['color']
            grey50: React.CSSProperties['color']
            grey60: React.CSSProperties['color']
            grey70: React.CSSProperties['color']
            grey80: React.CSSProperties['color']
            grey90: React.CSSProperties['color']
            grey100: React.CSSProperties['color']
        }
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        tiny24: true
        regular8: true
        regular10: true
        regular12: true
        regular14: true
        regular16: true
        regular18: true
        medium10: true
        medium12: true
        medium14: true
        medium15: true
        medium16: true
        medium18: true
        medium20: true
        medium24: true
        bold10: true
        bold12: true
        bold14: true
        bold16: true
        bold18: true
        bold20: true
        bold24: true
        bold28: true
        trajanRegular16: true
        trajanBold22: true
        trajanBold28: true
        trajanBold32: true
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            light: '#757ce8',
            main: primary,
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: secondary,
            dark: '#0f7984',
            contrastText: '#000',
        },
    },
    colors: {
        themeBackground,
        grayBackground,
        systemBlack,
        systemSteel,
        systemNickel,
        systemShadow,
        systemMercury,
        systemRain,
        systemWhite,
        grayscaleBlack,
        grayscaleGraphite,
        grayscaleRain,
        grayscaleStone,
        grayscaleIvory,
        primaryGreen,
        primaryBlue,
        secondaryNeon,
        secondaryRed,
        secondaryBlueLight,
        primary,
        secondary,
        secondaryHover,
        white,
        red,
        yellow,
        grey10,
        grey20,
        grey30,
        grey50,
        grey60,
        grey70,
        grey80,
        grey90,
        grey100,
    },
    breakpoints: {
        values: {
            xs: 432,
            sm: 834,
            md: 1340,
            lg: 1500,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: ['Minion Pro Regular', 'sans-serif'].join(','),
        tiny24: {
            fontWeight: 300,
            fontSize: '24px',
            lineHeight: '32px',
        },
        regular8: {
            fontWeight: 'normal',
            fontSize: '8px',
            lineHeight: '10px',
        },
        regular10: {
            fontWeight: 'normal',
            fontSize: '10px',
            lineHeight: '14px',
        },
        regular12: {
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '16px',
        },
        regular14: {
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
        },
        regular16: {
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
        },
        regular18: {
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '24px',
        },
        medium10: {
            fontFamily: 'Minion Pro Semibold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '10px',
            lineHeight: '14px',
        },
        medium12: {
            fontFamily: 'Minion Pro Semibold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '16px',
        },
        medium14: {
            fontFamily: 'Minion Pro Semibold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
        },
        medium15: {
            fontFamily: 'Minion Pro Semibold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '15px',
            lineHeight: '16px',
        },
        medium16: {
            fontFamily: 'Minion Pro Semibold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
        },
        medium18: {
            fontFamily: 'Minion Pro Semibold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '24px',
        },
        medium20: {
            fontFamily: 'Minion Pro Semibold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '24px',
        },
        medium24: {
            fontFamily: 'Minion Pro Semibold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '32px',
        },
        bold10: {
            fontFamily: 'Minion Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '10px',
            lineHeight: '14px',
        },
        bold12: {
            fontFamily: 'Minion Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
            lineHeight: '16px',
        },
        bold14: {
            fontFamily: 'Minion Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
        },
        bold16: {
            fontFamily: 'Minion Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '20px',
        },
        bold18: {
            fontFamily: 'Minion Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: '24px',
        },
        bold20: {
            fontFamily: 'Minion Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '28px',
        },
        bold24: {
            fontFamily: 'Minion Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '32px',
        },
        bold28: {
            fontFamily: 'Minion Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '28px',
            lineHeight: '35px',
        },
        trajanRegular16: {
            fontFamily: 'Trajan Pro Regular',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
        },
        trajanBold22: {
            fontFamily: 'Trajan Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '22px',
            lineHeight: '28px',
        },
        trajanBold28: {
            fontFamily: 'Trajan Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '28px',
            lineHeight: '35px',
        },
        trajanBold32: {
            fontFamily: 'Trajan Pro Bold',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '32px',
            lineHeight: '38px',
        },
    },
})

export const appTheme = createTheme(theme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    ...theme.typography.medium16,
                    textTransform: 'none',
                    color: theme.colors.primary,
                    borderRadius: '4px',
                    backgroundColor: theme.colors.white,
                    boxShadow: 'none',
                    padding: '13px 24px',
                },
                outlined: {
                    padding: '12px 24px',
                    backgroundColor: 'transparent',
                    // borderColor: theme.colors.grey30,
                },
                textPrimary: {
                    color: theme.colors.primary,
                },
                textSizeSmall: {
                    ...theme.typography.medium16,
                },
                outlinedSizeSmall: {},
                contained: {
                    '& svg': {
                        '& *': {
                            stroke: theme.colors.grey100,
                        },
                    },
                },
                containedPrimary: {
                    color: theme.colors.white,
                    backgroundColor: theme.colors.primary,
                    '& svg': {
                        '& *': {
                            stroke: theme.colors.white,
                        },
                    },
                },
                containedSecondary: {
                    color: theme.colors.white,
                    backgroundColor: theme.colors.secondary,
                    '& svg': {
                        '& *': {
                            stroke: theme.colors.white,
                        },
                    },
                },
                outlinedSecondary: {
                    color: theme.colors.secondary,
                },
                containedInherit: {
                    outline: `1px solid ${theme.colors.primary}`,
                },
                sizeSmall: {
                    ...theme.typography.medium16,
                    borderRadius: '4px',
                },
                iconSizeSmall: {
                    marginTop: '-4px',
                    marginBottom: '-4px',
                },
                iconSizeMedium: {
                    marginTop: '-2px',
                    marginBottom: '-2px',
                },
                startIcon: {
                    marginLeft: 0,
                },
                endIcon: {
                    marginRight: 0,
                },
            },
            defaultProps: {
                variant: 'contained',
                color: 'inherit',
                disableRipple: true,
                disableFocusRipple: true,
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    marginTop: 0,
                },
                underline: {
                    marginTop: 0,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: '12.5px 14px',
                    '& ::-webkit-input-placeholder': {
                        opacity: '0.5 !important',
                    },
                },
                inputSizeSmall: {
                    padding: '8.5px 14px',
                },

                root: {
                    borderRadius: '6px',
                },
                multiline: {
                    padding: 0,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    ...theme.typography.regular16,
                    transform: 'translate(14px, 14px) scale(1)',
                },
                sizeSmall: {
                    transform: 'translate(14px, 10px) scale(1)',
                },
                shrink: {
                    transform: 'translate(14px, -9px) scale(0.75)',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation12: {
                    boxShadow: 'none',
                    borderRadius: '4px',
                },
            },
            defaultProps: {
                variant: 'elevation',
                elevation: 12,
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    ...theme.typography.medium14,
                    color: theme.colors.secondary,
                    cursor: 'pointer',
                    textDecoration: 'none',
                },
            },
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    color: theme.colors.primary,
                },
            },
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                row: {
                    '&>.MuiFormControlLabel-root': {
                        minWidth: '100px',
                        marginRight: '8px',
                    },
                    '&>.MuiFormControlLabel-root:last-child': {
                        marginRight: 0,
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    ...theme.typography.medium16,
                    color: theme.colors.grey100,
                    marginBottom: '8px',
                    '&.Mui-focused': {
                        color: theme.colors.grey100,
                    },
                    '&.Mui-error': {
                        color: theme.colors.grey100,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    textAlign: 'left',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    ...theme.typography.regular12,
                    color: theme.colors.grey90,
                    marginTop: '8px',
                    marginLeft: 0,
                    '&.Mui-error': {
                        color: theme.colors.red,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: 0,
                    width: '20px',
                    height: '20px',
                    marginRight: '10px',
                    '& input': {
                        width: '16px',
                        height: '16px',
                        margin: '2px',
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    margin: '4px 0',
                    alignItems: 'flex-start',
                },
                label: {
                    ...theme.typography.medium14,
                    color: theme.colors.grey100,
                    marginBottom: '-2px',
                },
                input: {
                    width: '16px',
                    height: '16px',
                    margin: '2px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: theme.colors.grey100,
                },
                arrow: {
                    color: theme.colors.grey100,
                },
            },
            defaultProps: {
                arrow: true,
                placement: 'left',
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.colors.systemRain,
                    height: topBarHeight,
                    padding: '0 24px',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    flexDirection: 'row',
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: '2px',
                    marginRight: '8px',
                    '&.Mui-disabled': {
                        opacity: '.6',
                        '& svg': {
                            '& *': {
                                fill: theme.colors.grey20,
                            },
                        },
                    },
                },
                disabled: {},
            },
        },
        MuiDialog: {
            styleOverrides: {},
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'flex-start',
                    padding: '24px',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    ...theme.typography.medium18,
                    padding: '12px 24px',
                    backgroundColor: theme.colors.systemRain,
                    boxShadow: `0px 1px 0px ${theme.colors.systemShadow}`,
                    '&>div': {
                        justifyContent: 'center',
                    },
                    '& button': {
                        padding: '8px',
                        '& svg': {
                            width: '14px',
                            height: '14px',
                        },
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        cursor: 'default',
                        pointerEvents: 'none',
                        backgroundColor: 'inherit',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {},
                flexContainer: {},
                indicator: {
                    backgroundColor: theme.colors.secondary,
                },
            },
            defaultProps: {
                variant: 'scrollable',
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    maxHeight: `calc(100% - ${topBarHeight})`,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    ...theme.typography.regular16,
                    color: theme.colors.systemBlack,
                    '&.Mui-selected': {
                        color: theme.colors.secondary,
                    },
                },
            },
        },
    },
})

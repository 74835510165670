import { DocumentNode, gql } from '@apollo/client'

import { USER_INFO } from 'api/fragments/user.fragment'

class AuthAPI {
    static getAccessTokens(): DocumentNode {
        return gql`
            mutation verifyToken($input: String) {
                verifyToken(input: $input) {
                    token
                    refreshToken
                    success
                    errors
                }
            }
        `
    }

    static refreshToken(): DocumentNode {
        return gql`
            mutation refreshToken($refreshToken: String) {
                refreshToken(refreshToken: $refreshToken) {
                    token
                    refreshToken
                    payload
                }
            }
        `
    }

    static singIn(): DocumentNode {
        return gql`
            mutation emailSignIn($email: String!, $password: String!) {
                emailSignIn(email: $email, password: $password) {
                    user {
                        ...UserFields
                    }
                    success
                    errors
                    token
                    refreshToken
                }
            }
            ${USER_INFO}
        `
    }

    static socialAuth(): DocumentNode {
        return gql`
            mutation socialAuth($accessToken: String!, $provider: String!) {
                socialAuth(accessToken: $accessToken, provider: $provider) {
                    user {
                        ...UserFields
                    }
                    success
                    errors
                    token
                    refreshToken
                }
            }
            ${USER_INFO}
        `
    }

    static singUp(): DocumentNode {
        return gql`
            mutation emailSignup($input: EmailSignupInput!) {
                emailSignup(input: $input) {
                    success
                    errors
                }
            }
        `
    }

    static reinviteUser(): DocumentNode {
        return gql`
            mutation reinviteUser($input: ReinviteUserInput!) {
                reinviteUser(input: $input) {
                    success
                    errors
                }
            }
        `
    }

    static resetPassword(): DocumentNode {
        return gql`
            mutation resetPassword($input: ResetPasswordInput!) {
                resetPassword(input: $input) {
                    success
                    errors
                }
            }
        `
    }

    static createPassword(): DocumentNode {
        return gql`
            mutation resetPasswordConfirm($input: ResetPasswordConfirmInput!) {
                resetPasswordConfirm(input: $input) {
                    success
                }
            }
        `
    }
}

export default AuthAPI

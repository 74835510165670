import useStyles from './index.styles'

import React, { useCallback, useEffect } from 'react'

import { Button, Paper, Stack } from '@mui/material'

import { IReactiveDialog, useDialog } from 'hooks/useDialog'
import Plus from 'assets/images/icons/Plus'
import VesselsList from 'components/common/VesselsList'
import VesselForm, { IVesselForm } from 'components/form/VesselForm'
import { useCompanyApi } from 'hooks/useCompany'
import ConfirmationDialog, {
    IConfirmationDialog,
} from 'components/common/ConfirmationDialog'
import CompanyAside from 'components/common/CompanyAside'
import { useUser } from 'hooks/useUser'

const Vessels = (): JSX.Element => {
    const classes = useStyles()
    const { user } = useUser()
    const { company, getCompany } = useCompanyApi()

    const { open, close } = useDialog()

    useEffect(() => {
        if (user?.company?.id) {
            getCompany(user.company.id)
        }
    }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

    const openVesselForm = useCallback(
        (vesselId?: string): void => {
            if (user && user.company) {
                open({
                    component: VesselForm,
                    props: {
                        onSuccess: close,
                        onClose: close,
                        id: vesselId,
                        companyId: user.company.id,
                    },
                    options: {
                        title: 'Add Vessel',
                        onClose: close,
                    },
                } as IReactiveDialog<IVesselForm>)
            }
        },
        [open, close, user]
    )

    const openConfirmationDialog = useCallback((): void => {
        open({
            component: ConfirmationDialog,
            props: {
                onConfirm: close,
                onCancel: close,
            },
            options: {
                title: 'Confirm action',
                onClose: close,
            },
        } as IReactiveDialog<IConfirmationDialog>)
    }, [open, close])

    return (
        <Stack className={classes.container}>
            <Stack className={classes.page}>
                <Paper className={classes.profile}>
                    <CompanyAside company={company} />
                </Paper>
                <Paper className={classes.content}>
                    <Stack className={classes.head}>
                        <Stack className={classes.title}>Vessels</Stack>
                        <Stack className={classes.buttons}>
                            <Button
                                onClick={(): void => openVesselForm()}
                                color="primary"
                                startIcon={<Plus />}
                            >
                                Add New Vessel
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack className={classes.pageContent}>
                        {company?.id && <VesselsList />}
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    )
}

export default Vessels

import { ITableColumn, ITableData, ITableRowValue } from 'types/table.types'
import TableButtons from 'components/common/Table/fields/TableButtons'
import { IUser } from 'models/user.model'
import { UserInfo } from 'components/common/Table/fields'
import { ICompany } from 'models/company.model'

export const columns: Array<ITableColumn> = [
    {
        field: 'title',
        headerName: 'Title',
        fr: 1, // 7
    },
    {
        field: 'margin',
        headerName: 'Margin',
        fr: 0.3, // 7
        renderCell: (data: ITableData<ICompany>) => `${data.row.margin}%`,
    },
    {
        field: 'actions',
        headerName: '',
        align: 'end',
        renderCell: (data: ITableData<ITableRowValue>) => (
            <TableButtons data={data} />
        ),
    },
] as Array<ITableColumn>

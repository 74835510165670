import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        gap: '32px',
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            gap: '8px',
        },
    },
    group: {
        flexDirection: 'row',
        gap: '48px',
        '&>div': {
            flex: '33.3% 0 0',
        },
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            flexDirection: 'column',
            gap: '8px',
            '&>div': {
                flexBasis: 'auto',
            },
        },
    },
    divider: {
        borderBottom: `1px dashed ${theme.colors.systemShadow}`,
    },
}))

export default useStyles

import useStyles from './index.styles'

import React, { useCallback, useMemo, useState } from 'react'

import { Drawer, IconButton, Link } from '@mui/material'
import MenuList from '@mui/material/MenuList'
import { useLocation } from 'react-router-dom'
import { Close, Menu } from '@mui/icons-material'

import NavigationMenuItem from './NavigationMenuItem'
import { IMenuItem, TRender } from 'types/common.types'
import { paths, resolveHomepageByRole } from 'config/routes'
import AppLogo from 'assets/images/AppLogo.png'
import AppLogoMobile from 'assets/images/AppLogoMobile.png'
import useScreenSize from 'hooks/useScreenSize'
import { UserRole } from 'config/user'
import { useUser } from 'hooks/useUser'

interface IAppNav {
    menuItems?: Array<IMenuItem>
    bottomMenu?: Array<IMenuItem>
}

const AppNav = ({ menuItems = [], bottomMenu = [] }: IAppNav): JSX.Element => {
    const classes = useStyles()
    const { user } = useUser()
    const { pathname } = useLocation()
    const { isTablet } = useScreenSize()
    const menuRef = React.useRef<HTMLDivElement | null>(null)
    const [anchorMenu, setAnchorMenu] = useState<null | HTMLDivElement>(null)

    const toggleMenu = (): void => {
        setAnchorMenu(currentState => (currentState ? null : menuRef.current))
    }
    const onMenuClick = (): void => {
        setAnchorMenu(null)
    }

    const logoUrl: string = useMemo(() => {
        return isTablet ? AppLogoMobile : AppLogo
    }, [isTablet])
    const renderMenuItem = useCallback(
        (list: IMenuItem[]): TRender => {
            return list.map((item: IMenuItem) => (
                <NavigationMenuItem
                    key={item.id}
                    item={item}
                    isActive={pathname === item.url}
                    onMenuClick={onMenuClick}
                />
            ))
        },
        [pathname]
    )
    const menuContent: TRender = useMemo(
        () => (
            <>
                <MenuList className={classes.menuList}>
                    {renderMenuItem(menuItems)}
                </MenuList>
                <MenuList className={classes.menuList}>
                    {renderMenuItem(bottomMenu)}
                </MenuList>
            </>
        ),
        [menuItems, bottomMenu, classes, renderMenuItem]
    )
    return (
        <>
            <Link
                className={classes.logoLink}
                href={resolveHomepageByRole(user?.role.key)}
            >
                <img className={classes.logo} src={logoUrl} alt="app logo" />
            </Link>
            <div className={classes.menuHolder} ref={menuRef}>
                {isTablet ? (
                    <IconButton
                        className={classes.menuIconButton}
                        size="large"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleMenu}
                        color="inherit"
                    >
                        {anchorMenu ? <Close /> : <Menu />}
                    </IconButton>
                ) : (
                    menuContent
                )}
            </div>
            {isTablet && (
                <Drawer
                    anchor="top"
                    open={!!anchorMenu}
                    onClose={toggleMenu}
                    className={classes.mobileMenu}
                >
                    {menuContent}
                </Drawer>
            )}
        </>
    )
}
export default AppNav

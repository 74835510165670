import useStyles from './index.styles'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames/bind'
import { Stack } from '@mui/material'

import useScreenSize from 'hooks/useScreenSize'
import { ITableColumn, ITableData, ITableRowValue } from 'types/table.types'
import { RowSelector } from './fields'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import Loader from 'components/common/Loader'

interface ITable {
    rows?: Array<ITableRowValue>
    isLoading?: boolean
    wrapHeaders?: boolean
    onSelect?: (id: string) => void
    onRowClick?: (id: string) => void
    selectedItems?: Array<string>
    isMultiSelect?: boolean
    onSelectAll?: () => void
    columns: Array<ITableColumn>
    onCellClicked?: (row: ITableRowValue, action?: string) => void
    className?: string
    hasNextPage?: boolean
    loadMore?: () => void
}

const Table: React.FC<ITable> = (props: ITable): React.ReactElement => {
    const {
        rows,
        isLoading,
        wrapHeaders = false,
        selectedItems,
        onSelect,
        onRowClick,
        isMultiSelect,
        onSelectAll,
        columns,
        onCellClicked,
        hasNextPage,
        className,
        loadMore,
    } = props

    const { isMobile } = useScreenSize()
    const wrapperRef = useRef<HTMLDivElement | null>(null)
    const [isLeftSideFixed, setIsLeftSideFixed] = useState<boolean>(false)
    const [isRightSideFixed, setIsRightSideFixed] = useState<boolean>(false)

    const scrollHandler = useCallback((): void => {
        if (wrapperRef?.current) {
            if (wrapperRef.current?.scrollLeft > 5) {
                if (!isLeftSideFixed) {
                    setIsLeftSideFixed(true)
                }
            } else if (isLeftSideFixed) {
                setIsLeftSideFixed(false)
            }
            if (
                (wrapperRef.current?.scrollLeft || 0) +
                    wrapperRef.current.clientWidth <
                wrapperRef.current.scrollWidth - 3
            ) {
                if (!isRightSideFixed) {
                    setIsRightSideFixed(true)
                }
            } else if (isRightSideFixed) {
                setIsRightSideFixed(false)
            }
            const isBottom = (): boolean => {
                if (wrapperRef.current) {
                    const scrollHeight = wrapperRef.current?.scrollHeight || 0
                    const scrollTop = wrapperRef.current?.scrollTop || 0
                    const clientHeight = wrapperRef.current?.clientHeight || 0

                    return scrollHeight - (scrollTop + clientHeight) <= 50
                }
                return false
            }
            if (isBottom() && !isLoading && loadMore && hasNextPage) {
                loadMore()
            }
        }
    }, [
        isLeftSideFixed,
        isRightSideFixed,
        wrapperRef,
        hasNextPage,
        loadMore,
        isLoading,
    ])

    useEffect(() => {
        if (wrapperRef && wrapperRef.current) {
            scrollHandler()
        }
    }, [scrollHandler, wrapperRef])

    const classes = useStyles()
    const cx = classNames.bind(classes)

    const tableColumns = useMemo((): Array<ITableColumn> => {
        const response = [...columns]
        if (onSelect) {
            response.unshift({
                field: 'selectable',
                headerName: ' ',
                isSticky: true,
                renderCell: (data: ITableData<ITableRowValue>): JSX.Element => (
                    <RowSelector
                        data={data}
                        isMultiSelect={!!isMultiSelect}
                        isSelected={selectedItems?.indexOf(data.row.id) !== -1}
                    />
                ),
            } as ITableColumn)
        }
        return response
    }, [columns, selectedItems, onSelect, isMultiSelect])

    const rowStyles: Array<string> = useMemo(() => {
        if (isMobile) {
            return ['1fr', '1fr']
        }

        const columnTemplate = columns.map((column: ITableColumn) => {
            if (column.fr) return `${column.fr}fr`
            if (column.width) return column.width
            return 'auto'
        })
        if (onSelect) {
            columnTemplate.unshift('44px')
        }
        return columnTemplate
    }, [columns, onSelect, isMobile])

    const tableStyle: React.CSSProperties = useMemo(() => {
        return {
            gridTemplateColumns: rowStyles.join(' '),
        }
    }, [rowStyles])

    const loaderStyle: React.CSSProperties = useMemo(() => {
        return {
            gridColumn: `1 / ${rowStyles.length + 1}`,
        }
    }, [rowStyles])

    const containerClassNames = cx(classes.container, {
        [className || '']: !!className,
        topBorder: isMobile,
    })
    const loaderClassNames = cx(classes.bodyCell, classes.loader)

    return (
        <div
            ref={wrapperRef}
            className={classes.wrapper}
            onScroll={scrollHandler}
        >
            <div className={containerClassNames} style={tableStyle}>
                {!isMobile && (
                    <TableHeader
                        columns={tableColumns}
                        onSelect={onSelect}
                        onSelectAll={onSelectAll}
                        selectedItems={selectedItems}
                        isMultiSelect={isMultiSelect}
                        isLeftSideFixed={isLeftSideFixed}
                        isRightSideFixed={isRightSideFixed}
                        wrapHeaders={wrapHeaders}
                    />
                )}
                {rows?.map((row: ITableRowValue) => (
                    <TableRow
                        key={row.id}
                        row={row}
                        columns={tableColumns}
                        onSelect={onSelect}
                        onClick={onRowClick}
                        onCellClicked={onCellClicked}
                        selectedItems={selectedItems}
                        isMultiSelect={isMultiSelect}
                        isLeftSideFixed={isLeftSideFixed}
                        isRightSideFixed={isRightSideFixed}
                    />
                ))}
                {isLoading && (
                    <Stack className={loaderClassNames} style={loaderStyle}>
                        <Loader />
                    </Stack>
                )}
            </div>
        </div>
    )
}
export default Table

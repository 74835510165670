import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        gap: '16px',
        '& .ship-services': {
            '& .services': {
                visibility: 'hidden',
                height: '0px',
                marginTop: '-32px',
                overflow: 'hidden',
            },
        },
    },
    stepper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    groupTitle: {
        ...theme.typography.bold16,
    },
    orderTitle: {
        ...theme.typography.trajanBold32,
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
    },
    titleText: {
        ...theme.typography.medium16,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    formPaper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '40px 60px',
        gap: '32px',
        position: 'relative',
    },
    formGroup: {
        gap: '32px',
        position: 'relative',
    },
    group: {
        flexDirection: 'row',
        gap: '48px',
        '&>div': {
            flexBasis: '50%',
            flexGrow: 0,
        },
    },
    group3: {
        flexDirection: 'row',
        gap: '48px',
        '&>div': {
            flexBasis: '33.3%',
            flexGrow: 0,
        },
    },
    divider: {
        borderBottom: `1px dashed ${theme.colors.systemShadow}`,
    },
    buttons: {
        flexDirection: 'row',
        gap: '12px',
        justifyContent: 'flex-end',
    },
    users: {
        gap: '24px',
    },
    userList: {
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: '16px',
    },
    user: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    email: {
        flexBasis: '50%',
        flexGrow: 1,
    },
    status: {
        flexBasis: '35%',
    },
    actions: {
        '& button': {
            width: '48px',
            height: '48px',
            borderRadius: '8px',
        },
    },
    companyTitle: {
        ...theme.typography.regular16,
        textTransform: 'uppercase',
    },
    addedClient: {
        color: theme.colors.primaryGreen,
    },
    totalDays: {
        '& fieldset': {
            display: 'none',
        },
        '& input': {
            pointerEvents: 'none',
            flexBasis: '26px',
            textAlign: 'center',
            padding: '6px',
            margin: '6px',
            borderRadius: '4px',
            backgroundColor: '#E8EEF6',
        },
    },
    customerInfo: {
        flexDirection: 'row',
    },
    customerInfoBlock: {
        flexDirection: 'row',
        flexBasis: '25%',
        gap: '8px',
        alignItems: 'center',
    },
    icon: {
        flexBasis: '24px',
        flexGrow: '0',
    },
    title: {
        ...theme.typography.regular16,
    },
}))

export default useStyles
